import React from "react";
import {
  faCaretDown,
  faCaretUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class PartsTitle extends React.Component {
  render() {
    return (
      <div className="parts-title" onClick={this.props.onClick}>
        <span>{this.props.title}</span>
        {this.props.isValid ? (
          <div>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        ) : null}
        <div>
          <FontAwesomeIcon icon={this.props.isHide ? faCaretDown : faCaretUp} />
        </div>
      </div>
    );
  }
}
