import React from "react";
import { Row, Col } from "react-flexbox-grid";

import Equipment from "../../../components/folder/descriptive-state/Equipment";
import Vehicle from "../../../components/folder/descriptive-state/Vehicle";
import Pictures from "../../../components/folder/descriptive-state/Pictures";
import Histovec from "../../../components/folder/descriptive-state/Histovec";
import Electric from "../../../components/folder/descriptive-state/Electric";
import Guarding from "../../../components/folder/descriptive-state/Guarding";
import Signature from "../../../components/folder/descriptive-state/Signature";
import Identification from "../../../components/folder/summary/Identification";

import html2canvasInstance from "../../../utils/html2canvasInstance";

export default class DescriptiveState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPrint: false,
    };

    this.vehicleRef = null;
  }

  handlePrintClick = (callback) => {
    if (this.vehicleRef && this.vehicleRef.vehicleRef) {
      this.setState({ isPrint: true }, () => {
        html2canvasInstance(this.vehicleRef.vehicleRef, (result) => {
          if (result.success) {
            this.setState({ isPrint: false }, () => {
              callback({
                success: true,
                data: [result.base64],
              });
            });
          } else {
            this.setState({ isPrint: false }, () => {
              callback({
                success: false,
                data: [],
              });
            });
          }
        });
      });
    }
  };

  render() {
    return (
      <>
        <Row style={this.state.isPrint ? { width: "1050px" } : null}>
          {this.state.isPrint ? (
            <Col xs={12} className="d-flex">
              <Identification
                dataIdentification={this.props.dataIdentification}
                isClosed={this.props.isClosed}
              />
            </Col>
          ) : null}
          <Col
            xs={12}
            lg={this.state.isPrint ? 12 : 3}
            className="col_full_height"
          >
            <Equipment
              id={this.props.id}
              gender={this.props.gender}
              dataEquipment={this.props.dataEquipment}
              updateFolder={this.props.updateFolder}
              isEdit={!this.props.isClosed}
              isPrint={this.state.isPrint}
            />
          </Col>
          <Col xs={12} lg={this.state.isPrint ? 12 : 9}>
            <Vehicle
              ref={(ref) => {
                this.vehicleRef = ref;
              }}
              id={this.props.id}
              gender={this.props.gender}
              dataIdentification={this.props.dataIdentification}
              dataPart={this.props.dataPart}
              extendDataPart={this.props.extendDataPart}
              dataComment={this.props.dataComment}
              updateFolder={this.props.updateFolder}
              isTechnicalInspectionValid={this.props.isTechnicalInspectionValid}
              isTechnicalInspectionExist={this.props.isTechnicalInspectionExist}
              isEdit={!this.props.isClosed}
              isPrint={this.state.isPrint}
            />
          </Col>
          <Col xs={12}>
            <Pictures
              id={this.props.id}
              dataIdentification={this.props.dataIdentification}
              gender={this.props.gender}
              dataPart={this.props.dataPart}
              dataPixTransfer={this.props.dataPixTransfer}
              updateFolder={this.props.updateFolder}
              isEdit={!this.props.isClosed}
              isPrint={this.state.isPrint}
              hideImage={this.props.hideImage}
              updateHideImage={this.props.updateHideImage}
              hideImagesId={this.props.hideImagesId}
              updateHideImagesId={this.props.updateHideImagesId}
            />
          </Col>
          <Col xs={12}>
            <Histovec
              dataIdentification={this.props.dataIdentification}
              dataHistovec={this.props.dataHistovec}
              updateFolder={this.props.updateFolder}
            />
          </Col>
          <Col xs={12}>
            <Electric
              fuel={this.props.fuel}
              updateFolder={this.props.updateFolder}
              dataElectric={this.props.dataElectric}
            />
          </Col>
          <Col xs={12}>
            <Guarding
              id={this.props.id}
              gender={this.props.gender}
              fuel={this.props.fuel}
              dataIdentification={this.props.dataIdentification}
              dataGuarding={this.props.dataGuarding}
              updateFolder={this.props.updateFolder}
              isEdit={!this.props.isClosed}
              isPrint={this.state.isPrint}
            />
          </Col>
          <Col xs={12}>
            <Signature
              id={this.props.id}
              dataSignature={this.props.dataSignature}
              updateFolder={this.props.updateFolder}
              isEdit={!this.props.isClosed}
              isPrint={this.state.isPrint}
            />
          </Col>
        </Row>
      </>
    );
  }
}
