import React from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  Loader,
  FormInput,
  Button,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";

import { getReportHistovec } from "../../../requests/folder";

export default class Histovec extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalHistovecName: "",
      modalHistovecFirstname: "",
      modalHistovecLicensePlate: "",
      modalHistovecFormula: "",
      isLoad: false,
      isModalHistovec: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.setState({
        modalHistovecName: this.props.name ? this.props.name.split(" ")[0] : "",
        modalHistovecFirstname: this.props.name
          ? this.props.name.split(" ")[1]
          : "",
        modalHistovecLicensePlate: this.props.licensePlate,
        modalHistovecFormula: this.props.formula,
      });
    }
  }

  handleInputChange = (e, callback = () => {}) => {
    const { value, name } = e.target;
    this.setState(
      {
        [name]: value,
      },
      callback
    );
  };

  getReportHistovec = () => {
    if (
      this.state.modalHistovecName &&
      this.state.modalHistovecFirstname &&
      this.state.modalHistovecLicensePlate &&
      this.state.modalHistovecFormula
    ) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          getReportHistovec(
            {
              name: this.state.modalHistovecName,
              firstname: this.state.modalHistovecFirstname,
              licensePlate: this.state.modalHistovecLicensePlate,
              formula: this.state.modalHistovecFormula,
            },
            (result) => {
              if (result.success) {
                this.setState(
                  {
                    isLoad: false,
                    isModalHistovec: false,
                  },
                  () => {
                    const tmpDataHistovec = {
                      histovecOperations: result.data.operations,
                      histovecTechnicalControl: result.data.technical_control,
                      histovecReportUrl: result.data.url,
                    };
                    this.props.updateFolder("dataHistovec", tmpDataHistovec);
                  }
                );
              } else {
                this.setState(
                  {
                    isLoad: false,
                    isModalHistovec: true,
                  },
                  () => alert("Une erreur est survenue")
                );
              }
            }
          );
        }
      );
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  render() {
    return (
      <>
        <Card
          title="Histovec"
          className="mt-10 mt-20-xl"
          action={<FontAwesomeIcon icon={faSearch} />}
          onClick={() =>
            this.setState({
              modalHistovecName: this.props.dataIdentification["Nom"][0]
                ? this.props.dataIdentification["Nom"][0].split(" ")[0]
                : "",
              modalHistovecFirstname: this.props.dataIdentification["Nom"][0]
                ? this.props.dataIdentification["Nom"][0].split(" ")[1]
                : "",
              modalHistovecLicensePlate:
                this.props.dataIdentification["Immat"][0],
              modalHistovecFormula:
                this.props.dataIdentification["Numero_Formule"][0],
              isModalHistovec: true,
            })
          }
        >
          <h3>Historique des opérations</h3>
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Opération</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.dataHistovec &&
              this.props.dataHistovec.histovecOperations
                ? this.props.dataHistovec.histovecOperations.map(
                    (element, idx) => (
                      <Tr key={idx}>
                        <Td>{element.date}</Td>
                        <Td>{element.text}</Td>
                      </Tr>
                    )
                  )
                : null}
            </Tbody>
          </Table>

          <h3>Contrôles techniques</h3>
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Nature</Th>
                <Th>Résultat</Th>
                <Th>Kilométrage</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.dataHistovec &&
              this.props.dataHistovec.histovecTechnicalControl
                ? this.props.dataHistovec.histovecTechnicalControl.map(
                    (element, idx) => (
                      <Tr key={idx}>
                        <Td>{element.date}</Td>
                        <Td>{element.nature}</Td>
                        <Td>{element.result}</Td>
                        <Td>{element.mileage}</Td>
                      </Tr>
                    )
                  )
                : null}
            </Tbody>
          </Table>
        </Card>
        <Modal
          show={this.state.isModalHistovec}
          onClose={() => this.setState({ isModalHistovec: false })}
        >
          <FormInput
            className="mb-10"
            name="modalHistovecName"
            value={this.state.modalHistovecName}
            title="Nom"
            onChange={this.handleInputChange}
          />
          <FormInput
            className="mb-10"
            name="modalHistovecFirstname"
            value={this.state.modalHistovecFirstname}
            title="Prénom"
            onChange={this.handleInputChange}
          />
          <FormInput
            className="mb-10"
            name="modalHistovecLicensePlate"
            value={this.state.modalHistovecLicensePlate}
            title="Immatriculation"
            onChange={this.handleInputChange}
          />
          <FormInput
            className="mb-10"
            name="modalHistovecFormula"
            value={this.state.modalHistovecFormula}
            title="N° de formule"
            onChange={this.handleInputChange}
          />
          <div className="d-flex">
            <Button
              className="ml-auto w-auto"
              text="Synchroniser"
              onClick={() => this.getReportHistovec()}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </div>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
