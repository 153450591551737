import React from "react";
import { Card, FormInput, FormSelect } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default class Identification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
    };
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    let data = this.state[name] ? this.state[name] : [];
    data[0] = value;
    if (value) {
      data[1] = true;
    } else {
      data[1] = false;
    }
    this.setState({
      [name]: data,
    });
  };

  componentDidMount() {
    if (this.props.dataIdentification) {
      Object.keys(this.props.dataIdentification).map((key) => {
        this.setState({ [key]: this.props.dataIdentification[key] });
        return true;
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.dataIdentification) !==
        JSON.stringify(this.props.dataIdentification) &&
      this.props.dataIdentification
    ) {
      Object.keys(this.props.dataIdentification).map((key) => {
        this.setState({ [key]: this.props.dataIdentification[key] });
        return true;
      });
    }
  }

  getForm(value, options, type = "text") {
    if (options) {
      return (
        <FormSelect
          name={value}
          init={"Veuillez sélectionner une valeur"}
          ignore={true}
          value={
            this.state[value] && this.state[value][0]
              ? this.state[value][0]
              : "DEFAULT"
          }
          onChange={this.handleInputChange}
          options={options}
        />
      );
    } else {
      return (
        <FormInput
          type={type}
          name={value}
          value={
            this.state[value] && this.state[value][0]
              ? type === "date"
                ? moment(this.state[value][0], "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )
                : this.state[value][0]
              : ""
          }
          onChange={(e) => {
            type === "date"
              ? this.handleInputChange({
                  target: {
                    value: moment(e.target.value).format("DD/MM/YYYY"),
                    name: e.target.name,
                  },
                })
              : this.handleInputChange(e);
          }}
        />
      );
    }
  }

  search = (source, name) => {
    var results;
    results = source.map((entry) => {
      var match = entry.value.indexOf(name) !== -1;
      return match ? entry.text : null;
    });
    return results;
  };

  render() {
    return (
      <>
        <Card
          title={this.props.title}
          className="mt-10 mt-20-xl"
          action={
            this.state.isEdit ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faPen} />
            )
          }
          onClick={() => {
            this.setState(
              {
                isEdit: !this.state.isEdit,
              },
              () => {
                if (!this.state.isEdit) {
                  let data = {};
                  this.props.input.map((element) => {
                    if (this.state[element.value]) {
                      data[element.value] = this.state[element.value];
                    } else {
                      data[element.value] = [null, false];
                    }
                    return true;
                  });
                  this.props.onValid(data);
                }
              }
            );
          }}
        >
          <Row>
            {this.props.input.map((element, idx) => (
              <Col key={idx} className="mb-20" xs={12} md={4} lg={3}>
                <h3
                  className={
                    "mb-0 " +
                    ((element.required1 || element.required2) &&
                    (!this.state[element.value] ||
                      !this.state[element.value][0])
                      ? element.print1
                        ? "orange"
                        : "red"
                      : null)
                  }
                >
                  {element.name}
                  {element.required1 ? (
                    <span className={element.print1 ? "orange" : "red"}>
                      {" "}
                      *
                    </span>
                  ) : element.required2 ? (
                    <span className="red"> **</span>
                  ) : null}
                </h3>
                {this.state.isEdit ? (
                  this.getForm(element.value, element.options, element.type)
                ) : element.options ? (
                  <p
                    className={
                      "m-0 " +
                      ((element.required1 || element.required2) &&
                      (!this.state[element.value] ||
                        !this.state[element.value][0])
                        ? element.print1
                          ? "orange"
                          : "red"
                        : null)
                    }
                  >
                    {this.state[element.value]
                      ? this.search(
                          element.options,
                          this.state[element.value][0]
                        )
                      : null}
                  </p>
                ) : (
                  <p className="m-0">
                    {this.state[element.value]
                      ? this.state[element.value][0]
                      : null}
                  </p>
                )}
              </Col>
            ))}
          </Row>
        </Card>
      </>
    );
  }
}
