import React from "react";

import PartsTitle from "./PartsTitle";
import PartsTitleCustom from "./PartsTitleCustom";
import PartsStatus from "./PartsStatus";
import PartsStatusCustom from "./PartsStatusCustom";
import PartsValue from "./PartsValue";
import PartsPicture from "./PartsPicture";

export default class PartsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHide: true,
    };
  }

  onHide = () => {
    this.setState({
      isHide: !this.state.isHide,
    });
  };

  render() {
    return (
      <div className="parts-container">
        {!this.props.isCustom ? (
          <PartsTitle
            title={this.props.part.name}
            isValid={!!this.props.currentData}
            onClick={this.onHide}
            isHide={this.state.isHide}
          />
        ) : (
          <PartsTitleCustom
            value={
              this.props.currentData && this.props.currentData.title
                ? this.props.currentData.title
                : ""
            }
            onChange={(e) => {
              let tmpData = this.props.currentData;

              if (!tmpData) {
                tmpData = {};
              }
              tmpData.title = e.target.value;

              this.props.onChange(tmpData);
            }}
            isValid={!!this.props.currentData}
            onClick={this.onHide}
            isHide={this.state.isHide}
          />
        )}
        {!this.state.isHide || window.innerWidth > 600 ? (
          <>
            {!this.props.isCustom ? (
              <PartsStatus
                options={Object.keys(this.props.part.options).map((key2) => {
                  return {
                    value: key2,
                    text: this.props.part.options[key2][0],
                  };
                })}
                value={
                  this.props.currentData && this.props.currentData.state
                    ? this.props.currentData.state
                    : 0
                }
                onChange={(e) => {
                  let tmpData = this.props.currentData;

                  if (!tmpData) {
                    tmpData = {};
                  }
                  tmpData.state = e.target.value;
                  tmpData.value = this.props.part.options[e.target.value][1];

                  this.props.onChange(tmpData);
                }}
              />
            ) : (
              <PartsStatusCustom
                value={
                  this.props.currentData && this.props.currentData.status
                    ? this.props.currentData.status
                    : ""
                }
                onChange={(e) => {
                  let tmpData = this.props.currentData;

                  if (!tmpData) {
                    tmpData = {};
                  }
                  tmpData.status = e.target.value;

                  this.props.onChange(tmpData);
                }}
              />
            )}
            <PartsValue
              title={this.props.part.name ? this.props.part.name : ""}
              value={
                this.props.currentData && this.props.currentData.value
                  ? this.props.currentData.value
                  : 0
              }
              onUpdateValue={(value) => {
                let tmpData = this.props.currentData;

                if (!tmpData) {
                  tmpData = {};
                }
                tmpData.value = value;

                this.props.onChange(tmpData);
              }}
            />
            <PartsPicture
              picture={
                this.props.currentData && this.props.currentData.picture
                  ? this.props.currentData.picture
                  : 0
              }
              mime={
                this.props.currentData && this.props.currentData.mime
                  ? this.props.currentData.mime
                  : 0
              }
              onTakePictureClick={(picture, mime) => {
                let tmpData = this.props.currentData;

                if (!tmpData) {
                  tmpData = {};
                }
                tmpData.picture = picture;
                tmpData.mime = mime;

                this.props.onChange(tmpData);
              }}
            />
          </>
        ) : null}
      </div>
    );
  }
}
