import React from "react";

export default class PartsStatus extends React.Component {
  render() {
    return (
      <div className="parts-status">
        <select
          value={this.props.value}
          name={this.props.name}
          onChange={this.props.onChange}
        >
          {this.props.options.map((element, idx) => (
            <option key={`${this.props.name}-${idx}`} value={element.value}>
              {element.text}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
