import React from "react";
import { Card, Gauge, FormCheckbox } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

export default class Electric extends React.Component {
  handleGaugeChange = (value, name) => {
    let tmpDataElectric = this.props.dataElectric;
    tmpDataElectric[name] = value;
    this.props.updateFolder("dataElectric", tmpDataElectric);
  };

  handleCheckboxChange = (checked, name) => {
    let tmpDataElectric = this.props.dataElectric;
    tmpDataElectric[name] = checked;
    this.props.updateFolder("dataElectric", tmpDataElectric);
  };

  render() {
    if (![4, 6].includes(this.props.fuel)) {
      return null;
    }

    return (
      <>
        <Card title="Électrique" className="mt-10 mt-20-xl">
          <Row>
            <Col xs={12} md={6}>
              <Gauge
                ignore={true}
                className="mb-10"
                title={"SOH"}
                colors={["#db2828", "#0c8540"]}
                step={5}
                position={this.props.dataElectric["SOH"] || 0}
                onChange={(value) => this.handleGaugeChange(value, "SOH")}
              />
              <FormCheckbox
                className="mt-10"
                text="Inconnu"
                checked={this.props.dataElectric["checkboxSOH"] || false}
                onChange={({ target }) =>
                  this.handleCheckboxChange(target.checked, "checkboxSOH")
                }
              />
            </Col>
            <Col xs={12} md={6}>
              <Gauge
                ignore={true}
                className="mb-10"
                title={"SOC"}
                colors={["#db2828", "#0c8540"]}
                step={5}
                position={this.props.dataElectric["SOC"] || 0}
                onChange={(value) => this.handleGaugeChange(value, "SOC")}
              />
              <FormCheckbox
                className="mt-10"
                text="Inconnu"
                checked={this.props.dataElectric["checkboxSOC"] || false}
                onChange={({ target }) =>
                  this.handleCheckboxChange(target.checked, "checkboxSOC")
                }
              />
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
