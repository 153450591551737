import React from "react";

export default class PartsValue extends React.Component {
  render() {
    return (
      <div
        style={{ gap: 10, display: "flex" }}
        className={`parts-value ${
          this.props.className ? this.props.className : ""
        } ${
          this.props.value > 0 ? "green" : this.props.value < 0 ? "red" : ""
        }`}
      >
        <button
          onClick={() =>
            this.props.onUpdateValue(
              this.props.value - (this.props.title.includes("Pneu") ? 5 : 50)
            )
          }
        >
          -
        </button>
        <input
          value={this.props.value}
          className="input-administrative mr-0 ml-auto"
          onChange={(e) => this.props.onUpdateValue(parseInt(e.target.value))}
        />
        <span className="ml-0 m-auto">€</span>
        <button
          onClick={() =>
            this.props.onUpdateValue(
              this.props.value + (this.props.title.includes("Pneu") ? 5 : 50)
            )
          }
        >
          +
        </button>
      </div>
    );
  }
}
