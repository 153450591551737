import axiosConfig from "../utils/axiosConfig";

const count = (filters, callback) => {
  axiosConfig("market-search/count", {
    data: filters,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const pagination = (filters, callback) => {
  axiosConfig("market-search/pagination", {
    data: filters,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getAdsIds = (filters, callback) => {
  axiosConfig("market-search/get-ads-ids", {
    data: filters,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getSearch = (id, callback) => {
  axiosConfig("market-search/get-search", {
    data: { id },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const insertSearch = (id, filters, callback) => {
  axiosConfig("market-search/insert-search", {
    data: {
      id,
      filters,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const disableAd = (id, callback) => {
  axiosConfig("market-search/disable-ad", {
    data: {
      id,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const enableAd = (id, callback) => {
  axiosConfig("market-search/enable-ad", {
    data: {
      id,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getAd = (id, callback) => {
  axiosConfig("market-search/get-ad", {
    data: {
      id,
    },
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const addAd = (data, callback) => {
  axiosConfig("market-search/add-ad", {
    data: data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getAdAuto = (data, callback) => {
  axiosConfig("market-search/get-ad-auto", {
    data: data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export {
  count,
  pagination,
  getAdsIds,
  getSearch,
  insertSearch,
  disableAd,
  enableAd,
  getAd,
  addAd,
  getAdAuto,
};
