import axiosConfig from "../utils/axiosConfig";

const refreshToken = (token, url, callback) => {
  if (navigator.onLine) {
    axiosConfig("/auth/refresh-token", {
      data: {
        token,
        url,
      },
    })
      .then((res) => {
        callback(res.data);
      })
      .catch(() => callback({ success: false }));
  } else {
    callback({ success: false });
  }
};

const signout = (url, callback) => {
  if (navigator.onLine) {
    axiosConfig("/auth/signout", { data: { url } })
      .then((res) => {
        callback(res.data);
      })
      .catch(() => callback({ success: false }));
  } else {
    callback({ success: false });
  }
};

const getModel = (category, callback) => {
  axiosConfig("general/model", {
    data: { category },
  })
    .then((res) => {
      if (res.data.success) {
        let tmpArray = [{ value: "DEFAULT", text: "Toutes les marques" }];
        Object.keys(res.data.model).forEach((brand) => {
          tmpArray.push({ text: brand, value: brand });
        });

        callback({ success: true, models: res.data.model, brands: tmpArray });
      } else {
        callback({ success: false });
      }
    })
    .catch(() => callback({ success: false }));
};

export { refreshToken, signout, getModel };
