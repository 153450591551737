/*global chrome*/
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faMinusCircle,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faArrowCircleUp,
  faDownload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Status,
  ButtonIcon,
  Button,
  Pagination,
  Loader,
  Modal,
} from "ui-kit-ck-consultant";
import moment from "moment";

import ModalImage from "./ModalImage";
import ModalData from "./ModalData";
import ModalAddAd from "./ModalAddAd";

import { getPrice, getInt, getIcon } from "../../../utils/general";
import { getRegionFromDepartment } from "../../../utils/region";

import { addAd, getAd } from "../../../requests/marketSearch";

import frFlag from "../../../images/flag/fr.svg";
import deFlag from "../../../images/flag/de.svg";
import beFlag from "../../../images/flag/be.svg";
import luFlag from "../../../images/flag/lu.svg";
import nlFlag from "../../../images/flag/nl.svg";
import itFlag from "../../../images/flag/it.svg";

import tutorielExtensionAd from "../../../videos/tutoriel-extension-ad.webm";

import leboncoinLogo from "../../../images/website/leboncoin-icon.png";
import lacentraleLogo from "../../../images/website/lacentrale-icon.png";
import leparkingLogo from "../../../images/website/leparking-icon.png";
import autoscout24Logo from "../../../images/website/autoscout24-icon.png";
import paruvenduLogo from "../../../images/website/paruvendu-icon.png";
import largusLogo from "../../../images/website/largus-icon.png";
import luxautoLogo from "../../../images/website/luxauto-icon.png";
import mobileLogo from "../../../images/website/mobile-icon.png";
import ouestfranceLogo from "../../../images/website/ouestfrance-icon.png";

export default class Ads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      order: {},
      isModalImage: false,
      modalImageSrc: null,
      isModalData: false,
      modalDataIdx: null,
      ads: [],
      isModalAddAd: false,
      isModalExtension: false,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  extensionScript = (event) => {
    if (event.data.type === "ads") {
      this.setState(
        {
          isLoad: true,
        },
        async () => {
          const keys = Object.keys(event.data.ads);
          for (let index = 0; index < keys.length; index++) {
            const ad = event.data.ads[keys[index]];

            await new Promise((resolve) =>
              addAd(
                {
                  url: ad.url,
                  title: ad.title,
                  image: ad.image,
                  brand: ad.brand,
                  model: ad.model,
                  mileage: ad.mileage,
                  year: ad.date_circulation
                    ? moment(ad.date_circulation, "YYYY-MM-DD").format("YYYY")
                    : ad.year,
                  date_circulation: ad.date_circulation
                    ? ad.date_circulation
                    : `${ad.year}-01-01`,
                  date_index: ad.date_index,
                  zipcode: ad.zipcode,
                  gearbox: ad.gearbox,
                  fuel: ad.fuel,
                  price: ad.price,
                  description: ad.description,
                  gender: ad.gender,
                  country: ad.country,
                  type: ad.type,
                },
                (result) => {
                  if (result.success) {
                    this.onClickupdateStatusAd(null, true, result.id, () =>
                      resolve(true)
                    );
                  } else {
                    resolve(false);
                  }
                }
              )
            );
            chrome.runtime.sendMessage("jdnicomidkbnpaaiglomgpcpacaighkj", {
              type: "response",
            });
          }

          this.setState({ isLoad: false });
        }
      );
    }
  };

  componentDidMount() {
    window.addEventListener("message", this.extensionScript);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.extensionScript);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.ads) !== JSON.stringify(prevProps.ads) ||
      (!this.state.ads.length && this.props.ads.length)
    ) {
      this.setState({
        ads: this.props.ads ? this.props.ads : [],
      });
    }
  }

  handleSortClick = (desc, column) => {
    this.sortRef.map((elem, idx) => {
      if (idx !== column) {
        this.sortRef[idx].current.setState({ desc: false, sort: false });
      }
      return null;
    });

    let order = { name: this.sortRef[column].current.props.nameSort, desc };

    this.setState(
      {
        order,
      },
      () => {
        if (this.props.isPagination) {
          this.props.onClickSearch();
        }
      }
    );
  };

  onClickOpenModalData = (e, idx) => {
    if (
      !e.target.matches(".not-click") &&
      !e.target.parentNode.matches(".not-click") &&
      !e.target.parentNode.parentNode.matches(".not-click") &&
      !e.target.parentNode.parentNode.parentNode.matches(".not-click") &&
      !e.target.parentNode.parentNode.parentNode.parentNode.matches(
        ".not-click"
      ) &&
      !e.target.parentNode.parentNode.parentNode.parentNode.parentNode.matches(
        ".not-click"
      )
    ) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          getAd(this.state.ads[idx].id, (result) => {
            if (result.success) {
              let tmpAds = this.state.ads;

              tmpAds[idx] = { ...tmpAds[idx], ...result.data };

              this.setState({
                isLoad: false,
                isModalData: true,
                modalDataIdx: idx,
                ads: tmpAds,
              });
            } else {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  alert("Une erreur est survenue");
                }
              );
            }
          });
        }
      );
    }
  };

  updateFolderSelect = (idx, data) => {
    let tmpDataSelect = JSON.parse(JSON.stringify(this.props.dataSelect));

    if (tmpDataSelect.hasOwnProperty(this.state.ads[idx].id)) {
      tmpDataSelect[this.state.ads[idx].id] = {
        ...tmpDataSelect[this.state.ads[idx].id],
        ...data,
      };
    } else {
      tmpDataSelect[this.state.ads[idx].id] = data;
    }

    this.props.updateFolder("dataSelect", tmpDataSelect);
  };

  onClickupdateStatusAd = (idx, status, id = null, callback = () => {}) => {
    let tmpDataSelect = JSON.parse(JSON.stringify(this.props.dataSelect));

    if (tmpDataSelect.hasOwnProperty(id ? id : this.state.ads[idx].id)) {
      tmpDataSelect[this.state.ads[idx].id].status = status;
      if (status === true) {
        tmpDataSelect[this.state.ads[idx].id].date = moment();
      }
    } else {
      tmpDataSelect[id ? id : this.state.ads[idx].id] = {
        status,
        date: status === true ? moment() : undefined,
      };
    }

    this.props.updateFolder("dataSelect", tmpDataSelect, () => {
      callback();
      if (id) {
        this.props.onChangeTab();
      }
    });
  };

  getColorAd = (idx) => {
    if (this.state.ads[idx] && this.props.dataSelect[this.state.ads[idx].id]) {
      if (this.props.dataSelect[this.state.ads[idx].id].status === true) {
        return "bg-green-light";
      } else if (
        this.props.dataSelect[this.state.ads[idx].id].status === false
      ) {
        return "bg-red-light";
      }
    } else {
      return "";
    }
  };

  getFlag = (country) => {
    if (country === "FR") {
      return frFlag;
    } else if (country === "DE") {
      return deFlag;
    } else if (country === "BE") {
      return beFlag;
    } else if (country === "LU") {
      return luFlag;
    } else if (country === "NL") {
      return nlFlag;
    } else if (country === "IT") {
      return itFlag;
    }
  };

  getCorrectValue(dataEdit, data, name) {
    if (name === "date" || name === "status") {
      return data[name];
    }

    if (dataEdit[name]) {
      return name === "price" && dataEdit["typePrice"] === "HT"
        ? dataEdit[name] * 1.2
        : dataEdit[name];
    } else {
      return name === "price" && dataEdit["typePrice"] === "HT"
        ? data[name] * 1.2
        : data[name];
    }
  }

  getEndPoint(url) {
    switch (url) {
      case "https://leboncoin.fr/":
        return this.getSitesEndPoint("leboncoin");
      case "https://www.autoscout24.fr/":
        return this.getSitesEndPoint("autoscout");
      case "https://www.paruvendu.fr/":
        return this.getSitesEndPoint("paruvendu");
      case "https://www.luxauto.lu/":
        return this.getSitesEndPoint("luxauto");
      case "https://www.mobile.de/":
        return this.getSitesEndPoint("mobile");
      case "https://www.lacentrale.fr/":
        return this.getSitesEndPoint("lacentrale");
      case "https://www.ouestfrance-auto.com/":
        return this.getSitesEndPoint("ouestfrance");
      case "https://occasion.largus.fr/":
        return this.getSitesEndPoint("largus");
      default:
        break;
    }
  }

  getItemAd = (logo, url) => {
    return (
      <div
        className="mt-auto mb-auto bg-gray p-5"
        style={{
          display: "inline-flex",
          cursor: "pointer",
          borderRadius: 8,
        }}
        onClick={() => window.open(url + this.getEndPoint(url), "_blank")}
      >
        <img
          style={{
            borderRadius: 6,
          }}
          className="m-auto"
          height={28}
          src={logo}
          alt={url}
        />
      </div>
    );
  };

  getVehicleCategory(type) {
    switch (type) {
      case "0":
        return "voitures";
      case "1":
        return "motos/offres";
      case "2":
        return "utilitaire/offres";
      case "3":
        return "camping-car";
      case "4":
        return "caravane";
      default:
        return "voiture";
    }
  }

  getSitesEndPoint(site) {
    let baseUrl = "";
    // for autoscout
    let endOfUrl = "";

    if (this.props.filtersRef) {
      const {
        brand,
        model,
        priceMin,
        priceMax,
        type,
        gearbox,
        yearMin,
        yearMax,
        fuel,
        door,
        seat,
        mileageMin,
        mileageMax,
        category,
      } = this.props.filtersRef.state;

      const isSelect = (value) =>
        value !== "DEFAULT" && value !== undefined && value !== null;
      const isBrandSelect = isSelect(brand);

      const isModelSelect = isSelect(model);
      const isPriceSelect = isSelect(priceMin) || isSelect(priceMax);
      const isTypeSelect = isSelect(type);
      const isGearboxSelect = isSelect(gearbox);
      const isYearSelect = isSelect(yearMin) || isSelect(yearMax);
      const isFuelSelect = isSelect(fuel);
      const isDoorSelect = isSelect(door);
      const isSeatSelect = isSelect(seat);
      const isMileageSelect = isSelect(mileageMin) || isSelect(mileageMax);

      const isMileageMinSelect = isSelect(mileageMin);
      const isMileageMaxSelect = isSelect(mileageMax);

      const isYearMinSelect = isSelect(yearMin);
      const isYearMaxSelect = isSelect(yearMax);

      const isPriceMinSelect = isSelect(priceMin);
      const isPriceMaxSelect = isSelect(priceMax);

      if (site === "leboncoin") {
        baseUrl = `/recherche?category=2`;
        if (isBrandSelect) {
          baseUrl = baseUrl += `&u_car_brand=${brand}`;
        }

        if (isModelSelect) {
          const modelSplit = model.includes(" ") ? model.split(" ") : [];

          const modelReformat = modelSplit.length
            ? modelSplit[0].charAt(0).toUpperCase() +
              modelSplit[0].slice(1).toLowerCase() +
              "%20" +
              (modelSplit[1].length > 1
                ? modelSplit[1].charAt(0).toUpperCase() +
                  modelSplit[1].slice(1).toLowerCase()
                : modelSplit[1])
            : model.charAt(0).toUpperCase() + model.slice(1).toLowerCase();

          baseUrl += `&u_car_model=${brand}_${model}%2C${brand}_${modelReformat}`;
        }

        if (isPriceSelect)
          baseUrl += `&price=${isPriceMinSelect ? priceMin : "min"}-${
            isPriceMaxSelect ? priceMax : "max"
          }`;

        if (isTypeSelect) baseUrl += `&vehicle_type=${type}`;

        if (isGearboxSelect) baseUrl += `&gearbox=${gearbox}`;

        if (isYearSelect)
          baseUrl += `&regdate=${isYearMinSelect ? yearMin : "min"}-${
            isYearMaxSelect ? yearMax : "max"
          }`;

        if (isFuelSelect) baseUrl += `&fuel=${fuel}`;

        if (isDoorSelect) baseUrl += `&doors=${door}`;

        if (isSeatSelect) baseUrl += `&seats=${seat}`;

        if (isMileageSelect)
          baseUrl += `&mileage=${isMileageMinSelect ? mileageMin : "min"}-${
            isMileageMaxSelect ? mileageMax : "max"
          }`;
      } else if (site === "autoscout") {
        baseUrl = `/lst`;
        endOfUrl = "?atype=C";

        const getType = (type) => {
          switch (type) {
            case "citadine":
              return 1;
            case "cabriolet":
              return 2;
            case "coupe":
              return 3;
            case "4x4":
            case "pickup":
              return 4;
            case "break":
              return 5;
            case "berline":
              return 6;
            case "monospace":
              return 12;
            case "autre":
            case "minibus":
            case "voiture sans permis":
            case "voituresociete":
              return 7;
            default:
              return 1;
          }
        };

        const getFuel = (fuel) => {
          switch (fuel) {
            case 1:
              return "B";
            case 2:
              return "D";
            case 3:
              return "L";
            case 4:
              return "E";
            case 5:
              return "O";
            case 6:
              return "3%2C2";
            default:
              return "D";
          }
        };

        if (isBrandSelect) {
          baseUrl = baseUrl += `/${brand}`;
        }
        if (isModelSelect) {
          const modelException = {
            "MERCEDES-BENZ": ["CLASSE A", "CLASSE B", "CLASSE C"],
          };

          baseUrl = baseUrl += `/${model}${
            modelException[brand] && modelException[brand].includes(model)
              ? "-(tous)"
              : ""
          }`;
        }

        if (isTypeSelect) endOfUrl = endOfUrl += `&body=${getType(type)}`;

        if (isFuelSelect) endOfUrl = endOfUrl += `&fuel=${getFuel(fuel)}`;

        if (isMileageSelect) {
          endOfUrl = endOfUrl += `${
            isMileageMinSelect ? `&kmfrom=${mileageMin}` : ""
          }${isMileageMaxSelect ? `&kmto=${mileageMax}` : ""}`;
        }
        if (isGearboxSelect)
          endOfUrl = endOfUrl += `&gear=${gearbox === "1" ? "M" : "A"}`;

        if (isPriceSelect) {
          endOfUrl = endOfUrl += `${
            isPriceMinSelect ? `&pricefrom=${priceMin}` : ""
          }${isPriceMaxSelect ? `&priceto=${priceMax}` : ""}`;
        }

        if (isSeatSelect) endOfUrl += `&seatsfrom=1&seatsto=${seat}`;

        if (isDoorSelect) endOfUrl += `&doorfrom=1&doorto=${door}`;

        if (isYearSelect)
          endOfUrl = endOfUrl += `${
            isYearMinSelect ? `&fregfrom=${yearMin}` : ""
          }${isYearMaxSelect ? `&fregto=${yearMax}` : ""}`;
      } else if (site === "paruvendu") {
        baseUrl = "a/voiture-occasion";

        if (isBrandSelect) baseUrl = baseUrl += `/${brand.toLowerCase()}`;
        if (isModelSelect) baseUrl = baseUrl += `/${model}`;
      } else if (site === "luxauto") {
        baseUrl = `fr/occasions/search/?`;

        const getFuel = (fuel) => {
          switch (fuel) {
            case "1":
              return "essence";
            case "2":
              return "diesel";
            case "3":
              return "gpl";
            case "4":
              return "electrique";
            case "5":
              return "hybride";
            case "6":
              return "hybride";
            default:
              return "diesel";
          }
        };

        if (isBrandSelect) baseUrl = baseUrl += `&marque=${brand}`;

        if (isModelSelect) {
          baseUrl = baseUrl += `&modele=${model}`;
        }

        if (isFuelSelect) {
          baseUrl = baseUrl += `&carburant%5B0%5D=${getFuel(fuel)}`;
        }

        if (isPriceSelect) {
          baseUrl = baseUrl += `${
            (isPriceMinSelect ? `&fx_prixde=${priceMin}` : "") +
            (isPriceMaxSelect ? `&fx_prixa=${priceMax}` : "")
          }`;
        }

        if (isMileageSelect) {
          baseUrl = baseUrl += `${
            isMileageMinSelect ? `&fx_kmde=${mileageMin}` : ""
          }${isMileageMinSelect ? `&fx_kma=${mileageMax}` : ""}`;
        }
        if (isYearSelect)
          baseUrl = baseUrl += `${
            (isYearMinSelect ? `&fx_anneede=${yearMin}` : "") +
            (isYearMaxSelect ? `&fx_anneea=${yearMax}` : "")
          }`;
      } else if (site === "mobile") {
        baseUrl = "";
      } else if (site === "lacentrale") {
        baseUrl = "listing?";

        const getType = (type) => {
          switch (type) {
            case "citadine":
              return 40;
            case "cabriolet":
              return 46;
            case "coupe":
              return 45;
            case "4x4":
              return 47;
            case "pickup":
              return 50;
            case "break":
              return 43;
            case "berline":
              return "41_42";
            case "monospace":
              return 44;
            case "autre":
              return 83;
            case "minibus":
              return 82;
            case "voiture sans permis":
              return 48;
            case "voituresociete":
              return 80;
            default:
              return 40;
          }
        };

        const getFuel = (fuel) => {
          switch (fuel) {
            case 1:
              return "ess";
            case 2:
              return "dies";
            case 3:
              return "gpl";
            case 4:
              return "elec";
            case 5:
              return "alt";
            case 6:
              return "plug_hyb%2Cnot_plug_hyb";
            default:
              return "dies";
          }
        };

        if (isBrandSelect && !isModelSelect)
          baseUrl += `&makesModelsCommercialNames=${brand}`;

        if (isModelSelect)
          baseUrl += `&makesModelsCommercialNames=${brand}%3A${model}`;

        if (isTypeSelect) baseUrl += `&categories=${getType(type)}`;

        if (isSeatSelect) baseUrl += `&seats=${seat}`;

        if (isDoorSelect) baseUrl += `&doors=${door}`;

        if (isFuelSelect) baseUrl += `&energies=${getFuel(fuel)}`;

        if (isGearboxSelect)
          baseUrl += `&gearbox=${gearbox === "1" ? "MANUAL" : "AUTO"}`;

        if (isYearSelect)
          baseUrl += `${isYearMinSelect ? `&yearMin=${yearMin}` : ""}${
            isYearMaxSelect ? `&yearMax=${yearMax}` : ""
          }`;

        if (isMileageSelect) {
          baseUrl += `${isMileageMinSelect ? `&mileageMin=${mileageMin}` : ""}${
            isMileageMaxSelect ? `&mileageMax=${mileageMax}` : ""
          }`;
        }

        if (isPriceSelect) {
          baseUrl += `${isPriceMinSelect ? `&priceMin=${priceMin}` : ""}${
            isPriceMaxSelect ? `&priceMax=${priceMax}` : ""
          }`;
        }
      } else if (site === "ouestfrance") {
        baseUrl = "voiture-occasion/annonces/?";

        const getType = (type) => {
          switch (type) {
            case "citadine":
              return 27;
            case "cabriolet":
              return 22;
            case "coupe":
              return 21;
            case "4x4":
              return 23;
            case "pickup":
              return 12;
            case "break":
              return 2;
            case "berline":
              return 1;
            case "monospace":
              return 9;
            case "minibus":
              return 7;
            case "voiture sans permis":
              return 14;
            case "voituresociete":
            case "autre":
              return "";
            default:
              return 27;
          }
        };

        if (isBrandSelect) baseUrl += `&libelleMarque%5B%5D=${brand}`;

        if (isModelSelect) baseUrl += `&libelleModele%5B%5D=${model}`;

        if (isTypeSelect) baseUrl += `&idChassis%5B%5D=${getType(type)}`;

        if (isSeatSelect) baseUrl += `&nbPortesI%5B%5D=${seat}`;

        if (isDoorSelect) baseUrl += `&nbPlacesI%5B%5D=${door}`;

        if (isFuelSelect)
          baseUrl += `&idCarburant%5B%5D=${fuel === 5 ? 8 : fuel}`;

        if (isGearboxSelect) baseUrl += `&idBoiteVitesse%5B%5D=${gearbox}`;

        if (isYearSelect)
          baseUrl += `${isYearMinSelect ? `&annee-min=${yearMin}` : ""}${
            isYearMaxSelect ? `&annee-max=${yearMax}` : ""
          }`;

        if (isMileageSelect) {
          baseUrl += `${
            isMileageMinSelect ? `&kilometrage-min=${mileageMin}` : ""
          }${isMileageMaxSelect ? `&kilometrage-max=${mileageMax}` : ""}`;
        }

        if (isPriceSelect) {
          baseUrl += `${isPriceMinSelect ? `&prix-min=${priceMin}` : ""}${
            isPriceMaxSelect ? `&prix-max=${priceMax}` : ""
          }`;
        }
      } else if (site === "largus") {
        baseUrl = "auto/";

        const getType = (type) => {
          switch (type) {
            case "citadine":
              return "citadines";
            case "cabriolet":
              return "coupes-cabriolets";
            case "coupe":
              return "coupes-cabriolets";
            case "4x4":
              return "4x4-suv";
            case "pickup":
              return "4x4-suv";
            case "break":
              return "berlines-breaks";
            case "berline":
              return "berlines-breaks";
            case "monospace":
              return "monospaces";
            case "minibus":
            case "voiture sans permis":
            case "voituresociete":
            case "autre":
              return "";
            default:
              return "citadines";
          }
        };

        const getFuel = (fuel) => {
          switch (fuel) {
            case 1:
              return "eseence";
            case 2:
              return "diesel";
            case 3:
              return "gpl";
            case 4:
              return "electrique";
            case 5:
              return "hydrogene";
            case 6:
              return "gaz";
            default:
              return "diesel";
          }
        };

        if (isBrandSelect)
          baseUrl += `${brand.toLowerCase()}/${!isModelSelect ? "?" : ""}`;

        if (isModelSelect) baseUrl += `${model.toLowerCase()}/?`;

        if (isTypeSelect) baseUrl += `&category=${getType(type)}`;

        if (isSeatSelect) baseUrl += `&numberofseat=${seat}`;

        if (isDoorSelect) baseUrl += `&numberofdoor=${door}`;

        if (isFuelSelect) baseUrl += `&energy=${getFuel(fuel)}`;

        if (isGearboxSelect)
          baseUrl += `&gearbox=${gearbox === "1" ? "manuelle" : "automatique"}`;

        if (isYearSelect)
          baseUrl += `${isYearMinSelect ? `&year%5Bmin%5D=${yearMin}` : ""}${
            isYearMaxSelect ? `&year%5Bmax%5D=${yearMax}` : ""
          }`;

        if (isMileageSelect) {
          baseUrl += `${
            isMileageMinSelect ? `&km%5Bmin%5D=${mileageMin}` : ""
          }${isMileageMaxSelect ? `&km%5Bmax%5D=${mileageMax}` : ""}`;
        }

        if (isPriceSelect) {
          baseUrl += `${isPriceMinSelect ? `&price%5Bmin%5D=${priceMin}` : ""}${
            isPriceMaxSelect ? `&price%5Bmax%5D=${priceMax}` : ""
          }`;
        }
      }
    }

    return baseUrl + endOfUrl;
  }

  render() {
    let ads = JSON.parse(JSON.stringify(this.props.ads));

    ads.forEach((ad, idx) => {
      Object.keys(ad).forEach((key) => {
        if (this.props.dataSelect && this.props.dataSelect[ad.id]) {
          ads[idx][key] = this.getCorrectValue(
            this.props.dataSelect[ad.id],
            ad,
            key
          );
        }
      });
    });

    return (
      <>
        <Card
          title="Annonces"
          className="mt-10 mt-20-xl"
          disableStyleAction
          action={
            <div
              className="d-flex"
              style={{
                gap: 10,
              }}
            >
              {this.getItemAd(leboncoinLogo, `https://leboncoin.fr/`)}
              {this.getItemAd(
                leparkingLogo,
                "https://www.leparking.fr/voiture-occasion/.html"
              )}
              {this.getItemAd(autoscout24Logo, "https://www.autoscout24.fr/")}
              {this.getItemAd(paruvenduLogo, "https://www.paruvendu.fr/")}
              {this.getItemAd(luxautoLogo, "https://www.luxauto.lu/")}
              {this.getItemAd(mobileLogo, "https://www.mobile.de/")}
              {this.getItemAd(lacentraleLogo, "https://www.lacentrale.fr/")}
              {this.getItemAd(
                ouestfranceLogo,
                "https://www.ouestfrance-auto.com/"
              )}
              {this.getItemAd(largusLogo, "https://occasion.largus.fr/")}
              <Button
                text="Extension récupération annonces"
                className="mr-10"
                onClick={() =>
                  this.setState({
                    isModalExtension: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <Button
                text="Ajouter une annonce manuellement"
                onClick={() =>
                  this.setState({
                    isModalAddAd: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          }
        >
          <Table>
            <Thead>
              <Tr>
                <Th>Image</Th>
                <Th>Titre</Th>
                <Th>Finition</Th>
                <Th>Type</Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 0)}
                  ref={this.sortRef[0]}
                  nameSort="mileage"
                >
                  Kilométrage
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                  ref={this.sortRef[1]}
                  nameSort="date_circulation"
                >
                  Année
                </Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 2)}
                  ref={this.sortRef[2]}
                  nameSort="price"
                >
                  Prix
                </Th>
                <Th>Région</Th>
                <Th>Source</Th>
                <Th
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 3)}
                  ref={this.sortRef[3]}
                  nameSort="date"
                >
                  Date
                </Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {ads.map((element, idx) => {
                if (
                  !this.props.filtersRef ||
                  (this.props.filtersRef &&
                    (this.props.filtersRef.state.isAllStatus ||
                      element.status === 0))
                ) {
                  return (
                    <Tr
                      key={idx}
                      className="cursor-pointer"
                      onClick={(e) => this.onClickOpenModalData(e, idx)}
                    >
                      <Td
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          padding: "0 !important",
                        }}
                        className={this.getColorAd(idx)}
                      >
                        {element.image ? (
                          <img
                            onError={() => {
                              if (
                                element.image &&
                                element.source !== "evalcar"
                              ) {
                                this.props.onClickDisableAd(idx);
                              }
                            }}
                            className="not-click cursor-pointer"
                            style={{
                              position: "absolute",
                              width: " 100%",
                              top: "0",
                              bottom: "0",
                              left: " 50%",
                              transform: "translateX(-50%)",
                            }}
                            src={element.image}
                            alt="car"
                            onClick={() =>
                              this.setState({
                                isModalImage: true,
                                modalImageSrc: element.image,
                              })
                            }
                          />
                        ) : null}
                      </Td>
                      <Td className={this.getColorAd(idx)}>
                        <div className="d-flex">
                          {element.status ? (
                            <Status
                              text="désactivée"
                              className="mt-auto mb-auto bg-orange"
                              style={{ marginRight: "6px" }}
                            />
                          ) : null}{" "}
                          {element.type ? (
                            <Status
                              text={element.type}
                              className="mt-auto mb-auto"
                              style={{ marginRight: "6px" }}
                            />
                          ) : null}
                          <span
                            style={{ display: "inline-block" }}
                            className="mt-auto mb-auto"
                          >
                            {element.title}
                          </span>
                        </div>
                      </Td>
                      <Td className={this.getColorAd(idx)}>{element.finish}</Td>
                      <Td className={this.getColorAd(idx)}>{element.type}</Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        {getInt(element.mileage)} km
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        {element.date_circulation}
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        {getPrice(element.price)}
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        <div className="d-flex">
                          <img
                            className="mt-auto mb-auto mr-10"
                            height={18}
                            src={this.getFlag(element.country)}
                            alt={`flag-${element.country}`}
                            style={{ borderRadius: "6px" }}
                          />
                          {element.country && element.country.includes("FR")
                            ? `
                            ${getRegionFromDepartment(element.department)} ${
                                element["zipcode"]
                                  ? ` - ${element["zipcode"]}`
                                  : ""
                              }`
                            : null}
                        </div>
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        {!element.status ? (
                          <div className="d-flex">
                            <img
                              className="not-click cursor-pointer m-auto"
                              onClick={() => window.open(element.url, "_blank")}
                              alt="icon"
                              height={24}
                              src={getIcon(element.source, element.url)}
                              style={{ borderRadius: "6px" }}
                            />
                          </div>
                        ) : null}
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        {element.date}
                      </Td>
                      <Td className={`nowrap ${this.getColorAd(idx)}`}>
                        <ButtonIcon
                          className="not-click green"
                          small
                          onClick={() => this.onClickupdateStatusAd(idx, true)}
                        >
                          <FontAwesomeIcon icon={faThumbsUp} />
                        </ButtonIcon>
                        <ButtonIcon
                          className="not-click red"
                          small
                          onClick={() => this.onClickupdateStatusAd(idx, false)}
                        >
                          <FontAwesomeIcon icon={faThumbsDown} />
                        </ButtonIcon>
                        {!element.status ? (
                          <ButtonIcon
                            className="not-click orange"
                            small
                            onClick={() => this.props.onClickDisableAd(idx)}
                          >
                            <FontAwesomeIcon icon={faMinusCircle} />
                          </ButtonIcon>
                        ) : null}
                        {element.status === 2 ? (
                          <ButtonIcon
                            className="not-click green"
                            small
                            onClick={() => this.props.onClickEnableAd(idx)}
                          >
                            <FontAwesomeIcon icon={faArrowCircleUp} />
                          </ButtonIcon>
                        ) : null}
                      </Td>
                    </Tr>
                  );
                } else {
                  return null;
                }
              })}
            </Tbody>
          </Table>
          {this.props.isPagination ? (
            <Pagination
              className="mt-10"
              activePage={this.props.activePage}
              itemsCountPerPage={this.props.tabLength}
              totalItemsCount={this.props.total}
              pageRangeDisplayed={6}
              onChange={this.props.handlePageChange.bind(this)}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          ) : null}
        </Card>
        <ModalImage
          show={this.state.isModalImage}
          src={this.state.modalImageSrc}
          onClose={() =>
            this.setState({
              isModalImage: false,
              modalImageSrc: null,
            })
          }
        />
        <ModalData
          gender={this.props.gender}
          show={this.state.isModalData}
          ad={
            this.state.ads &&
            this.state.ads.hasOwnProperty(this.state.modalDataIdx)
              ? this.state.ads[this.state.modalDataIdx]
              : {}
          }
          select={
            this.props.dataSelect &&
            this.state.ads &&
            this.state.ads.hasOwnProperty(this.state.modalDataIdx)
              ? this.props.dataSelect[
                  this.state.ads[this.state.modalDataIdx].id
                ]
              : {}
          }
          dataSelect={this.props.dataSelect}
          updateFolderSelect={this.updateFolderSelect}
          idx={this.state.modalDataIdx}
          onClickDisableAd={this.props.onClickDisableAd}
          onClickEnableAd={this.props.onClickEnableAd}
          onClickAddAd={(idx) => this.onClickupdateStatusAd(idx, true)}
          onClickRemoveAd={(idx) => this.onClickupdateStatusAd(idx, false)}
          onClose={() =>
            this.setState({
              isModalData: false,
            })
          }
        />
        <ModalAddAd
          gender={this.props.gender}
          show={this.state.isModalAddAd}
          onClose={(id) =>
            this.setState(
              {
                isModalAddAd: false,
              },
              () => {
                if (id) {
                  this.onClickupdateStatusAd(null, true, id);
                }
              }
            )
          }
        />
        <Modal
          show={this.state.isModalExtension}
          title="Extension récupération annonces"
          onClose={() => this.setState({ isModalExtension: false })}
          large
        >
          <video style={{ width: "100%", borderRadius: "15px" }} controls>
            <source src={tutorielExtensionAd} type="video/webm" />
          </video>
          <Button
            className="mt-10"
            text="Télécharger l'extension"
            onClick={() => {
              window
                .open(
                  "https://chrome.google.com/webstore/detail/evalcar/jdnicomidkbnpaaiglomgpcpacaighkj",
                  "_blank"
                )
                .focus();
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
