import React from "react";
import { faEye, faEyeSlash, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-flexbox-grid";
import {
  Card,
  FormSelect,
  FormRange,
  FormInput,
  Button,
  FormCheckbox,
  FormSelectMultiples,
} from "ui-kit-ck-consultant";

import CountryMap from "./CountryMap";

import {
  getYearOptions,
  getMileageOptions,
  getPriceOptions,
} from "../../../utils/dataSelect";

import { getModel } from "../../../requests/general";
import { getSearch } from "../../../requests/marketSearch";

export default class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHide: true,
      categoryOptions: [
        { value: 0, text: "Voiture" },
        { value: 1, text: "Moto" },
        { value: 2, text: "Utilitaire" },
        { value: 3, text: "Camping-car" },
        { value: 4, text: "Caravane" },
      ],
      sourceOptions: [
        { value: "leboncoin", text: "Leboncoin" },
        { value: "lacentrale", text: "La Centrale" },
        { value: "leparking", text: "LeParking" },
        { value: "autoscout24", text: "AutoScout24" },
        { value: "paruvendu", text: "ParuVendu" },
        { value: "luxauto", text: "Luxauto" },
        { value: "mobile", text: "Mobile.de" },
        { value: "evalcar", text: "EvalCar" },
      ],
      brandOptions: [],
      modelOptions: null,
      fuelOptions: [
        { value: "DEFAULT", text: "Tous les carburants" },
        { value: 1, text: "Essence" },
        { value: 2, text: "Diesel" },
        { value: 3, text: "GPL" },
        { value: 4, text: "Electrique" },
        { value: 5, text: "Autre" },
        { value: 6, text: "Hybride" },
      ],
      gearboxOptions: [
        { value: "DEFAULT", text: "Toutes les boîtes de vitesse" },
        { value: 1, text: "Manuelle" },
        { value: 2, text: "Automatique" },
      ],
      yearMinOptions: [],
      yearMaxOptions: [],
      mileageMinOptions: [],
      mileageMaxOptions: [],
      priceMinOptions: [],
      priceMaxOptions: [],
      category: "DEFAULT",
      sources: [],
      countries: ["FR"],
      brand: "DEFAULT",
      model: "DEFAULT",
      type: "DEFAULT",
      cycleType: "DEFAULT",
      license: "DEFAULT",
      seat: "DEFAULT",
      search: "",
      fuel: "DEFAULT",
      gearbox: "DEFAULT",
      yearMin: "DEFAULT",
      yearMax: "DEFAULT",
      mileageMin: "DEFAULT",
      mileageMax: "DEFAULT",
      priceMin: "DEFAULT",
      priceMax: "DEFAULT",
      dateMin: "",
      dateMax: "",
      cylinderMin: "",
      cylinderMax: "",
      isAllRegion: false,
      isAllStatus: false,
      isIsolateWord: false,
      isOut: false,
      isTitle: false,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "category" && ["0", "1"].includes(value)) {
          this.getBrandModel(() => {}, value);
        } else if (name === "category") {
          this.setState({
            brand: "DEFAULT",
            model: "DEFAULT",
          });
        } else if (name === "yearMin") {
          this.setState({
            yearMaxOptions: this.disableOptions(
              this.state.yearMaxOptions,
              this.state.yearMin,
              "<"
            ),
          });
        } else if (name === "mileageMin") {
          this.setState({
            mileageMaxOptions: this.disableOptions(
              this.state.mileageMaxOptions,
              this.state.mileageMin,
              "<"
            ),
          });
        } else if (name === "priceMin") {
          this.setState({
            priceMaxOptions: this.disableOptions(
              this.state.priceMaxOptions,
              this.state.priceMin,
              "<"
            ),
          });
        } else if (name === "yearMax") {
          this.setState({
            yearMinOptions: this.disableOptions(
              this.state.yearMinOptions,
              this.state.yearMax,
              ">"
            ),
          });
        } else if (name === "mileageMax") {
          this.setState({
            mileageMinOptions: this.disableOptions(
              this.state.mileageMinOptions,
              this.state.mileageMax,
              ">"
            ),
          });
        } else if (name === "priceMax") {
          this.setState({
            priceMinOptions: this.disableOptions(
              this.state.priceMinOptions,
              this.state.priceMax,
              ">"
            ),
          });
        }
      }
    );
  };

  getBrandModel = (callback, gender = null) => {
    getModel(gender, (result) => {
      if (result.success) {
        this.setState(
          {
            brandOptions: result.brands,
            brand: "DEFAULT",
            modelOptions: result.models,
            model: "DEFAULT",
          },
          callback
        );
      } else {
        this.setState(
          {
            brandOptions: [],
            brand: "DEFAULT",
            modelOptions: [],
            model: "DEFAULT",
          },
          callback
        );
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.dataFilters) !==
        JSON.stringify(this.props.dataFilters) ||
      prevProps.gender !== this.props.gender
    ) {
      if (this.props.gender !== null) {
        this.getBrandModel(() => {
          this.setDefaultFilters();
        }, this.props.gender);
      } else {
        this.setDefaultFilters();
      }
    }
  }

  setDefaultFilters = () => {
    getSearch(this.props.id, (result) => {
      if (result.success) {
        result.filters.isOut =
          this.props.dataIdentification["Compagnie"][0] === "MAIF"
            ? true
            : result.filters.isOut;

        if (
          this.props.gender &&
          result.filters.category !== this.props.gender.toString()
        ) {
          this.getBrandModel(() => {
            this.setState(result.filters, () => {
              if (this.countryMapRef) {
                this.countryMapRef.setState({
                  regions: result.filters.regions,
                });
              }
            });
          }, result.filters.category);
        } else {
          this.setState(result.filters, () => {
            if (this.countryMapRef) {
              this.countryMapRef.setState({
                regions: result.filters.regions,
              });
            }
          });
        }
      } else {
        let model = "DEFAULT";
        if (
          this.props.dataFilters.brand &&
          this.state.modelOptions &&
          this.state.modelOptions.hasOwnProperty(
            this.props.dataFilters.brand.toUpperCase()
          )
        ) {
          this.state.modelOptions[
            this.props.dataFilters.brand.toUpperCase()
          ].map((modelOption) => {
            if (
              modelOption.text &&
              (this.props.dataFilters.model.includes(
                modelOption.text.toUpperCase()
              ) ||
                this.props.dataFilters.model === modelOption.text.toUpperCase())
            ) {
              model = modelOption.value;
            }

            return null;
          });
        }

        if (this.props.gender !== null && [3, 4].includes(this.props.gender)) {
          this.props.dataFilters.search = "";
          if (this.props.dataFilters.brand) {
            this.props.dataFilters.search += this.props.dataFilters.brand;
          }
          if (this.props.dataFilters.model) {
            this.props.dataFilters.search += " " + this.props.dataFilters.model;
          }
        }

        this.setState(
          {
            category:
              this.props.gender !== null
                ? this.props.gender.toString()
                : "DEFAULT",
            brand: this.props.dataFilters.brand
              ? this.props.dataFilters.brand
              : "DEFAULT",
            model,
            search:
              this.props.dataFilters.search &&
              this.props.dataFilters.search !== "."
                ? this.props.dataFilters.search
                : "",
            fuel: this.props.dataFilters.fuel
              ? this.props.dataFilters.fuel
              : "DEFAULT",
            yearMin: this.props.dataFilters.year
              ? this.props.dataFilters.year
              : "DEFAULT",
            mileageMax: this.props.dataFilters.mileage
              ? this.getFirstValue(
                  this.state.mileageMaxOptions,
                  this.props.dataFilters.mileage,
                  ">"
                )
              : "DEFAULT",
            isOut:
              this.props.dataIdentification["Compagnie"][0] === "MAIF"
                ? true
                : this.state.isOut,
          },
          () => {
            if (this.countryMapRef) {
              this.countryMapRef.setState({
                regions: this.props.dataFilters.region
                  ? [this.props.dataFilters.region]
                  : [],
              });
            }
          }
        );
      }
    });
  };

  getFirstValue = (data, value, type) => {
    let result = "DEFAULT";

    data.map((element) => {
      if (type === ">") {
        if ((result === "DEFAULT" || result < value) && value < element.value) {
          result = element.value;
        }
      } else if (type === "<") {
        if ((result === "DEFAULT" || result < value) && value > element.value) {
          result = element.value;
        }
      }
      return null;
    });

    return result;
  };

  disableOptions = (data, value, type) => {
    data.map((element, key) => {
      if (type === "<") {
        if (element.value < value && value !== "DEFAULT") {
          data[key].disabled = true;
        } else {
          data[key].disabled = false;
        }
      } else if (type === ">") {
        if (element.value > value && value !== "DEFAULT") {
          data[key].disabled = true;
        } else {
          data[key].disabled = false;
        }
      }
      return null;
    });

    return data;
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };

  componentDidMount() {
    const mileageOptions = [
      { value: "DEFAULT", text: "Tous les kilométrages" },
    ].concat(getMileageOptions());
    const yearOptions = [
      { value: "DEFAULT", text: "Toutes les années" },
    ].concat(getYearOptions());
    const priceOptions = [{ value: "DEFAULT", text: "Tous les prix" }].concat(
      getPriceOptions()
    );

    this.setState({
      mileageMinOptions: JSON.parse(JSON.stringify(mileageOptions)),
      mileageMaxOptions: JSON.parse(JSON.stringify(mileageOptions)),
      yearMinOptions: JSON.parse(JSON.stringify(yearOptions)),
      yearMaxOptions: JSON.parse(JSON.stringify(yearOptions)),
      priceMinOptions: JSON.parse(JSON.stringify(priceOptions)),
      priceMaxOptions: JSON.parse(JSON.stringify(priceOptions)),
    });
  }

  checkIfValueExist = (data, value) => {
    let result = false;

    if (data) {
      data.map((element) => {
        if (value === element.value) {
          result = true;
        }
        return null;
      });
    }

    return result;
  };

  getFilters = () => {
    return {
      category: this.state.category,
      sources: this.state.sources,
      countries: this.state.countries,
      brand: this.checkIfValueExist(this.state.brandOptions, this.state.brand)
        ? this.state.brand
        : "DEFAULT",
      model:
        this.state.brand !== "DEFAULT" &&
        this.checkIfValueExist(
          this.state.modelOptions[this.state.brand],
          this.state.model
        )
          ? this.state.model
          : "DEFAULT",
      type: this.state.type,
      door: this.state.door,
      seat: this.state.seat,
      cycleType: this.state.cycleType,
      license: this.state.license,
      search: this.state.search,
      fuel: this.state.fuel,
      gearbox: this.state.gearbox,
      yearMin: this.state.yearMin,
      yearMax: this.state.yearMax,
      mileageMin: this.state.mileageMin,
      mileageMax: this.state.mileageMax,
      priceMin: this.state.priceMin,
      priceMax: this.state.priceMax,
      dateMin: this.state.dateMin,
      dateMax: this.state.dateMax,
      cylinderMin: this.state.cylinderMin,
      cylinderMax: this.state.cylinderMax,
      regions: this.countryMapRef.state.regions,
      isAllRegion: this.state.isAllRegion,
      isAllStatus: this.state.isAllStatus,
      isIsolateWord: this.state.isIsolateWord,
      isOut: this.state.isOut,
      isTitle: this.state.isTitle,
    };
  };

  updateRegions = (name) => {
    let tmpRegions = this.countryMapRef.state.regions;

    if (tmpRegions.includes(name)) {
      tmpRegions.splice(tmpRegions.indexOf(name), 1);
    } else {
      tmpRegions.push(name);
    }

    this.countryMapRef.setState(
      {
        regions: tmpRegions,
      },
      () => this.setState({})
    );
  };

  render() {
    if (this.state.isHide) {
      return (
        <Button
          className="mt-10 mt-20-xl w-auto"
          text="Afficher les filtres"
          onClick={() => this.setState({ isHide: false })}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      );
    }

    return (
      <Card className="mt-10 mt-20-xl overflow-hidden">
        <Row className="h-100">
          <Col xs={12} lg={3} style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: -20,
                left: -20,
                right: -20,
                bottom: -20,
                backgroundColor: "var(--thirty-color)",
              }}
            />
            <div
              className="d-flex flex-column h-100"
              style={{ position: "relative" }}
            >
              <CountryMap
                ref={(ref) => (this.countryMapRef = ref)}
                isAll={this.state.isAllRegion}
              />
              <FormCheckbox
                className="mt-auto"
                text="Guadeloupe"
                checked={
                  this.state.isAllRegion ||
                  (this.countryMapRef &&
                    this.countryMapRef.state.regions.includes("guadeloupe"))
                }
                onChange={() => this.updateRegions("guadeloupe")}
              />
              <FormCheckbox
                className="mt-auto"
                text="Martinique"
                checked={
                  this.state.isAllRegion ||
                  (this.countryMapRef &&
                    this.countryMapRef.state.regions.includes("martinique"))
                }
                onChange={() => this.updateRegions("martinique")}
              />
              <FormCheckbox
                className="mt-auto"
                text="Guyane"
                checked={
                  this.state.isAllRegion ||
                  (this.countryMapRef &&
                    this.countryMapRef.state.regions.includes("guyane"))
                }
                onChange={() => this.updateRegions("guyane")}
              />
              <FormCheckbox
                className="mt-auto"
                text="La réunion"
                checked={
                  this.state.isAllRegion ||
                  (this.countryMapRef &&
                    this.countryMapRef.state.regions.includes("la-reunion"))
                }
                onChange={() => this.updateRegions("la-reunion")}
              />
              <FormCheckbox
                name="isAllRegion"
                className="mt-auto"
                text="Toutes les régions"
                checked={this.state.isAllRegion}
                onChange={this.handleCheckboxChange}
              />
            </div>
          </Col>
          <Col xs={12} lg={9} className="pl-30 d-flex flex-column">
            <Row>
              <Col xs={12} md={6} lg={3} className="mb-10">
                <FormSelect
                  ignore={true}
                  onChange={this.handleInputChange}
                  value={this.state.category}
                  options={this.state.categoryOptions}
                  title="Catégorie"
                  name="category"
                  init="Veuillez sélectionner une catégorie"
                  required={true}
                  classNameTitle={
                    this.state.category !== "DEFAULT" ? "green-important" : ""
                  }
                />
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-10">
                <FormSelectMultiples
                  title="Sources"
                  default="Toutes"
                  options={this.state.sourceOptions}
                  value={this.state.sources}
                  onChange={(value) => this.setState({ sources: value })}
                />
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-10">
                <FormSelectMultiples
                  default="Tous"
                  title="Pays"
                  options={[
                    { value: "DE", text: "Allemagne" },
                    { value: "BE", text: "Belgique" },
                    { value: "FR", text: "France" },
                    { value: "LU", text: "Luxembourg" },
                    { value: "NL", text: "Pays-Bas" },
                    { value: "IT", text: "Italie" },
                  ]}
                  value={this.state.countries}
                  onChange={(value) => this.setState({ countries: value })}
                />
              </Col>
              {["0", "1"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormSelect
                    ignore={true}
                    onChange={this.handleInputChange}
                    value={this.state.brand}
                    options={this.state.brandOptions}
                    title="Marque"
                    name="brand"
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.brand !== "DEFAULT" ? "green-important" : ""
                    }
                  />
                </Col>
              ) : null}
              {["0", "1"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormSelect
                    ignore={true}
                    onChange={this.handleInputChange}
                    value={this.state.model}
                    options={
                      this.state.brand !== "DEFAULT" && this.state.modelOptions
                        ? this.state.modelOptions[this.state.brand]
                        : null
                    }
                    title="Modèle"
                    name="model"
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.model !== "DEFAULT" ? "green-important" : ""
                    }
                  />
                </Col>
              ) : null}
              {["0"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormSelect
                    ignore={true}
                    onChange={this.handleInputChange}
                    value={this.state.type}
                    options={[
                      { text: "Aucun type", value: "DEFAULT" },
                      { text: "4x4", value: "4x4" },
                      { text: "autre", value: "autre" },
                      { text: "berline", value: "berline" },
                      { text: "break", value: "break" },
                      { text: "cabriolet", value: "cabriolet" },
                      { text: "citadine", value: "citadine" },
                      { text: "coupe", value: "coupe" },
                      { text: "minibus", value: "minibus" },
                      { text: "monospace", value: "monospace" },
                      { text: "pickup", value: "pickup" },
                      {
                        text: "voiture sans permis",
                        value: "voiture sans permis",
                      },
                      { text: "voituresociete", value: "voituresociete" },
                    ]}
                    title="Type"
                    name="type"
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.type !== "DEFAULT" ? "green-important" : ""
                    }
                  />
                </Col>
              ) : null}
              {["1"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormSelect
                    ignore={true}
                    onChange={this.handleInputChange}
                    value={this.state.type}
                    options={[
                      { text: "Aucun type", value: "DEFAULT" },
                      { text: "moto", value: "moto" },
                      { text: "scooter", value: "scooter" },
                      { text: "quad", value: "quad" },
                      { text: "autre", value: "autre" },
                    ]}
                    title="Type"
                    name="type"
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.type !== "DEFAULT" ? "green-important" : ""
                    }
                  />
                </Col>
              ) : null}
              {["1"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormSelect
                    ignore={true}
                    onChange={this.handleInputChange}
                    value={this.state.cycleType}
                    options={[
                      { text: "Aucun type", value: "DEFAULT" },
                      { text: "moto", value: "moto" },
                      { text: "scooter", value: "scooter" },
                      { text: "quad", value: "quad" },
                      { text: "autre", value: "autre" },
                    ]}
                    title="Type"
                    name="cycleType"
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.cycleType !== "DEFAULT"
                        ? "green-important"
                        : ""
                    }
                  />
                </Col>
              ) : null}
              {["1"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormSelect
                    ignore={true}
                    onChange={this.handleInputChange}
                    value={this.state.license}
                    options={[
                      { text: "Aucun permis", value: "DEFAULT" },
                      { text: "permis A", value: "permisa" },
                      { text: "permis AM", value: "permisam" },
                      { text: "sans permis", value: "sanspermis" },
                    ]}
                    title="Type de permis"
                    name="license"
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.license !== "DEFAULT" ? "green-important" : ""
                    }
                  />
                </Col>
              ) : null}
              {["0", "2"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormSelect
                    ignore={true}
                    onChange={this.handleInputChange}
                    value={this.state.door}
                    options={[
                      { text: "Non défini", value: "DEFAULT" },
                      { text: "2", value: "2" },
                      { text: "3", value: "3" },
                      { text: "4", value: "4" },
                      { text: "5", value: "5" },
                      { text: "6", value: "6" },
                      { text: "7", value: "7" },
                      { text: "8", value: "8" },
                    ]}
                    title="Nombre de portes"
                    name="door"
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.door !== "DEFAULT" ? "green-important" : ""
                    }
                  />
                </Col>
              ) : null}
              {["0", "2"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormSelect
                    ignore={true}
                    onChange={this.handleInputChange}
                    value={this.state.seat}
                    options={[
                      { text: "Non défini", value: "DEFAULT" },
                      { text: "2", value: "2" },
                      { text: "3", value: "3" },
                      { text: "4", value: "4" },
                      { text: "5", value: "5" },
                      { text: "6", value: "6" },
                      { text: "7", value: "7" },
                      { text: "8", value: "8" },
                    ]}
                    title="Nombre de sièges"
                    name="seat"
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.seat !== "DEFAULT" ? "green-important" : ""
                    }
                  />
                </Col>
              ) : null}
              <Col xs={12} md={6} lg={3} className="mb-10">
                <FormInput
                  onChange={this.handleInputChange}
                  value={this.state.search}
                  title={
                    ["3", "4"].includes(this.state.category)
                      ? "Marque / Modèle"
                      : "Motorisation / Finition"
                  }
                  name="search"
                  disabled={this.state.category === "DEFAULT"}
                  classNameTitle={this.state.search ? "green-important" : ""}
                />
              </Col>
              {["0"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormSelect
                    ignore={true}
                    onChange={this.handleInputChange}
                    value={this.state.fuel}
                    options={this.state.fuelOptions}
                    title="Carburant"
                    name="fuel"
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.fuel !== "DEFAULT" ? "green-important" : ""
                    }
                  />
                </Col>
              ) : null}
              {["0", "2"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormSelect
                    ignore={true}
                    onChange={this.handleInputChange}
                    value={this.state.gearbox}
                    options={this.state.gearboxOptions}
                    title="Boîte de vitesses"
                    name="gearbox"
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.gearbox !== "DEFAULT" ? "green-important" : ""
                    }
                  />
                </Col>
              ) : null}
              <Col xs={12} md={6} lg={3} className="mb-10">
                <FormRange
                  title="Année"
                  name1="yearMin"
                  name2="yearMax"
                  value1={this.state.yearMin}
                  value2={this.state.yearMax}
                  options1={this.state.yearMinOptions}
                  options2={this.state.yearMaxOptions}
                  onChange1={this.handleInputChange}
                  onChange2={this.handleInputChange}
                  disabled={this.state.category === "DEFAULT"}
                  classNameTitle={
                    this.state.yearMin !== "DEFAULT" ||
                    this.state.yearMax !== "DEFAULT"
                      ? "green-important"
                      : ""
                  }
                />
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-10">
                <FormRange
                  title="Kilométrage"
                  name1="mileageMin"
                  name2="mileageMax"
                  value1={this.state.mileageMin}
                  value2={this.state.mileageMax}
                  options1={this.state.mileageMinOptions}
                  options2={this.state.mileageMaxOptions}
                  onChange1={this.handleInputChange}
                  onChange2={this.handleInputChange}
                  disabled={this.state.category === "DEFAULT"}
                  classNameTitle={
                    this.state.mileageMin !== "DEFAULT" ||
                    this.state.mileageMax !== "DEFAULT"
                      ? "green-important"
                      : ""
                  }
                />
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-10">
                <FormRange
                  title="Prix"
                  name1="priceMin"
                  name2="priceMax"
                  value1={this.state.priceMin}
                  value2={this.state.priceMax}
                  options1={this.state.priceMinOptions}
                  options2={this.state.priceMaxOptions}
                  onChange1={this.handleInputChange}
                  onChange2={this.handleInputChange}
                  disabled={this.state.category === "DEFAULT"}
                  classNameTitle={
                    this.state.priceMin !== "DEFAULT" ||
                    this.state.priceMax !== "DEFAULT"
                      ? "green-important"
                      : ""
                  }
                />
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-10">
                <FormRange
                  input
                  type="date"
                  title="Date de publication"
                  name1="dateMin"
                  name2="dateMax"
                  value1={this.state.dateMin}
                  value2={this.state.dateMax}
                  onChange1={this.handleInputChange}
                  onChange2={this.handleInputChange}
                  disabled={this.state.category === "DEFAULT"}
                  classNameTitle={
                    this.state.dateMin !== "" || this.state.dateMax !== ""
                      ? "green-important"
                      : ""
                  }
                />
              </Col>
              {["1"].includes(this.state.category) ? (
                <Col xs={12} md={6} lg={3} className="mb-10">
                  <FormRange
                    input
                    type="number"
                    title="Cylindrée"
                    name1="cylinderMin"
                    name2="cylinderMax"
                    value1={this.state.cylinderMin}
                    value2={this.state.cylinderMax}
                    onChange1={this.handleInputChange}
                    onChange2={this.handleInputChange}
                    disabled={this.state.category === "DEFAULT"}
                    classNameTitle={
                      this.state.cylinderMin !== "" ||
                      this.state.cylinderMax !== ""
                        ? "green-important"
                        : ""
                    }
                  />
                </Col>
              ) : null}
            </Row>
            <Row className="mt-10">
              <Col xs={12}>
                <h3 className="mb-0 mt-0">Autres filtres</h3>
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-10 d-flex">
                <FormCheckbox
                  className="mt-auto mb-auto"
                  name="isAllStatus"
                  text="Afficher les annonces désactivées"
                  checked={this.state.isAllStatus}
                  onChange={this.handleCheckboxChange}
                />
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-10 d-flex">
                <FormCheckbox
                  className="mt-auto mb-auto"
                  name="isIsolateWord"
                  text="Isoler les mots"
                  checked={this.state.isIsolateWord}
                  onChange={this.handleCheckboxChange}
                />
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-10 d-flex">
                <FormCheckbox
                  name="isOut"
                  className="mt-auto mb-auto"
                  text="Exclure les annonces avec : Fuite, panne, moteur bloqué"
                  checked={this.state.isOut}
                  onChange={this.handleCheckboxChange}
                />
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-10 d-flex">
                <FormCheckbox
                  name="isTitle"
                  className="mt-auto mb-auto"
                  text="Rechercher dans le titre uniquement"
                  checked={this.state.isTitle}
                  onChange={this.handleCheckboxChange}
                />
              </Col>
            </Row>
            <div className="d-flex mt-auto ml-auto">
              <Button
                className="w-auto mr-10"
                text="Masquer les filtres"
                onClick={() => this.setState({ isHide: true })}
              >
                <FontAwesomeIcon icon={faEyeSlash} />
              </Button>
              <Button
                className="w-auto"
                text="Rechercher"
                disabled={
                  this.state.category === "DEFAULT" || !this.props.isSearch
                }
                onClick={this.props.onClickSearch}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}
