import React from "react";
import {
  Card,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  Status,
  ButtonIcon,
  Pagination,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCopy,
  faEye,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default class Folders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageLength: 10,
    };
  }

  renderLabelSwitch(param) {
    switch (param) {
      case 0:
        return "À faire";
      case 1:
        return "En cours";
      case 2:
        return "Finalisé";
      default:
        return "Nouveau dossier";
    }
  }

  renderClassSwitch(param) {
    switch (param) {
      case 0:
        return "bg-red w-100";
      case 1:
        return "bg-orange w-100";
      case 2:
        return "bg-green w-100";
      default:
        return "w-100";
    }
  }

  onOpenFolderClick(e, id, reference) {
    if (
      !e.target.matches(".not-click") &&
      !e.target.parentNode.matches(".not-click") &&
      !e.target.parentNode.parentNode.matches(".not-click")
    ) {
      this.props.onOpenFolderClick(id, reference);
    }
  }

  render() {
    return (
      <Card
        title={this.props.title}
        className={this.props.isFlex ? "" : "mb-10 mb-20-xl"}
        classNameChildren="overflow-x-auto"
      >
        <Table
          empty={
            !this.props.data || !this.props.data.length ? "Aucun dossier" : null
          }
        >
          <Thead>
            <Tr>
              <Th>N° dossier</Th>
              {this.props.isFlex ? <Th>Date d'étude</Th> : null}
              <Th>Immat.</Th>
              <Th>Marque</Th>
              <Th>Modèle</Th>
              {this.props.isFlex ? <Th>État descriptif</Th> : null}
              {this.props.isFlex ? <Th>Valeur de marché</Th> : null}
              {this.props.isFlex ? <Th>Actions</Th> : null}
            </Tr>
          </Thead>
          <Tbody>
            {this.props.data
              .filter(
                (element) =>
                  (!this.props.search ||
                    element.reference.includes(this.props.search) ||
                    (element.license_plate &&
                      element.license_plate
                        .replace(/-/g, "")
                        .includes(this.props.search.replace(/-/g, "")))) &&
                  (!this.props.descriptiveStateStatus ||
                    this.props.descriptiveStateStatus === "DEFAULT" ||
                    this.props.descriptiveStateStatus
                      .split(",")
                      .includes(
                        (element.descriptive_state_status
                          ? element.descriptive_state_status
                          : ""
                        ).toString()
                      )) &&
                  (!this.props.marketSearchStatus ||
                    this.props.marketSearchStatus === "DEFAULT" ||
                    this.props.marketSearchStatus
                      .split(",")
                      .includes(
                        (element.market_search_status
                          ? element.market_search_status
                          : ""
                        ).toString()
                      ))
              )
              .slice(
                (this.state.activePage - 1) * this.state.pageLength,
                this.state.activePage * this.state.pageLength
              )
              .map((element, idx) => (
                <Tr
                  className="cursor-pointer"
                  key={idx}
                  onClick={(e) =>
                    this.onOpenFolderClick(e, element.id, element.reference)
                  }
                >
                  <Th
                    className={`nowrap ${
                      element.date && !this.props.isFlex ? "bg-green-light" : ""
                    }`}
                  >
                    {element.reference}
                  </Th>
                  {this.props.isFlex ? (
                    <Td className="nowrap">{element.date}</Td>
                  ) : null}
                  <Td
                    className={`nowrap ${
                      element.date && !this.props.isFlex ? "bg-green-light" : ""
                    }`}
                  >
                    {element.license_plate}
                  </Td>
                  <Td
                    className={`nowrap ${
                      element.date && !this.props.isFlex ? "bg-green-light" : ""
                    }`}
                  >
                    {element.brand}
                  </Td>
                  <Td
                    className={`nowrap ${
                      element.date && !this.props.isFlex ? "bg-green-light" : ""
                    }`}
                  >
                    {element.model}
                  </Td>
                  {this.props.isFlex ? (
                    <Td className="nowrap">
                      <Status
                        className={this.renderClassSwitch(
                          element.descriptive_state_status
                        )}
                        text={this.renderLabelSwitch(
                          element.descriptive_state_status
                        )}
                      />
                    </Td>
                  ) : null}
                  {this.props.isFlex ? (
                    <Td className="nowrap">
                      <Status
                        className={this.renderClassSwitch(
                          element.market_search_status
                        )}
                        text={this.renderLabelSwitch(
                          element.market_search_status
                        )}
                      />
                    </Td>
                  ) : null}
                  {this.props.isFlex ? (
                    <Td className="nowrap">
                      <ButtonIcon
                        className="red not-click"
                        small
                        onClick={() => this.props.onDeleteFolder(idx)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                      <ButtonIcon
                        className="yellow not-click"
                        small
                        onClick={() =>
                          this.props.onCopyFolder(element.reference)
                        }
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </ButtonIcon>
                      {element.descriptive_state_status === 2 &&
                      element.descriptiveToken ? (
                        <ButtonIcon
                          className="blue not-click"
                          info="imprimer descriptif"
                          small
                          onClick={() =>
                            this.props.onPreviewPDF(
                              element.descriptiveToken,
                              "descriptif",
                              element.id
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </ButtonIcon>
                      ) : null}
                      {element.market_search_status === 2 &&
                      element.marketSearchToken ? (
                        <ButtonIcon
                          className="blue not-click"
                          info="imprimer étude du marché"
                          small
                          onClick={() =>
                            this.props.onPreviewPDF(
                              element.marketSearchToken,
                              "étude-du-marché",
                              element.id
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </ButtonIcon>
                      ) : null}
                    </Td>
                  ) : null}
                </Tr>
              ))}
          </Tbody>
        </Table>{" "}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.pageLength}
          totalItemsCount={this.props.data.length}
          pageRangeDisplayed={4}
          onChange={(activePage) => {
            this.setState({ activePage: activePage });
          }}
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
          nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
        />
      </Card>
    );
  }
}
