import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import 'react-camera-media-stream/dist/index.css'
import "ui-kit-ck-consultant/dist/index.css";
import "ui-kit-ck-consultant/css/style.css";

console.log(
  `Name : ${process.env.REACT_APP_NAME}\nVersion : ${process.env.REACT_APP_VERSION}\nDate last version : ${process.env.REACT_APP_DATE}`
)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
