import React from "react";
import { Row, Col } from "react-flexbox-grid";

import Equipment from "../../../components/folder/descriptive-state/Equipment";
import Vehicle from "../../../components/folder/descriptive-state/Vehicle";
import Pictures from "../../../components/folder/descriptive-state/Pictures";
import Histovec from "../../../components/folder/descriptive-state/Histovec";
import Electric from "../../../components/folder/descriptive-state/Electric";
import Identification from "../../../components/folder/summary/Identification";
import Ad from "../../../components/folder/summary/Ad";
import Vrade from "../../../components/folder/summary/Vrade";

import { getAdsIds } from "../../../requests/marketSearch";

import html2canvasInstance from "../../../utils/html2canvasInstance";
export default class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      adsSelect: [],
      isPrint: false,
    };

    this.partsRef = {};
    this.adsRef = {};
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.dataSelect) !==
      JSON.stringify(this.props.dataSelect)
    ) {
      this.setState({ isLoad: true }, () => {
        let ids = [];
        if (this.props.dataSelect) {
          ids = Object.keys(this.props.dataSelect).map((key) => {
            if (this.props.dataSelect[key].status) {
              return key;
            } else {
              return "0";
            }
          });
        }

        getAdsIds({ ids }, (result) => {
          if (result.success) {
            this.setState({ isLoad: false, adsSelect: result.data });
          } else {
            this.setState({ isLoad: false, adsSelect: [] });
          }
        });
      });
    }
  }

  handlePrintClick = (callback) => {
    if (this.vehicleRef && this.vehicleRef.vehicleRef) {
      this.setState({ isPrint: true }, () => {
        html2canvasInstance(this.vehicleRef.vehicleRef, (result) => {
          if (result.success) {
            this.setState({ isPrint: false }, () => {
              callback({
                success: true,
                data: [result.base64, this.vradeRef.data],
              });
            });
          } else {
            this.setState({ isPrint: false }, () => {
              callback({
                success: false,
                data: [],
              });
            });
          }
        });
      });
    }
  };

  render() {
    return (
      <>
        <Row style={this.state.isPrint ? { width: "1300px" } : null}>
          <Col xs={12} md={this.state.isPrint ? 12 : 8} className="d-flex">
            <Identification
              dataIdentification={this.props.dataIdentification}
            />
          </Col>
          <Col
            xs={12}
            md={this.state.isPrint ? 12 : 4}
            className="col_full_height"
          >
            <Equipment
              gender={this.props.gender}
              dataEquipment={this.props.dataEquipment}
              isEdit={!this.props.isClosed}
              isPrint={this.state.isPrint}
            />
          </Col>
          <Col xs={12}>
            <Vehicle
              ref={(ref) => {
                this.vehicleRef = ref;
              }}
              gender={this.props.gender}
              dataPart={this.props.dataPart}
              extendDataPart={this.props.extendDataPart}
              dataComment={this.props.dataComment}
              dataIdentification={this.props.dataIdentification}
              dataAdministrative={this.props.dataAdministrative}
              updateFolder={this.props.updateFolder}
              isTechnicalInspectionValid={this.props.isTechnicalInspectionValid}
              isTechnicalInspectionExist={this.props.isTechnicalInspectionExist}
              isSummary={true}
              isEdit={!this.props.isClosed}
              isPrint={this.state.isPrint}
              adsSelect={this.state.adsSelect}
              vradeRef={this.vradeRef}
              tabsId={this.props.tabsId}
            />
          </Col>
          <Col xs={12}>
            <Pictures
              dataIdentification={this.props.dataIdentification}
              gender={this.props.gender}
              dataPart={this.props.dataPart}
              dataPixTransfer={this.props.dataPixTransfer}
              updateFolder={this.props.updateFolder}
              isEdit={!this.props.isClosed}
              isPrint={this.state.isPrint}
              hideImage={this.props.hideImage}
              updateHideImage={this.props.updateHideImage}
              hideImagesId={this.props.hideImagesId}
              updateHideImagesId={this.props.updateHideImagesId}
            />
          </Col>
          <Col xs={12}>
            <Histovec
              dataIdentification={this.props.dataIdentification}
              dataHistovec={this.props.dataHistovec}
              updateFolder={this.props.updateFolder}
            />
          </Col>
          <Col xs={12}>
            <Electric
              fuel={this.props.fuel}
              updateFolder={this.props.updateFolder}
              dataElectric={this.props.dataElectric}
            />
          </Col>
          <Col xs={12}>
            <Vrade
              ref={(ref) => {
                this.vradeRef = ref;
              }}
              gender={this.props.gender}
              vehicleValue={this.vehicleRef ? this.vehicleRef.totalValue : 0}
              date={this.props.date}
              dataSummary={this.props.dataSummary}
              dataIdentification={this.props.dataIdentification}
              dataSelect={this.props.dataSelect}
              adsSelect={this.state.adsSelect}
              updateFolder={this.props.updateFolder}
              isEdit={!this.props.isClosed}
              isPrint={this.state.isPrint}
              checkAd={this.props.checkAd}
            />
          </Col>
          <Col xs={12}>
            {this.props.dataSelect
              ? this.state.adsSelect.map((element, idx) => {
                  if (this.props.dataSelect[element.id].status) {
                    return (
                      <div
                        key={idx}
                        className="w-100"
                        ref={(ref) => {
                          this.partsRef[5 + idx] = ref;
                        }}
                      >
                        <Ad
                          gender={this.props.gender}
                          ref={(ref) => {
                            this.adsRef[5 + idx] = ref;
                          }}
                          ad={element}
                          select={this.props.dataSelect[element.id]}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              : null}
          </Col>
        </Row>
      </>
    );
  }
}
