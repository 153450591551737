import { getTable } from "./valueTable";

const getNegotiationPrice = (
  delayAd,
  averagePrice,
  force,
  negotitationMin,
  negotitationMax,
  isMAIF
) => {
  let result = {
    coefficient: 0,
  };

  if (isMAIF) {
    return {
      ...result,
      price: 0,
    };
  }

  if (delayAd > 5) {
    result.coefficient = parseInt((delayAd - 5) * 0.5 * -1 * 100) / 100;
  }

  if (result.coefficient || force) {
    if (force) {
      result.coefficient = force;
    } else if (
      negotitationMin !== null &&
      result.coefficient < negotitationMin
    ) {
      result.coefficient = negotitationMin;
    } else if (
      negotitationMax !== null &&
      result.coefficient > negotitationMax
    ) {
      result.coefficient = negotitationMax;
    }
    result.price = (result.coefficient / 100) * averagePrice;
  } else {
    result.price = 0;
  }

  return result;
};

const getTimePrice = (ageFolder, ageAd, averagePrice, force) => {
  let result = {};

  let difference = ageAd - ageFolder;
  if (difference) {
    if (ageFolder > 120) {
      let oldValue = 50;
      let totalValue = 0;

      for (
        let index = 0;
        index < (difference < 0 ? difference * -1 : difference);
        index++
      ) {
        oldValue = oldValue - oldValue * 0.01;
        totalValue += oldValue;
      }

      result.coefficient =
        parseInt(((totalValue * 100) / averagePrice) * 100) / 100;
    } else {
      let oldValue = 50;
      let totalValue = 0;
      for (
        let index = 0;
        index < (difference < 0 ? difference * -1 : difference);
        index++
      ) {
        oldValue = oldValue + oldValue * 0.01;
        totalValue += oldValue;
      }

      result.coefficient =
        parseInt(((totalValue * 100) / averagePrice) * 100) / 100;
    }
  }

  if (difference < 0) {
    result.coefficient = result.coefficient * -1;
  }

  if (result.coefficient || force) {
    if (force) {
      result.coefficient = force;
    }
    result.price = (result.coefficient / 100) * averagePrice;
  } else {
    result.price = 0;
  }

  return result;
};

const getMileagePrice = (
  mileageFolder,
  mileageAd,
  ageAd,
  category,
  gender,
  averagePrice,
  force
) => {
  let result = {};

  if (category && getTable(gender).hasOwnProperty(category)) {
    const categoryTable = getTable(gender)[category];

    let value = categoryTable[categoryTable.length - 1];
    if (categoryTable.hasOwnProperty(parseInt(ageAd))) {
      value = categoryTable[parseInt(ageAd)];
    }

    result.coefficient =
      (parseInt(
        (((mileageFolder - mileageAd) * value * 100) / averagePrice) * 100
      ) /
        100) *
      -1;
  }

  if (result.coefficient || force) {
    if (force) {
      result.coefficient = force;
    }
    result.price = (result.coefficient / 100) * averagePrice;
  } else {
    result.price = 0;
  }

  return result;
};

export { getNegotiationPrice, getTimePrice, getMileagePrice };
