import React from "react";
import { Modal } from "ui-kit-ck-consultant";

export default class ModalImage extends React.Component {
  render() {
    return (
      <Modal show={this.props.show} onClose={this.props.onClose}>
        {this.props.src ? (
          <img
            src={this.props.src}
            alt="car"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        ) : null}
      </Modal>
    );
  }
}
