import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Card } from "ui-kit-ck-consultant";
import { getFuel, getFuelString } from "../../../utils/general";

export default class Identification extends React.Component {
  getLine = (title, value, extension) => {
    return (
      <div className="w-100 mb-5">
        <span>{title} : </span>
        <span>
          <b>
            {value} {extension}
          </b>
        </span>
      </div>
    );
  };

  getValue = (name) => {
    if (this.props.dataIdentification[name]) {
      return this.props.dataIdentification[name][0];
    } else {
      return null;
    }
  };

  render() {
    if (!this.props.dataIdentification) {
      return null;
    }

    return (
      <Card
        title="Identification du véhicule"
        className="mt-10 mt-20-xl d-flex flex-column w-100"
        classNameChildren="d-flex flex-column h-100"
      >
        <Row>
          <Col xs={12} lg={4}>
            {this.getLine("Marque", this.getValue("Constructeur"))}
            {this.getLine("Modèle", this.getValue("Modele"))}
            {this.getLine("Immatriculation", this.getValue("Immat"))}
            {this.getLine(
              "Date mise en circulation",
              this.getValue("Date1MEC")
            )}
          </Col>
          <Col xs={12} lg={4}>
            {this.getLine("Genre de véhicule", this.getValue("Genre"))}
            {this.getLine(
              "Puissance fiscale",
              this.getValue("Puissance_Fiscale"),
              "cv"
            )}
            {this.getLine("Type Mine", this.getValue("Type_Mine"))}
            {this.getLine("Date sinistre", this.getValue("Date_Sinistre"))}
          </Col>
          <Col xs={12} lg={4}>
            {this.getLine("N° Série", this.getValue("VIN"))}
            {this.getLine(
              "Énergie",
              getFuelString(getFuel(this.getValue("Energie")))
            )}
            {this.getLine("Kilométrage", this.getValue("Kms"), "km")}
            {this.getLine(
              "Date de contrôle technique",
              this.getValue("DateDernierCT")
            )}
          </Col>
        </Row>
      </Card>
    );
  }
}
