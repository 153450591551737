import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes";

import AuthContext from "./context/AuthContext";

import { refreshToken } from "./requests/general";

import ReactGA from "react-ga4";
ReactGA.initialize("G-KY63MFTXSC");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      connected: null,
      negotitationMin: null,
      negotitationMax: null,
      value: null,
      global: null,
      type1: null,
      type2: null,
      signature: null,
      url: null,
    };
  }

  componentDidMount() {
    this.checkAuth();
    this.intervalToken = setInterval(() => {
      this.checkAuth();
    }, 30 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalToken);
  }

  checkAuth = () => {
    const pathArray = document.location.href.split("/");
    let token = null;
    if (pathArray.includes("success")) {
      token = pathArray[pathArray.length - 1];
    }

    var url = window.location.href;
    var arr = url.split("/");

    refreshToken(token, arr[0] + "//" + arr[2], (result) => {
      if (result.success) {
        if (result.redirect) {
          document.location.href = result.redirect;
        } else {
          if (pathArray.includes("success")) {
            document.location.href = "/";
          } else {
            const oldState = this.state;

            const newState = {
              isReady: true,
              connected: true,
              negotitationMin: result.data.negotitationMin,
              negotitationMax: result.data.negotitationMax,
              value: result.data.value,
              global: result.data.global,
              type1: result.data.type1,
              type2: result.data.type2,
              signature: result.data.signature,
              url: result.url,
            };
            window.localStorage.setItem("token", result.token);
            if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
              this.setState(newState);
            }
          }
        }
      } else {
        this.setState({ connected: false }, () => {
          setTimeout(() => {
            if (result.redirect) {
              document.location.href = result.redirect;
            }
          }, 1000);
        });
      }
    });
  };

  render() {
    if (!this.state.isReady) {
      return null;
    }

    return (
      <AuthContext.Provider
        value={{
          negotitationMin: this.state.negotitationMin,
          negotitationMax: this.state.negotitationMax,
          value: this.state.value,
          global: this.state.global,
          type1: this.state.type1,
          type2: this.state.type2,
          signature: this.state.signature,
          url: this.state.url,
          domain:
            process.env.NODE_ENV === "production"
              ? "https://evalcar.fr"
              : "http://localhost:5001",
        }}
      >
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  }}
                />
              );
            })}
          </Switch>
        </Router>
      </AuthContext.Provider>
    );
  }
}
