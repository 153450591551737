import React from "react";
import { FullModal } from "ui-kit-ck-consultant";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { getFileUrl } from "../../utils/general";
import AuthContext from "../../context/AuthContext";

export default class ModalPDF extends React.Component {
  static contextType = AuthContext;
  downloadPDF = async (filename) => {
    if (!this.props.base64) {
      const response = await fetch(
        getFileUrl(
          this.context.domain,
          this.props.folderId,
          this.props.prewiewToken
        )
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      let link = document.createElement("a");
      link.href = getFileUrl(
        this.context.domain,
        this.props.folderId,
        this.props.prewiewToken
      );
      link.setAttribute("download", filename);
      link.target = "_blank";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } else {
      const link = document.createElement("a");
      link.href = this.props.base64;
      link.target = "_blank";
      link.download = this.props.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  b64toBlob = (content, contentType = "application/pdf") => {
    contentType = contentType || "";
    const sliceSize = 512;
    // method which converts base64 to binary
    const byteCharacters = window.atob(content);

    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType,
      name: this.props.name,
    }); // statement which creates the blob
    return blob;
  };

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <FullModal
        title={`PDF - ${this.props.name}`}
        show={this.props.show}
        onClose={this.props.onClose}
        onCustom={this.downloadPDF}
        customIcon={faDownload}
      >
        {!this.props.base64 ? (
          <div style={{ height: "100%" }}>
            <embed
              title={this.props.name}
              src={getFileUrl(
                this.context.domain,
                this.props.folderId,
                this.props.prewiewToken
              )}
              type="application/pdf"
              width="100%"
              style={{ height: "100%" }}
            />
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            <embed
              title={this.props.name}
              src={`${URL.createObjectURL(
                this.b64toBlob(this.props.base64.split(",").pop())
              )}#toolbar=0`}
              type="application/pdf"
              width="100%"
              style={{ height: "100%" }}
            />
          </div>
        )}
      </FullModal>
    );
  }
}
