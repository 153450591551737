import React from "react";
import { Modal, FormInput, Button, Loader } from "ui-kit-ck-consultant";

import { addFolder, copyFolder } from "../../requests/home";

export default class ModalAddFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      reference: "",
      isReference: null,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.reference !== this.props.reference) {
      this.setState({ reference: this.props.reference });
    }
  }

  onAddFolder = (isForce) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        if (!this.props.isFolderCopy) {
          addFolder(this.state.reference, isForce, (result) => {
            if (result.success) {
              if (result.isExist) {
                alert("Le dossier est déjà créé");
              }
              this.setState(
                { reference: "", isReference: null, isLoad: false },
                () => this.props.onOpenFolderClick(result.id)
              );
            } else {
              this.setState({ isReference: false, isLoad: false });
            }
          });
        } else {
          copyFolder(
            this.props.currentReference,
            this.state.reference,
            (result) => {
              if (result.success) {
                if (result.isExist) {
                  alert("Le dossier est déjà créé");
                }
                this.setState(
                  { reference: "", isReference: null, isLoad: false },
                  () => this.props.onOpenFolderClick(result.id)
                );
              } else {
                this.setState({ isReference: false, isLoad: false });
              }
            }
          );
        }
      }
    );
  };

  render() {
    return (
      <>
        <Modal
          title={
            this.props.isFolderCopy
              ? "Copie du dossier"
              : "Création d’un nouveau dossier"
          }
          show={this.props.show}
          onClose={this.props.onClose}
        >
          <FormInput
            className="mb-20"
            type="text"
            name="reference"
            title="N° de dossier / Immatriculation"
            value={this.state.reference}
            onChange={this.handleInputChange}
            error={
              this.state.isReference === false ? (
                <span>Aucun dossier trouvé, vérifiez le numéro</span>
              ) : (
                ""
              )
            }
          />
          <Button text="Valider" onClick={() => this.onAddFolder(false)} />
          {!this.props.isFolderCopy && (
            <>
              <span className="mt-20 mb-20 text-center">OU</span>
              <p className="mt-0 mb-5 text-center">
                Vous n'avez pas de numéro de dossier ?
              </p>
              <Button
                outline
                text="Créer un nouveau dossier"
                onClick={() => this.onAddFolder(true)}
              />
            </>
          )}
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
