import React from "react";

export default class PartsStatusCustom extends React.Component {
  render() {
    return (
      <div className="parts-status">
        <input
          onChange={this.props.onChange}
          value={this.props.value}
          placeholder="État de la pièce"
        />
      </div>
    );
  }
}
