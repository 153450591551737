/* 0 -> car | 1 -> bike | 2 -> commercial */

var equipments = [
  {
    "EQ-1": { name: "Climatisation manuelle" },
    "EQ-2": { name: "Climatisation automatique" },
    "EQ-3": { name: "Boîte vitesse automatique" },
    "EQ-4": { name: "Capteurs stationnement" },
    "EQ-5": { name: "Autoradio" },
    "EQ-6": { name: "Jante alliage" },
    "EQ-7": { name: "Régulateur limitateur de vitesse" },
    "EQ-8": { name: "GPS" },
    "EQ-9": { name: "Toit ouvrant" },
    "EQ-10": { name: "4 roues motrices" },
    "EQ-11": { name: "Porte latérale coulissante" },
    "EQ-12": { name: "ADAS" },
    "EQ-13": { name: "Capteur pluie" },
    "EQ-14": { name: "Caméra de recul" },
    "EQ-15": { name: "Kit main libre" },
    "EQ-16": { name: "Attelage" },
    "EQ-17": { name: "Coffre de toit" },
    "EQ-18": { name: "Échelle" },
    "EQ-19": { name: "Sellerie semi-cuir" },
    "EQ-20": { name: "Toit panoramique" },
    "EQ-21": { name: "Kit carrosserie" },
    "EQ-22": { name: "Galerie" },
    "EQ-23": { name: "Intérieur aménagé" },
    "EQ-25": { name: "Peinture métal" },
    "EQ-26": { name: "Peinture nacré" },
    "EQ-27": { name: "Peinture mat" },
    "EQ-28": { name: "Peinture texturée" },
    "EQ-29": { name: "Sellerie cuir" },
  },
  {
    "EQ-1": { name: "Selle confort" },
    "EQ-2": { name: "Ligne échappement" },
    "EQ-3": { name: "Silencieux échappement" },
    "EQ-4": { name: "Valises" },
    "EQ-5": { name: "Pare-cylindres" },
    "EQ-6": { name: "Top bloc" },
    "EQ-7": { name: "GPS" },
    "EQ-8": { name: "Pare-carter" },
    "EQ-9": { name: "Rétroviseur" },
    "EQ-10": { name: "Levier" },
    "EQ-11": { name: "Embout guidon" },
    "EQ-12": { name: "Tablier" },
    "EQ-13": { name: "Bulle" },
    "EQ-14": { name: "ABS" },
    "EQ-15": { name: "Top-case" },
    "EQ-16": { name: "Peinture métal" },
    "EQ-17": { name: "Peinture nacré" },
    "EQ-18": { name: "Peinture mat" },
    "EQ-19": { name: "Peinture texturée" },
    "EQ-20": { name: "Sellerie cuir" },
  },
  {
    "EQ-1": { name: "Climatisation manuelle" },
    "EQ-2": { name: "Climatisation automatique" },
    "EQ-3": { name: "Boîte vitesse automatique" },
    "EQ-4": { name: "Capteurs stationnement" },
    "EQ-5": { name: "Autoradio" },
    "EQ-6": { name: "Jante alliage" },
    "EQ-7": { name: "Régulateur limitateur de vitesse" },
    "EQ-8": { name: "GPS" },
    "EQ-9": { name: "Toit ouvrant" },
    "EQ-10": { name: "4 roues motrices" },
    "EQ-11": { name: "Porte latérale coulissante" },
    "EQ-12": { name: "ADAS" },
    "EQ-13": { name: "Capteur pluie" },
    "EQ-14": { name: "Caméra de recul" },
    "EQ-15": { name: "Kit main libre" },
    "EQ-16": { name: "Attelage" },
    "EQ-17": { name: "Coffre de toit" },
    "EQ-18": { name: "Échelle" },
    "EQ-19": { name: "Sellerie semi-cuir" },
    "EQ-20": { name: "Toit panoramique" },
    "EQ-21": { name: "Kit carrosserie" },
    "EQ-22": { name: "Galerie" },
    "EQ-23": { name: "Intérieur aménagé" },
    "EQ-24": { name: "DVD" },
    "EQ-25": { name: "Peinture métal" },
    "EQ-26": { name: "Peinture nacré" },
    "EQ-27": { name: "Peinture mat" },
    "EQ-28": { name: "Peinture texturée" },
    "EQ-29": { name: "Sellerie cuir" },
  },
  {
    "EQ-1": { name: "Climatisation manuelle" },
    "EQ-2": { name: "Climatisation automatique" },
    "EQ-3": { name: "Boîte vitesse automatique" },
    "EQ-4": { name: "Capteurs stationnement" },
    "EQ-5": { name: "Autoradio" },
    "EQ-6": { name: "Jante alliage" },
    "EQ-7": { name: "Régulateur limitateur de vitesse" },
    "EQ-8": { name: "GPS" },
    "EQ-9": { name: "Toit ouvrant" },
    "EQ-10": { name: "4 roues motrices" },
    "EQ-11": { name: "Porte latérale coulissante" },
    "EQ-12": { name: "ADAS" },
    "EQ-13": { name: "Capteur pluie" },
    "EQ-14": { name: "Caméra de recul" },
    "EQ-15": { name: "Kit main libre" },
    "EQ-16": { name: "Attelage" },
    "EQ-17": { name: "Coffre de toit" },
    "EQ-18": { name: "Échelle" },
    "EQ-19": { name: "Sellerie semi-cuir" },
    "EQ-20": { name: "Toit panoramique" },
    "EQ-21": { name: "Kit carrosserie" },
    "EQ-22": { name: "Galerie" },
    "EQ-23": { name: "Intérieur aménagé" },
    "EQ-24": { name: "DVD" },
    "EQ-25": { name: "Peinture métal" },
    "EQ-26": { name: "Peinture nacré" },
    "EQ-27": { name: "Peinture mat" },
    "EQ-28": { name: "Peinture texturée" },
    "EQ-29": { name: "Sellerie cuir" },
  },
  {
    "EQ-1": { name: "Climatisation manuelle" },
    "EQ-2": { name: "Climatisation automatique" },
    "EQ-3": { name: "Boîte vitesse automatique" },
    "EQ-4": { name: "Capteurs stationnement" },
    "EQ-5": { name: "Autoradio" },
    "EQ-6": { name: "Jante alliage" },
    "EQ-7": { name: "Régulateur limitateur de vitesse" },
    "EQ-8": { name: "GPS" },
    "EQ-9": { name: "Toit ouvrant" },
    "EQ-10": { name: "4 roues motrices" },
    "EQ-11": { name: "Porte latérale coulissante" },
    "EQ-12": { name: "ADAS" },
    "EQ-13": { name: "Capteur pluie" },
    "EQ-14": { name: "Caméra de recul" },
    "EQ-15": { name: "Kit main libre" },
    "EQ-16": { name: "Attelage" },
    "EQ-17": { name: "Coffre de toit" },
    "EQ-18": { name: "Échelle" },
    "EQ-19": { name: "Sellerie semi-cuir" },
    "EQ-20": { name: "Toit panoramique" },
    "EQ-21": { name: "Kit carrosserie" },
    "EQ-22": { name: "Galerie" },
    "EQ-23": { name: "Intérieur aménagé" },
    "EQ-24": { name: "DVD" },
    "EQ-25": { name: "Peinture métal" },
    "EQ-26": { name: "Peinture nacré" },
    "EQ-27": { name: "Peinture mat" },
    "EQ-28": { name: "Peinture texturée" },
    "EQ-29": { name: "Sellerie cuir" },
  },
];

export { equipments };
