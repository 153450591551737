import React from "react";
import { Card, ButtonIcon, FormInput } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { equipments } from "../../../utils/equipment";
export default class Equipment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customEquipment: "",
    };
  }

  getEquipmentItem = (key, name) => {
    return (
      <div
        key={key}
        className={`equipment-item ${
          this.props.isEdit && !this.props.isPrint ? "edit-field" : ""
        } ${
          this.props.dataEquipment.hasOwnProperty(key) &&
          this.props.dataEquipment[key].status
            ? "select"
            : ""
        }`}
        onClick={() => {
          if (this.props.isEdit) {
            const tmpDataEquipment = this.props.dataEquipment;

            if (this.props.dataEquipment.hasOwnProperty(key)) {
              if (this.props.dataEquipment[key].status === true) {
                delete tmpDataEquipment[key];
              } else {
                tmpDataEquipment[key].status = true;
              }
            } else {
              tmpDataEquipment[key] = { status: true };
            }

            this.props.updateFolder("dataEquipment", tmpDataEquipment);
          }
        }}
      >
        {name}
      </div>
    );
  };

  onClickAddEquipment = () => {
    if (this.state.customEquipment) {
      const tmpDataEquipment = this.props.dataEquipment;

      tmpDataEquipment[`RD-${Object.keys(tmpDataEquipment).length + 1}`] = {
        status: true,
        name: this.state.customEquipment,
      };

      this.props.updateFolder("dataEquipment", tmpDataEquipment);
    }
  };

  getEquipment = (data, isCustom = false) => {
    if (data && this.props.gender !== null && this.props.gender !== undefined) {
      let result = Object.keys(data).map((key) => {
        if (!isCustom || !equipments[this.props.gender].hasOwnProperty(key)) {
          if (
            (this.props.isEdit && !this.props.isPrint) ||
            (this.props.dataEquipment[key] &&
              this.props.dataEquipment[key].status)
          ) {
            return this.getEquipmentItem(key, data[key].name);
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
      return result;
    } else {
      return null;
    }
  };

  render() {
    return (
      <Card
        title="Équipements et accessoires"
        className="mt-10 mt-20-xl d-flex flex-column w-100 h-100"
        classNameChildren="d-flex flex-column h-100"
      >
        <div className="equipment-container mb-20">
          {this.getEquipment(equipments[this.props.gender])}
          {this.getEquipment(this.props.dataEquipment, true)}
        </div>
        {this.props.isEdit && !this.props.isPrint ? (
          <div
            className="mt-auto p-10 d-flex"
            style={{
              backgroundColor: "var(--thirty-color)",
              margin: "0 -20px -20px -20px",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <FormInput
              className="w-100 bg-white br-15"
              placeholder="Ajouter un accessoire"
              name="customEquipment"
              value={this.state.customEquipment}
              onChange={(e) =>
                this.setState({
                  customEquipment: e.target.value,
                })
              }
            />
            <ButtonIcon
              className="ml-10"
              style={{
                borderRadius: "15px",
                height: "50px",
                width: "50px",
                padding: "10px 14px",
                backgroundColor: "var(--primary-color)",
              }}
              onClick={this.onClickAddEquipment}
            >
              <FontAwesomeIcon icon={faPlus} />
            </ButtonIcon>
          </div>
        ) : null}
      </Card>
    );
  }
}
