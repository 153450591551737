import React from "react";
import { FullModal, Button, FormInput } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import PartsContainer from "./PartsContainer";

import getParts from "../parts/parts";

export default class ModalParts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parts: {},
      data: {},
      extendData: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && this.props.show) {
      this.setState({
        parts: getParts(
          this.props.gender,
          this.props.dataIdentification &&
            this.props.dataIdentification["Genre"]
            ? this.props.dataIdentification["Genre"][0]
            : null,
          this.props.dataIdentification &&
            this.props.dataIdentification["Categorie"]
            ? this.props.dataIdentification["Categorie"][0]
            : null
        ),
      });
    }

    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) ||
      prevProps.show !== this.props.show
    ) {
      let tmpData = JSON.parse(JSON.stringify(this.props.data));
      let tmpExtendData = JSON.parse(JSON.stringify(this.props.extendData));

      Object.keys(tmpData).map((key) => {
        if (
          !tmpData[key].status &&
          !tmpData[key].state &&
          !tmpData[key].value &&
          !tmpData[key].picture
        ) {
          delete tmpData[key];
        }
        return null;
      });

      this.setState({
        data: tmpData,
        extendData: tmpExtendData,
      });
    }
  }

  getParts = (name) => {
    let data = [];

    switch (name) {
      case "body":
        data.push(<h3 key={name}>Carrosserie</h3>);
        break;
      case "mechanics":
        data.push(<h3 key={name}>Mécanique</h3>);
        break;
      case "cockpit":
        data.push(<h3 key={name}>Habitacle</h3>);
        break;
      case "tyre":
        data.push(<h3 key={name}>Pneus</h3>);
        break;
      case "technical_control":
        data.push(<h3 key={name}>Contrôle technique</h3>);
        break;
      default:
        data.push(<h3 key={name}>Autre</h3>);
        break;
    }

    Object.keys(this.state.parts).forEach((key, idx) => {
      if (
        this.state.parts[key].type === name &&
        this.state.parts[key].others.includes(this.props.name)
      ) {
        let currentData = this.state.data.hasOwnProperty(key)
          ? this.state.data[key]
          : null;

        if (this.state.parts.hasOwnProperty(key)) {
          data.push(
            <PartsContainer
              key={`${name}-${key}-${idx}`}
              part={this.state.parts[key]}
              currentData={currentData}
              onChange={(newData) => {
                let tmpData = this.state.data;
                tmpData[key] = newData;
                this.setState({
                  data: tmpData,
                });
              }}
            />
          );
        }
      }
    });

    Object.keys(this.state.data).forEach((key) => {
      if (key.split("-")[0] === name) {
        data.push(this.getCustomParts(key));
      }
    });

    if (data.length > 1) {
      data.push(
        <div key={`custom-${name}`} className="d-flex ml-5 mr-5">
          <FormInput
            type="number"
            placeholder="Moins value globale (€)"
            value={
              this.state.extendData[name] ? this.state.extendData[name] : ""
            }
            onChange={({ target }) => {
              let tmpExtendData = this.state.extendData;
              tmpExtendData[name] = target.value;
              this.setState({
                extendData: tmpExtendData,
              });
            }}
          />

          <Button
            text="Ajouter une pièce"
            className="w-auto ml-auto"
            onClick={() => this.addCustomParts(name)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      );

      return data;
    } else {
      return null;
    }
  };

  addCustomParts = (name) => {
    let tmpData = this.state.data;
    let count = 1;

    Object.keys(tmpData).forEach((key) => {
      if (key.split("-")[0] === name) {
        count++;
      }
    });

    tmpData[`${name}-${count}`] = {
      status: "",
      title: "",
      value: 0,
    };

    this.setState({
      data: tmpData,
    });
  };

  getCustomParts = (key) => {
    let currentData = this.state.data.hasOwnProperty(key)
      ? this.state.data[key]
      : null;

    return (
      <PartsContainer
        key={key}
        part={{}}
        currentData={currentData}
        isCustom={true}
        onChange={(newData) => {
          let tmpData = this.state.data;
          tmpData[key] = newData;
          this.setState({
            data: tmpData,
          });
        }}
      />
    );
  };

  getName = () => {
    switch (this.props.name) {
      case "front":
        return "Face avant";
      case "rear":
        return "Face arrière";
      case "interior":
        return "Intérieur";
      case "roof":
        return "Pavillon";
      case "left_side":
        return "Latéral gauche";
      case "right_side":
        return "Latéral droit";
      case "tyre":
        return "Pneus";
      case "exhaust":
        return "Échappement";
      case "fuel_tank":
        return "Réservoir";
      case "technical_control":
        return "Contrôle technique";
      default:
        return "Autre";
    }
  };

  render() {
    return (
      <FullModal
        show={this.props.show}
        title={this.getName()}
        onClose={this.props.onClose}
        onValid={() =>
          this.props.onValid(this.state.data, this.state.extendData)
        }
      >
        {this.getParts("body")}
        {this.getParts("mechanics")}
        {this.getParts("cockpit")}
        {this.getParts("tyre")}
        {this.getParts("technical_control")}
      </FullModal>
    );
  }
}
