import React from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons";
import { Header, ButtonIcon } from "ui-kit-ck-consultant";

import evalcarLogo from "../images/logo-white-1.png";

import { signout } from "../requests/general";

import AuthContext from "../context/AuthContext";

export default class DashboardLayout extends React.Component {
  static contextType = AuthContext;

  render() {
    if (this.props.connected === false) {
      return <Redirect to="/signin" />;
    } else {
      return (
        <div className="Dashboard-container">
          <div className="Dashboard-container-body">
            <Header
              logo={evalcarLogo}
              height="30"
              onClick={() => this.props.history.push("/home")}
            >
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => (document.location.href = this.context.url)}
              >
                <FontAwesomeIcon icon={faUser} />
              </ButtonIcon>
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => {
                  var url = window.location.href;
                  var arr = url.split("/");

                  signout(arr[0] + "//" + arr[2], (result) => {
                    if (result.success === true) {
                      document.location.href = result.redirect;
                    }
                  });
                }}
              >
                <FontAwesomeIcon icon={faPowerOff} />
              </ButtonIcon>
            </Header>

            <div
              className={
                this.props.location.pathname === "/home"
                  ? "Dashboard-content Dashboard-content-fixed"
                  : "Dashboard-content"
              }
            >
              {this.props.children}
            </div>
          </div>
        </div>
      );
    }
  }
}
