import React from "react";
import { Nav, Button, Loader } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import Status from "../../components/home/Status";
import Filters from "../../components/home/Filters";
import Folders from "../../components/home/Folders";
import ModalAddFolder from "../../components/home/ModalAddFolder";

import {
  getFolders,
  getTour,
  getStatistics,
  deleteFolder,
  addFolder,
} from "../../requests/home";
import ModalPDF from "../../components/home/ModalPDF";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      filters: {},
      data: [],
      dataTour: [],
      total: 0,
      totalOld: 0,
      marketSearch: 0,
      marketSearchOld: 0,
      descriptiveState: 0,
      descriptiveStateOld: 0,
      isModalAddFolder: false,
      isFolderCopy: false,
      currentReference: "",
      currentfolderId: "",
      isModalPreview: false,
      prewiewToken: "",
      titlePreview: "",
    };
  }

  componentDidMount() {
    this.getFolders();
    getStatistics((result) => {
      this.setState({
        total: result.total,
        totalOld: result.totalOld,
        marketSearch: result.marketSearch,
        marketSearchOld: result.marketSearchOld,
        descriptiveState: result.descriptiveState,
        descriptiveStateOld: result.descriptiveStateOld,
      });
    });
    getTour((result) => {
      if (result.success) {
        this.setState({ dataTour: result.data });
      } else {
        this.setState({ dataTour: [] });
      }
    });
  }

  onDataChange = (filters, isRequest = true) => {
    let tmpFilters = this.state.filters;

    Object.keys(filters).map((key) => {
      tmpFilters[key] = filters[key];
      return null;
    });

    this.setState(
      {
        filters: tmpFilters,
      },
      () => {
        if (isRequest) this.getFolders();
      }
    );
  };

  onDeleteFolder = (idx) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce dossier ? ")) {
      deleteFolder(this.state.data[idx].id, (result) => {
        if (result.success) {
          const tmpData = this.state.data;
          tmpData.splice(idx, 1);
          this.setState({ data: tmpData });
        }
      });
    }
  };

  getFolders = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getFolders(this.state.filters, (result) => {
          if (result.success) {
            this.setState({ isLoad: false, data: result.data });
          } else {
            this.setState({ isLoad: false, data: [] });
          }
        });
      }
    );
  };

  onOpenFolderClick = (id, reference = null) => {
    this.setState({ isLoad: true }, () => {
      if (id) {
        this.props.history.push("/folder/" + id);
      } else {
        addFolder(reference, false, (result) => {
          if (result.success) {
            if (result.isExist) {
              alert("Le dossier est déjà créé");
            }
            this.onOpenFolderClick(result.id);
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue, veuillez réssayer");
            });
          }
        });
      }
    });
  };

  onCopyFolder = (reference) => {
    this.setState({
      isModalAddFolder: true,
      isFolderCopy: true,
      currentReference: reference,
    });
  };

  onPreviewPDF = (token, name, folderId, idx) => {
    this.setState({
      isModalPreview: true,
      prewiewToken: token,
      titlePreview: `previsualisation-${name}.pdf`,
      currentfolderId: folderId,
    });
  };

  render() {
    return (
      <>
        <Nav title="Tableau de bord" className="mb-10 mb-30-xl">
          <Button
            text="Nouveau dossier"
            onClick={() => this.setState({ isModalAddFolder: true })}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Nav>
        <Status
          total={this.state.total}
          totalOld={this.state.totalOld}
          marketSearch={this.state.marketSearch}
          marketSearchOld={this.state.marketSearchOld}
          descriptiveState={this.state.descriptiveState}
          descriptiveStateOld={this.state.descriptiveStateOld}
          isMobile={window.innerWidth < 560}
        />
        <Row className="pb-10 xl-h-100" style={{ marginBottom: "-10px" }}>
          <Col xs={12} xl={3}>
            <Filters
              onDataChange={this.onDataChange}
              isMobile={window.innerWidth < 560}
            />
          </Col>
          <Col xs={12} xl={9} className="xl-h-100">
            <Folders
              title="Tournée"
              data={this.state.dataTour}
              search={this.state.filters.search}
              descriptiveStateStatus={this.state.filters.descriptiveStateStatus}
              marketSearchStatus={this.state.filters.marketSearchStatus}
              onDeleteFolder={this.onDeleteFolder}
              onOpenFolderClick={this.onOpenFolderClick}
              onCopyFolder={this.onCopyFolder}
              onPreviewPDF={this.onPreviewPDF}
              isFlex={false}
            />
            <Folders
              title="Dossiers"
              data={this.state.data}
              search={this.state.filters.search}
              descriptiveStateStatus={this.state.filters.descriptiveStateStatus}
              marketSearchStatus={this.state.filters.marketSearchStatus}
              onDeleteFolder={this.onDeleteFolder}
              onOpenFolderClick={this.onOpenFolderClick}
              onCopyFolder={this.onCopyFolder}
              onPreviewPDF={this.onPreviewPDF}
              isFlex={true}
            />
          </Col>
        </Row>
        <ModalAddFolder
          show={this.state.isModalAddFolder}
          reference={this.state.filters.search}
          onClose={(isRequest) =>
            this.setState(
              { isModalAddFolder: false, isFolderCopy: false },
              () => {
                if (isRequest) this.getFolders();
              }
            )
          }
          onOpenFolderClick={this.onOpenFolderClick}
          isFolderCopy={this.state.isFolderCopy}
          currentReference={this.state.currentReference}
        />
        <ModalPDF
          onClose={() =>
            this.setState({
              isModalPreview: false,
              prewiewTokenData: "",
              titlePreview: "",
            })
          }
          show={this.state.isModalPreview}
          prewiewToken={this.state.prewiewToken}
          name={this.state.titlePreview}
          folderId={this.state.currentfolderId}
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
