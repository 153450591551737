const carTable = {
  "(1/A) Petits Véhicules": [
    0.049, 0.048, 0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038,
    0.037, 0.037, 0.036, 0.036, 0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032,
    0.032, 0.031, 0.031, 0.03, 0.03, 0.029, 0.029, 0.029, 0.028, 0.028, 0.027,
    0.027, 0.027, 0.026, 0.026, 0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023,
    0.023, 0.022, 0.022, 0.022, 0.021, 0.021, 0.021, 0.02, 0.02, 0.02, 0.02,
    0.019, 0.019, 0.019, 0.019, 0.018, 0.018, 0.018, 0.018, 0.017, 0.017, 0.017,
    0.017, 0.016, 0.016, 0.016, 0.015, 0.015, 0.015, 0.015, 0.014, 0.014, 0.014,
    0.014, 0.014, 0.013, 0.013, 0.013, 0.013, 0.013, 0.012, 0.012, 0.012, 0.012,
    0.012, 0.011, 0.011, 0.011, 0.011, 0.011, 0.011, 0.011, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009,
    0.009, 0.009, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008,
  ],
  "(2/B) Gamme Moyenne Inférieure": [
    0.048, 0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038, 0.037,
    0.037, 0.036, 0.036, 0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032, 0.032,
    0.031, 0.03, 0.03, 0.03, 0.029, 0.029, 0.028, 0.028, 0.028, 0.027, 0.027,
    0.027, 0.026, 0.026, 0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023, 0.023,
    0.022, 0.022, 0.021, 0.021, 0.021, 0.02, 0.02, 0.02, 0.02, 0.019, 0.019,
    0.019, 0.019, 0.018, 0.018, 0.018, 0.018, 0.017, 0.017, 0.017, 0.017, 0.016,
    0.016, 0.016, 0.015, 0.015, 0.015, 0.015, 0.014, 0.014, 0.014, 0.014, 0.014,
    0.013, 0.013, 0.013, 0.013, 0.012, 0.012, 0.012, 0.012, 0.012, 0.011, 0.011,
    0.011, 0.011, 0.011, 0.011, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.009,
    0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.008, 0.008, 0.008, 0.008,
    0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.007, 0.007,
  ],
  "(3/C) Gamme Moyenne Supérieure": [
    0.048, 0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038, 0.037,
    0.037, 0.036, 0.036, 0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032, 0.032,
    0.031, 0.03, 0.03, 0.03, 0.029, 0.029, 0.028, 0.028, 0.028, 0.027, 0.027,
    0.027, 0.026, 0.026, 0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023, 0.023,
    0.022, 0.022, 0.021, 0.021, 0.021, 0.02, 0.02, 0.02, 0.02, 0.019, 0.019,
    0.019, 0.019, 0.018, 0.018, 0.018, 0.018, 0.017, 0.017, 0.017, 0.017, 0.016,
    0.016, 0.016, 0.015, 0.015, 0.015, 0.015, 0.014, 0.014, 0.014, 0.014, 0.014,
    0.013, 0.013, 0.013, 0.013, 0.012, 0.012, 0.012, 0.012, 0.012, 0.011, 0.011,
    0.011, 0.011, 0.011, 0.011, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.009,
    0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.008, 0.008, 0.008, 0.008,
    0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.007, 0.007,
  ],
  "(4/D) Gamme Haute": [
    0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038, 0.037, 0.036,
    0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032, 0.032, 0.031, 0.031, 0.03,
    0.03, 0.029, 0.029, 0.029, 0.028, 0.028, 0.027, 0.027, 0.027, 0.026, 0.026,
    0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023, 0.023, 0.022, 0.022, 0.022,
    0.021, 0.021, 0.02, 0.02, 0.02, 0.019, 0.019, 0.019, 0.019, 0.018, 0.018,
    0.018, 0.018, 0.017, 0.017, 0.017, 0.017, 0.016, 0.016, 0.016, 0.015, 0.015,
    0.015, 0.015, 0.014, 0.014, 0.014, 0.014, 0.013, 0.013, 0.013, 0.013, 0.012,
    0.012, 0.012, 0.012, 0.012, 0.011, 0.011, 0.011, 0.011, 0.011, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.009, 0.009, 0.009, 0.009, 0.009, 0.008, 0.008, 0.008,
    0.008, 0.008, 0.007, 0.007, 0.007, 0.007, 0.007, 0.006, 0.006, 0.006, 0.006,
    0.006, 0.006, 0.005, 0.005, 0.005, 0.005, 0.005, 0.005, 0.004, 0.004,
  ],
  "(7/G) Véhicule de Société Dérivé de V.L": [
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
    0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
  "(8/H) Véhicule Utilitaire Léger": [
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
  "(9/J) 4x4": [
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
};

const bikeTable = {
  "(10/K) Motos < 50cc": [
    0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
  "(11/L) Motos 50 >< 125cc": [
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
    0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
  "(12/M) Motos > 125": [
    0.06, 0.06, 0.06, 0.06, 0.06, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.05, 0.05, 0.05, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
    0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
  ],
};

const commercialTable = {
  "(1/A) Petits Véhicules": [
    0.049, 0.048, 0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038,
    0.037, 0.037, 0.036, 0.036, 0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032,
    0.032, 0.031, 0.031, 0.03, 0.03, 0.029, 0.029, 0.029, 0.028, 0.028, 0.027,
    0.027, 0.027, 0.026, 0.026, 0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023,
    0.023, 0.022, 0.022, 0.022, 0.021, 0.021, 0.021, 0.02, 0.02, 0.02, 0.02,
    0.019, 0.019, 0.019, 0.019, 0.018, 0.018, 0.018, 0.018, 0.017, 0.017, 0.017,
    0.017, 0.016, 0.016, 0.016, 0.015, 0.015, 0.015, 0.015, 0.014, 0.014, 0.014,
    0.014, 0.014, 0.013, 0.013, 0.013, 0.013, 0.013, 0.012, 0.012, 0.012, 0.012,
    0.012, 0.011, 0.011, 0.011, 0.011, 0.011, 0.011, 0.011, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009,
    0.009, 0.009, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008,
  ],
  "(2/B) Gamme Moyenne Inférieure": [
    0.048, 0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038, 0.037,
    0.037, 0.036, 0.036, 0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032, 0.032,
    0.031, 0.03, 0.03, 0.03, 0.029, 0.029, 0.028, 0.028, 0.028, 0.027, 0.027,
    0.027, 0.026, 0.026, 0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023, 0.023,
    0.022, 0.022, 0.021, 0.021, 0.021, 0.02, 0.02, 0.02, 0.02, 0.019, 0.019,
    0.019, 0.019, 0.018, 0.018, 0.018, 0.018, 0.017, 0.017, 0.017, 0.017, 0.016,
    0.016, 0.016, 0.015, 0.015, 0.015, 0.015, 0.014, 0.014, 0.014, 0.014, 0.014,
    0.013, 0.013, 0.013, 0.013, 0.012, 0.012, 0.012, 0.012, 0.012, 0.011, 0.011,
    0.011, 0.011, 0.011, 0.011, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.009,
    0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.008, 0.008, 0.008, 0.008,
    0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.007, 0.007,
  ],
  "(3/C) Gamme Moyenne Supérieure": [
    0.048, 0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038, 0.037,
    0.037, 0.036, 0.036, 0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032, 0.032,
    0.031, 0.03, 0.03, 0.03, 0.029, 0.029, 0.028, 0.028, 0.028, 0.027, 0.027,
    0.027, 0.026, 0.026, 0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023, 0.023,
    0.022, 0.022, 0.021, 0.021, 0.021, 0.02, 0.02, 0.02, 0.02, 0.019, 0.019,
    0.019, 0.019, 0.018, 0.018, 0.018, 0.018, 0.017, 0.017, 0.017, 0.017, 0.016,
    0.016, 0.016, 0.015, 0.015, 0.015, 0.015, 0.014, 0.014, 0.014, 0.014, 0.014,
    0.013, 0.013, 0.013, 0.013, 0.012, 0.012, 0.012, 0.012, 0.012, 0.011, 0.011,
    0.011, 0.011, 0.011, 0.011, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.009,
    0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.008, 0.008, 0.008, 0.008,
    0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.007, 0.007,
  ],
  "(4/D) Gamme Haute": [
    0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038, 0.037, 0.036,
    0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032, 0.032, 0.031, 0.031, 0.03,
    0.03, 0.029, 0.029, 0.029, 0.028, 0.028, 0.027, 0.027, 0.027, 0.026, 0.026,
    0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023, 0.023, 0.022, 0.022, 0.022,
    0.021, 0.021, 0.02, 0.02, 0.02, 0.019, 0.019, 0.019, 0.019, 0.018, 0.018,
    0.018, 0.018, 0.017, 0.017, 0.017, 0.017, 0.016, 0.016, 0.016, 0.015, 0.015,
    0.015, 0.015, 0.014, 0.014, 0.014, 0.014, 0.013, 0.013, 0.013, 0.013, 0.012,
    0.012, 0.012, 0.012, 0.012, 0.011, 0.011, 0.011, 0.011, 0.011, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.009, 0.009, 0.009, 0.009, 0.009, 0.008, 0.008, 0.008,
    0.008, 0.008, 0.007, 0.007, 0.007, 0.007, 0.007, 0.006, 0.006, 0.006, 0.006,
    0.006, 0.006, 0.005, 0.005, 0.005, 0.005, 0.005, 0.005, 0.004, 0.004,
  ],
  "(7/G) Véhicule de Société Dérivé de V.L": [
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
    0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
  "(8/H) Véhicule Utilitaire Léger": [
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
  "(9/J) 4x4": [
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
};

const caravaningTable = {
  "(1/A) Petits Véhicules": [
    0.049, 0.048, 0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038,
    0.037, 0.037, 0.036, 0.036, 0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032,
    0.032, 0.031, 0.031, 0.03, 0.03, 0.029, 0.029, 0.029, 0.028, 0.028, 0.027,
    0.027, 0.027, 0.026, 0.026, 0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023,
    0.023, 0.022, 0.022, 0.022, 0.021, 0.021, 0.021, 0.02, 0.02, 0.02, 0.02,
    0.019, 0.019, 0.019, 0.019, 0.018, 0.018, 0.018, 0.018, 0.017, 0.017, 0.017,
    0.017, 0.016, 0.016, 0.016, 0.015, 0.015, 0.015, 0.015, 0.014, 0.014, 0.014,
    0.014, 0.014, 0.013, 0.013, 0.013, 0.013, 0.013, 0.012, 0.012, 0.012, 0.012,
    0.012, 0.011, 0.011, 0.011, 0.011, 0.011, 0.011, 0.011, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009,
    0.009, 0.009, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008,
  ],
  "(2/B) Gamme Moyenne Inférieure": [
    0.048, 0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038, 0.037,
    0.037, 0.036, 0.036, 0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032, 0.032,
    0.031, 0.03, 0.03, 0.03, 0.029, 0.029, 0.028, 0.028, 0.028, 0.027, 0.027,
    0.027, 0.026, 0.026, 0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023, 0.023,
    0.022, 0.022, 0.021, 0.021, 0.021, 0.02, 0.02, 0.02, 0.02, 0.019, 0.019,
    0.019, 0.019, 0.018, 0.018, 0.018, 0.018, 0.017, 0.017, 0.017, 0.017, 0.016,
    0.016, 0.016, 0.015, 0.015, 0.015, 0.015, 0.014, 0.014, 0.014, 0.014, 0.014,
    0.013, 0.013, 0.013, 0.013, 0.012, 0.012, 0.012, 0.012, 0.012, 0.011, 0.011,
    0.011, 0.011, 0.011, 0.011, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.009,
    0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.008, 0.008, 0.008, 0.008,
    0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.007, 0.007,
  ],
  "(3/C) Gamme Moyenne Supérieure": [
    0.048, 0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038, 0.037,
    0.037, 0.036, 0.036, 0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032, 0.032,
    0.031, 0.03, 0.03, 0.03, 0.029, 0.029, 0.028, 0.028, 0.028, 0.027, 0.027,
    0.027, 0.026, 0.026, 0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023, 0.023,
    0.022, 0.022, 0.021, 0.021, 0.021, 0.02, 0.02, 0.02, 0.02, 0.019, 0.019,
    0.019, 0.019, 0.018, 0.018, 0.018, 0.018, 0.017, 0.017, 0.017, 0.017, 0.016,
    0.016, 0.016, 0.015, 0.015, 0.015, 0.015, 0.014, 0.014, 0.014, 0.014, 0.014,
    0.013, 0.013, 0.013, 0.013, 0.012, 0.012, 0.012, 0.012, 0.012, 0.011, 0.011,
    0.011, 0.011, 0.011, 0.011, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.009,
    0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.009, 0.008, 0.008, 0.008, 0.008,
    0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.008, 0.007, 0.007,
  ],
  "(4/D) Gamme Haute": [
    0.047, 0.046, 0.045, 0.044, 0.043, 0.041, 0.04, 0.039, 0.038, 0.037, 0.036,
    0.035, 0.035, 0.034, 0.034, 0.033, 0.033, 0.032, 0.032, 0.031, 0.031, 0.03,
    0.03, 0.029, 0.029, 0.029, 0.028, 0.028, 0.027, 0.027, 0.027, 0.026, 0.026,
    0.025, 0.025, 0.025, 0.024, 0.024, 0.023, 0.023, 0.023, 0.022, 0.022, 0.022,
    0.021, 0.021, 0.02, 0.02, 0.02, 0.019, 0.019, 0.019, 0.019, 0.018, 0.018,
    0.018, 0.018, 0.017, 0.017, 0.017, 0.017, 0.016, 0.016, 0.016, 0.015, 0.015,
    0.015, 0.015, 0.014, 0.014, 0.014, 0.014, 0.013, 0.013, 0.013, 0.013, 0.012,
    0.012, 0.012, 0.012, 0.012, 0.011, 0.011, 0.011, 0.011, 0.011, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.009, 0.009, 0.009, 0.009, 0.009, 0.008, 0.008, 0.008,
    0.008, 0.008, 0.007, 0.007, 0.007, 0.007, 0.007, 0.006, 0.006, 0.006, 0.006,
    0.006, 0.006, 0.005, 0.005, 0.005, 0.005, 0.005, 0.005, 0.004, 0.004,
  ],
  "(7/G) Véhicule de Société Dérivé de V.L": [
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
    0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
  "(8/H) Véhicule Utilitaire Léger": [
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
  "(9/J) 4x4": [
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02, 0.02,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
    0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
  ],
};

const getTable = (type) => {
  switch (type) {
    case 0:
      return carTable;
    case 1:
      return bikeTable;
    case 2:
      return commercialTable;
    case 3:
      return caravaningTable;
    default:
      return {};
  }
};

export { getTable };
