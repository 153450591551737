import React from "react";
import moment from "moment";
import {
  Card,
  FormInput,
  FormRadio,
  FormTextarea,
  Button,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getDifferenceDays } from "../../../utils/general";
import { faCamera, faTrash } from "@fortawesome/free-solid-svg-icons";

export default class Guarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isKey: null,
      isChargeCable: null,
      isRedKey: null,
      isSecureSRA: null,
      isGuarding: null,
      isCertificat: null,
      countKey: "",
      dateDeposit: "",
      costDay: "",
      costToday: "",
      estimateComment: "",
      examCondition1: false,
      examCondition2: false,
      examCondition3: false,
      certificatBase64: "",
      certificatType: "",
    };
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState(
      {
        [name]: value,
      },
      this.updateFolderGuarding
    );
  };

  handleRadioChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: parseInt(value),
      },
      this.updateFolderGuarding
    );
  };

  handleCheckboxChange = (e) => {
    const { name } = e.target;
    this.setState(
      {
        [name]: !this.state[name],
      },
      this.updateFolderGuarding
    );
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.dataGuarding) !==
        JSON.stringify(this.props.dataGuarding) ||
      JSON.stringify(prevProps.dataIdentification) !==
        JSON.stringify(this.props.dataIdentification)
    ) {
      this.setState({
        isKey: this.props.dataGuarding.isKey,
        isChargeCable: this.props.dataGuarding.isChargeCable,
        isRedKey: this.props.dataGuarding.isRedKey,
        isSecureSRA: this.props.dataGuarding.isSecureSRA,
        isGuarding: this.props.dataGuarding.isGuarding,
        isCertificat: this.props.dataGuarding.isCertificat,
        examCondition1: this.props.dataGuarding.examCondition1,
        examCondition2: this.props.dataGuarding.examCondition2,
        examCondition3: this.props.dataGuarding.examCondition3,
        certificatBase64: this.props.dataGuarding.certificatBase64,
        certificatType: this.props.dataGuarding.certificatType,
        countKey: this.props.dataGuarding.countKey
          ? this.props.dataGuarding.countKey
          : "",
        dateDeposit: this.props.dataGuarding.dateDeposit
          ? this.props.dataGuarding.dateDeposit
          : this.props.dataIdentification &&
            this.props.dataIdentification["Date_Sinistre"]
          ? moment(
              this.props.dataIdentification["Date_Sinistre"][0],
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")
          : "",
        costDay: this.props.dataGuarding.costDay
          ? this.props.dataGuarding.costDay
          : "",
        costToday: this.props.dataGuarding.costToday
          ? this.props.dataGuarding.costToday
          : "",
        estimateComment: this.props.dataGuarding.estimateComment
          ? this.props.dataGuarding.estimateComment
          : "",
      });
    }
  }

  updateFolderGuarding = () => {
    if (this.props.isEdit) {
      let tmpDataGuarding = this.props.dataGuarding;

      tmpDataGuarding.isKey = this.state.isKey;
      tmpDataGuarding.isChargeCable = this.state.isChargeCable;
      tmpDataGuarding.isRedKey = this.state.isRedKey;
      tmpDataGuarding.isSecureSRA = this.state.isSecureSRA;
      tmpDataGuarding.isGuarding = this.state.isGuarding;
      tmpDataGuarding.isCertificat = this.state.isCertificat;
      tmpDataGuarding.examCondition1 = this.state.examCondition1;
      tmpDataGuarding.examCondition2 = this.state.examCondition2;
      tmpDataGuarding.examCondition3 = this.state.examCondition3;
      tmpDataGuarding.certificatBase64 = this.state.certificatBase64;
      tmpDataGuarding.certificatType = this.state.certificatType;
      tmpDataGuarding.countKey = this.state.countKey;
      tmpDataGuarding.dateDeposit = this.state.dateDeposit;
      tmpDataGuarding.costDay = this.state.costDay;
      tmpDataGuarding.costToday = this.state.costToday;
      tmpDataGuarding.estimateComment = this.state.estimateComment;

      this.props.updateFolder("dataGuarding", tmpDataGuarding);
    }
  };

  render() {
    const costToday = this.state.costToday
      ? this.state.costToday
      : parseInt(this.state.costDay) &&
        getDifferenceDays(moment(this.state.dateDeposit), moment())
      ? parseInt(this.state.costDay) *
        getDifferenceDays(moment(this.state.dateDeposit), moment())
      : 0;

    return (
      <Row>
        <Col xs={12} md={4} className="d-flex">
          <Card title="Frais de devis" className="mt-10 mt-20-xl w-100">
            <FormTextarea
              placeholder="Commentaire..."
              rows={10}
              name="estimateComment"
              value={
                this.state.estimateComment ? this.state.estimateComment : ""
              }
              onChange={this.handleInputChange}
            />
          </Card>
        </Col>
        <Col xs={12} md={4} className="d-flex">
          <Card title="Clés" className="mt-10 mt-20-xl w-100">
            <p className="m-0 mb-5">Présence des clés</p>
            <FormRadio
              name="isKey"
              value={1}
              checked={this.state.isKey === 1}
              text="Oui"
              onChange={this.handleRadioChange}
            />
            <FormRadio
              className="mb-10"
              name="isKey"
              value={0}
              checked={this.state.isKey === 0}
              text="Non"
              onChange={this.handleRadioChange}
            />
            {this.state.isKey ? (
              <>
                {!this.props.isPrint ? (
                  <FormInput
                    className="mb-10"
                    name="countKey"
                    value={this.state.countKey}
                    onChange={this.handleInputChange}
                    title="Nombre de clés"
                    style={{ maxWidth: "270px" }}
                  />
                ) : (
                  <p>Nombre de clés : {this.state.countKey}</p>
                )}
              </>
            ) : null}

            {this.props.fuel === 4 ? (
              <>
                <p className="m-0 mb-5">Cable de recharge présent</p>
                <FormRadio
                  name="isChargeCable"
                  value={1}
                  checked={this.state.isChargeCable === 1}
                  text="Oui"
                  onChange={this.handleRadioChange}
                />
                <FormRadio
                  className="mb-10"
                  name="isChargeCable"
                  value={0}
                  checked={this.state.isChargeCable === 0}
                  text="Non"
                  onChange={this.handleRadioChange}
                />
              </>
            ) : null}

            {this.props.gender === 1 ? (
              <>
                <p className="m-0 mb-5">Présence clé rouge</p>
                <FormRadio
                  name="isRedKey"
                  value={1}
                  checked={this.state.isRedKey === 1}
                  text="Oui"
                  onChange={this.handleRadioChange}
                />
                <FormRadio
                  className="mb-10"
                  name="isRedKey"
                  value={0}
                  checked={this.state.isRedKey === 0}
                  text="Non"
                  onChange={this.handleRadioChange}
                />

                <p className="m-0 mb-5">Présence antivol SRA</p>
                <FormRadio
                  name="isSecureSRA"
                  value={1}
                  checked={this.state.isSecureSRA === 1}
                  text="Oui"
                  onChange={this.handleRadioChange}
                />
                <FormRadio
                  className="mb-10"
                  name="isSecureSRA"
                  value={0}
                  checked={this.state.isSecureSRA === 0}
                  text="Non"
                  onChange={this.handleRadioChange}
                />
              </>
            ) : null}
          </Card>
        </Col>
        <Col xs={12} md={4} className="d-flex">
          <Card title="Gardiennage" className="mt-10 mt-20-xl w-100">
            {!this.props.isPrint ? (
              <FormInput
                className="mb-10"
                name="dateDeposit"
                value={this.state.dateDeposit}
                onChange={this.handleInputChange}
                title="Date de dépôt du véhicule"
                type="date"
                style={{ maxWidth: "270px" }}
              />
            ) : (
              <p>
                Date de dépôt du véhicule :{" "}
                {this.state.dateDeposit
                  ? moment(this.state.dateDeposit).format("DD/MM/YYYY")
                  : ""}
              </p>
            )}
            <p className="m-0 mb-5">Frais de gardiennage</p>
            <FormRadio
              name="isGuarding"
              value={1}
              checked={this.state.isGuarding === 1}
              text="Oui"
              onChange={this.handleRadioChange}
            />
            <FormRadio
              className="mb-10"
              name="isGuarding"
              value={0}
              checked={this.state.isGuarding === 0}
              text="Non"
              onChange={this.handleRadioChange}
            />
            {this.state.isGuarding ? (
              <>
                {!this.props.isPrint ? (
                  <FormInput
                    className="mb-10"
                    name="costDay"
                    value={this.state.costDay}
                    onChange={this.handleInputChange}
                    title="Coût journalier (en € HT)"
                    style={{ maxWidth: "270px" }}
                  />
                ) : (
                  <p>Coût journalier : {this.state.costDay} € HT</p>
                )}
                {!this.props.isPrint ? (
                  <FormInput
                    className="mb-10"
                    name="costToday"
                    value={costToday}
                    onChange={this.handleInputChange}
                    title="Montant gardiennage à ce jour (en € HT)"
                    style={{ maxWidth: "270px" }}
                  />
                ) : (
                  <p>Montant gardiennage à ce jour : {costToday} € HT</p>
                )}
              </>
            ) : null}
          </Card>
        </Col>
        <Col xs={12} md={8} className="d-flex">
          <Card
            title="Certificat d’immatriculation"
            className="mt-10 mt-20-xl w-100"
          >
            <Row>
              <Col xs={12} md={6} className="d-flex flex-column">
                <FormRadio
                  className="mb-10"
                  name="isCertificat"
                  value={1}
                  checked={this.state.isCertificat === 1}
                  text="Oui"
                  onChange={this.handleRadioChange}
                />
                <FormRadio
                  className="mb-10"
                  name="isCertificat"
                  value={0}
                  checked={this.state.isCertificat === 0}
                  text="Non"
                  onChange={this.handleRadioChange}
                />
                <Button
                  className="w-auto mt-auto"
                  text="Prendre une photo"
                  onClick={() => {
                    if (this.refInput) {
                      this.refInput.click();
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
                </Button>
              </Col>
              {this.state.certificatBase64 ? (
                <Col xs={12} md={6} className="d-flex">
                  <div className="d-flex" style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        padding: "5px",
                        background: "#ff0000a1",
                        borderRadius: "5px",

                        top: 5,
                        right: 15,
                      }}
                      className="cursor-pointer"
                      onClick={() =>
                        this.setState(
                          {
                            certificatBase64: "",
                            certificatType: "",
                          },
                          this.updateFolderGuarding
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                    <img
                      src={this.state.certificatBase64}
                      alt="certficat"
                      style={{
                        maxWidth: "100%",
                        maxHeight: 400,
                        borderRadius: 8,
                      }}
                    />
                  </div>
                </Col>
              ) : null}
              <input
                ref={(ref) => (this.refInput = ref)}
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  var reader = new FileReader();
                  reader.readAsDataURL(e.target.files[0]);
                  reader.onload = () => {
                    if (e.target.files[0].type.includes("image")) {
                      this.setState(
                        {
                          certificatBase64: reader.result,
                          certificatType: e.target.files[0].type,
                        },
                        this.updateFolderGuarding
                      );
                    } else {
                      alert("Format incompatible");
                    }
                  };
                  reader.onerror = (error) => {};
                }}
              />
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={4} className="d-flex">
          <Card
            title="Conditions de l’examen"
            className="mt-10 mt-20-xl w-100"
            classNameChildren="d-flex flex-column"
          >
            <FormCheckbox
              className="mb-10"
              name="examCondition1"
              checked={this.state.examCondition1}
              text="Sans démontage"
              onChange={this.handleCheckboxChange}
            />
            <FormCheckbox
              className="mb-10"
              name="examCondition2"
              checked={this.state.examCondition2}
              text="Sans essai routier"
              onChange={this.handleCheckboxChange}
            />
            <FormCheckbox
              className="mb-10"
              name="examCondition3"
              checked={this.state.examCondition3}
              text="Sur pont"
              onChange={this.handleCheckboxChange}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}
