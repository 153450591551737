import React from "react";
import {
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  PaginationClass,
  Loader,
} from "ui-kit-ck-consultant";

import Filters from "../../../components/folder/market-search/Filters";
import Ads from "../../../components/folder/market-search/Ads";

import axiosConfig from "../../../utils/axiosConfig";

import {
  getAdsIds,
  insertSearch,
  disableAd,
  enableAd,
} from "../../../requests/marketSearch";

export default class MarketSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      tabsIdArray: [
        { disabled: false, title: "Recherche" },
        { disabled: false, title: "Annonces sauvegardées" },
      ],
      tabsId: 1,
      activePage: 1,
      tabLength: 15,
      total: 0,
      ads: [],
      adsSelect: [],
      isDisableCheck: false,
      isAutoSearch: false,
      autoSearchTimer: 0,
    };
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "/market-search/count",
      "/market-search/pagination",
      this.state.tabLength
    );

    window.disableCheck = (value) => {
      this.setState({
        isDisableCheck: value,
      });
    };

    window.updateTabLength = (length) => {
      this.setState(
        {
          tabLength: length,
        },
        () => {
          this.PaginationClass = new PaginationClass(
            "/market-search/count",
            "/market-search/pagination",
            this.state.tabLength
          );
        }
      );
    };

    window.autoSearch = (status = true, timer = 60000) => {
      this.setState(
        {
          isAutoSearch: status,
          autoSearchTimer: timer,
        },
        () => {
          this.handlePageChange(this.state.activePage + 1);
        }
      );
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.dataSelect) !==
        JSON.stringify(prevProps.dataSelect) &&
      this.state.tabsId === 1
    ) {
      this.getTabsId();
    }
  }

  initSearch = (step = 1) => {
    const filters = this.filtersRef.getFilters();
    filters.isDisableCheck = this.state.isDisableCheck;

    const order = this.adsRef.state.order;
    if (order.name) {
      filters.orderName = order.name;
      filters.orderDesc = order.desc;
    }

    this.setState(
      {
        isLoad: true,
        message: `Récupération des annonces étape ${step}`,
        activePage: 1,
      },
      () => {
        insertSearch(this.props.id, filters, () => {
          this.PaginationClass.init(axiosConfig, filters, (result) => {
            if (result.success === true) {
              step++;
              this.setState(
                {
                  total: result.total,
                  message: `Récupération des annonces étape ${step}`,
                },
                () => {
                  this.PaginationClass.get(axiosConfig, filters, (result) => {
                    if (result.success) {
                      this.setData(result.tabData, step);
                    } else {
                      this.setData([], step);
                    }
                  });
                }
              );
            } else {
              alert("Aucune annonce");
              this.setState(
                {
                  total: 0,
                },
                () => {
                  this.setData([], step);
                }
              );
            }
          });
        });
      }
    );
  };

  handlePageChange = (activePage, step = 1) => {
    this.setState(
      { isLoad: true, message: `Récupération des annonces étape ${step}` },
      () => {
        this.PaginationClass.page(activePage, () =>
          this.setState({ activePage: activePage }, () => {
            const filters = this.filtersRef.getFilters();
            filters.isDisableCheck = this.state.isDisableCheck;

            const order = this.adsRef.state.order;
            if (order.name) {
              filters.orderName = order.name;
              filters.orderDesc = order.desc;
            }

            this.PaginationClass.get(axiosConfig, filters, (result) => {
              if (result.success) {
                this.setData(result.tabData, step);
              } else {
                this.setData([], step);
              }
            });
          })
        );
      }
    );
  };

  setData = (data, step = 1) => {
    const filters = this.filtersRef.getFilters();

    const spliceData = data.splice(0, this.state.tabLength);

    let isDisabled = false;
    spliceData.map((ad) => {
      if (ad.status === 1) {
        isDisabled = true;
      }
      return null;
    });

    if (filters.isAllStatus === true || !isDisabled) {
      this.setState(
        {
          ads: spliceData,
          isLoad: false,
          message: "",
        },
        () => {
          if (this.state.isAutoSearch) {
            setTimeout(() => {
              this.handlePageChange(this.state.activePage + 1);
            }, this.state.autoSearchTimer);
          }
        }
      );
    } else {
      this.handlePageChange(this.state.activePage, step + 1);
    }
  };

  onClickSearch = () => {
    this.initSearch();
  };

  onClickDisableAd = (idx) => {
    disableAd(
      this.state.tabsId === 0
        ? this.state.ads[idx].id
        : this.state.adsSelect[idx].id,
      (result) => {
        if (result.success) {
          let tmpAds =
            this.state.tabsId === 0 ? this.state.ads : this.state.adsSelect;

          tmpAds[idx].status = 2;

          if (this.state.tabsId === 0) {
            this.setState({ ads: tmpAds });
          } else {
            this.setState({ adsSelect: tmpAds });
          }
        }
      }
    );
  };

  onClickEnableAd = (idx) => {
    enableAd(
      this.state.tabsId === 0
        ? this.state.ads[idx].id
        : this.state.adsSelect[idx].id,
      (result) => {
        if (result.success) {
          let tmpAds =
            this.state.tabsId === 0 ? this.state.ads : this.state.adsSelect;

          tmpAds[idx].status = 0;

          if (this.state.tabsId === 0) {
            this.setState({ ads: tmpAds });
          } else {
            this.setState({ adsSelect: tmpAds });
          }
        }
      }
    );
  };

  getTabsId = () => {
    this.setState({ isLoad: true }, () => {
      let ids = [];
      if (this.props.dataSelect) {
        ids = Object.keys(this.props.dataSelect).map((key) => {
          return key;
        });
      }

      if (ids.length) {
        getAdsIds({ ids }, (result) => {
          if (result.success) {
            this.setState({ isLoad: false, adsSelect: result.data });
          } else {
            this.setState({ isLoad: false, adsSelect: [] });
          }
        });
      }
    });
  };

  onChangeTabs = (idx) => {
    this.setState(
      {
        tabsId: idx,
      },
      () => {
        if (idx === 1) {
          this.getTabsId();
        }
      }
    );
  };

  render() {
    return (
      <>
        <Filters
          ref={(ref) => (this.filtersRef = ref)}
          gender={this.props.gender}
          dataFilters={this.props.dataFilters}
          onClickSearch={this.onClickSearch}
          isSearch={!this.state.tabsId}
          id={this.props.id}
          dataIdentification={this.props.dataIdentification}
        />
        <TabsCustom>
          <TabsCustomHeader className="m-0 mt-10 mt-20-xl">
            {this.state.tabsIdArray.map((element, key) => (
              <TabsCustomMenu
                key={key}
                title={element.title}
                disabled={element.disabled}
                active={this.state.tabsId === key}
                onClick={() => this.onChangeTabs(key)}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsId === 0}>
              <Ads
                ref={(ref) => (this.adsRef = ref)}
                gender={this.props.gender}
                id={this.props.id}
                isPagination={true}
                activePage={this.state.activePage}
                tabLength={this.state.tabLength}
                total={this.state.total}
                handlePageChange={this.handlePageChange}
                ads={this.state.ads}
                onClickDisableAd={this.onClickDisableAd}
                onClickEnableAd={this.onClickEnableAd}
                onClickSearch={this.onClickSearch}
                dataSelect={this.props.dataSelect}
                updateFolder={this.props.updateFolder}
                filtersRef={this.filtersRef}
                onChangeTab={() => this.onChangeTabs(this.state.tabsId)}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsId === 1}>
              <Ads
                gender={this.props.gender}
                id={this.props.id}
                ads={this.state.adsSelect}
                onClickDisableAd={this.onClickDisableAd}
                onClickEnableAd={this.onClickEnableAd}
                dataSelect={this.props.dataSelect}
                updateFolder={this.props.updateFolder}
                onChangeTab={() => this.onChangeTabs(this.state.tabsId)}
                filtersRef={this.filtersRef}
              />
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        <Loader show={this.state.isLoad} message={this.state.message} />
      </>
    );
  }
}
