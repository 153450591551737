import React from "react";
import { CardStatistics } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import {
  faFolder,
  faMoneyBill,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";

export default class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getColor = (value, isReverse = false) => {
    if (value === 0) {
      return "blue";
    } else if (value < 0) {
      if (isReverse) {
        return "green";
      } else {
        return "red";
      }
    } else {
      if (isReverse) {
        return "red";
      } else {
        return "green";
      }
    }
  };

  render() {
    if (this.props.isMobile) {
      return null;
    }

    return (
      <Row>
        <Col xs={12} md={4} className="mb-10 mb-30-xl">
          <CardStatistics
            title="État descriptif en cours / à faire"
            subtitle={this.props.descriptiveState}
            evolution={
              this.props.descriptiveStateOld > 0
                ? `+${this.props.descriptiveStateOld}`
                : this.props.descriptiveStateOld
            }
            evolutionColor={this.getColor(this.props.descriptiveStateOld, true)}
            icon={faToolbox}
          />
        </Col>
        <Col xs={12} md={4} className="mb-10 mb-30-xl">
          <CardStatistics
            title="Valeur de marché en cours / à faire"
            subtitle={this.props.marketSearch}
            evolution={
              this.props.marketSearchOld > 0
                ? `+${this.props.marketSearchOld}`
                : this.props.marketSearchOld
            }
            evolutionColor={this.getColor(this.props.marketSearchOld, true)}
            icon={faMoneyBill}
          />
        </Col>
        <Col xs={12} md={4} className="mb-10 mb-30-xl">
          <CardStatistics
            title="Dossiers créés"
            subtitle={this.props.total}
            evolution={
              this.props.totalOld > 0
                ? `+${this.props.totalOld}`
                : this.props.totalOld
            }
            evolutionColor={this.getColor(this.props.totalOld)}
            icon={faFolder}
          />
        </Col>
      </Row>
    );
  }
}
