import React from "react";
import { Row, Col } from "react-flexbox-grid";

import {
  getPrice,
  getInt,
  getFuelString,
  getGearboxString,
} from "../../../utils/general";
import { getRegionFromDepartment } from "../../../utils/region";

export default class Ad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFull: false,
    };
  }

  onClickFull = () => {
    this.setState({
      isFull: !this.state.isFull,
    });
  };

  getSource = (url = "") => {
    if (url.includes("leboncoin")) {
      return "leboncoin";
    } else if (url.includes("lacentrale")) {
      return "lacentrale";
    } else if (url.includes("leparking")) {
      return "leparking";
    } else if (url.includes("autoscout24")) {
      return "autoscout24";
    } else if (url.includes("paruvendu")) {
      return "paruvendu";
    } else if (url.includes("largus")) {
      return "largus";
    } else if (url.includes("luxauto")) {
      return "luxauto";
    } else if (url.includes("mobile.de")) {
      return "mobile";
    } else {
      return "evalcar";
    }
  };

  getList = () => {
    if (this.props.ad && this.props.select) {
      return [
        {
          name: "price",
          title: "Prix",
          value: this.props.ad.price,
          valueEdit: this.props.select.price,
          formatText: getPrice,
        },
        {
          name: "brand",
          title: "Marque",
          value: this.props.ad.brand,
          valueEdit: this.props.select.brand,
        },
        {
          name: "model",
          title: "Modèle",
          value: this.props.ad.model,
          valueEdit: this.props.select.model,
        },
        {
          name: "date_circulation",
          title: "Mise en circulation",
          value: this.props.ad.date_circulation,
          valueEdit: this.props.select.date_circulation,
        },
        {
          name: "color",
          title: "Couleur",
          value: this.props.ad.color,
          valueEdit: this.props.select.color,
        },
        {
          name: "mileage",
          title: "Kilométrage",
          value: this.props.ad.mileage,
          formatText: getInt,
          valueEdit: this.props.select.mileage,
          extension: "km",
        },
        {
          name: "fuel",
          title: "Énergie",
          value: getFuelString(this.props.ad.fuel),
          valueEdit: this.props.select.fuel,
        },
        {
          name: "fiscal_power",
          title: "Puissance fiscale",
          value: this.props.ad.fiscal_power,
          valueEdit: this.props.select.fiscal_power,
          extension: "cv",
        },
        {
          name: "door",
          title: "Nombre de porte",
          value: this.props.ad.door,
          valueEdit: this.props.select.door,
        },
        {
          name: "source",
          title: "Source",
          value:
            this.props.ad.source === "evalcar"
              ? this.getSource(this.props.ad.url)
              : this.props.ad.source,
          valueEdit: this.props.select.source,
        },
        this.props.ad.country === "FR"
          ? {
              name: "region",
              title: "Région",
              value: getRegionFromDepartment(this.props.ad.department),
              valueEdit: this.props.select.region,
            }
          : {
              name: "country",
              title: "Pays",
              value: this.props.ad.country,
              valueEdit: this.props.select.country,
            },
        {
          name: "gearbox",
          title: "Boîte de vitesse",
          value: getGearboxString(this.props.ad.gearbox),
          valueEdit: this.props.select.gearbox,
        },
        {
          name: "zipcode",
          title: "Code postal",
          value: this.props.ad.zipcode,
          valueEdit: this.props.select.zipcode,
        },

        this.props.gender === 1
          ? {
              name: "displacement",
              title: "Cylindrée",
              value: this.props.ad.displacement,
              valueEdit: this.props.select.displacement,
            }
          : {},
        {
          name: "comment",
          title: "Commentaire de l'expert",
          value: this.props.ad.comment,
          valueEdit: this.props.select.comment,
          isFull: true,
          rows: 3,
        },
        {
          name: "description",
          title: "Description",
          value: this.props.ad.description,
          valueEdit: this.props.select.description,
          isFull: true,
          rows: 12,
          onClick: this.onClickFull,
        },
      ];
    } else {
      return [];
    }
  };

  render() {
    return (
      <div
        className="mt-10 mt-20-xl"
        style={{
          borderRadius: "10px",
          backgroundColor: "var(--white)",
          color: "var(--black)",
          boxShadow: "0 3px 6px 0 rgb(0 0 0 / 16%)",
        }}
      >
        <Row>
          <Col xs={12} md={3}>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                backgroundColor: "var(--gray)",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              {this.props.ad.image ? (
                <img
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    left: "50%",
                    top: "50%",
                    width: "100%",
                  }}
                  src={this.props.ad.image}
                  alt="car"
                />
              ) : null}
            </div>
          </Col>
          <Col xs={12} md={9}>
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
              className="p-20"
            >
              <Row>
                {this.getList().map((element, idx) => (
                  <Col
                    key={idx}
                    xs={12}
                    md={element.isFull ? 12 : 3}
                    className="mb-20"
                  >
                    <h2 className={`m-0 ${element.valueEdit ? "red" : ""}`}>
                      {element.title}
                    </h2>
                    <p
                      className={`m-0 ${
                        element.onClick ? "cursor-pointer" : ""
                      }`}
                      onClick={element.onClick}
                    >
                      {element.formatText
                        ? element.formatText(
                            element.valueEdit
                              ? element.valueEdit
                              : element.value
                          )
                        : element.valueEdit
                        ? element.valueEdit
                        : element.onClick && !this.state.isFull
                        ? `${
                            element.value ? element.value.substring(0, 200) : ""
                          }...`
                        : element.value}{" "}
                      {element.extension ? element.extension : ""}
                    </p>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
