import React from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  Button,
  FormInput,
  FormTextarea,
  FullModal,
  FormSelect,
  Loader,
} from "ui-kit-ck-consultant";
import moment from "moment";

import { addAd, getAdAuto } from "../../../requests/marketSearch";
import { getModel } from "../../../requests/general";

export default class ModalAddAd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      countGetAdAuto: 0,
      url: "",
      oldUrl: "",
      title: "",
      image: "",
      brand: "DEFAULT",
      model: "DEFAULT",
      mileage: "",
      date_circulation: "",
      date_index: "",
      zipcode: "",
      gearbox: "DEFAULT",
      fuel: "DEFAULT",
      price: "",
      description: "",
      brandOptions: [],
      modelOptions: null,
      fuelOptions: [
        { value: "DEFAULT", text: "Tous les carburants" },
        { value: 1, text: "Essence" },
        { value: 2, text: "Diesel" },
        { value: 3, text: "GPL" },
        { value: 4, text: "Electrique" },
        { value: 5, text: "Autre" },
        { value: 6, text: "Hybride" },
      ],
      gearboxOptions: [
        { value: "DEFAULT", text: "Toutes les boîtes de vitesse" },
        { value: 1, text: "Manuelle" },
        { value: 2, text: "Automatique" },
      ],
    };
  }

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && !this.state.brandOptions.length) {
      this.getBrandModel(() => {}, 0);
    }
  }

  getBrandModel = (callback, gender = null) => {
    getModel(gender, (result) => {
      if (result.success) {
        this.setState(
          {
            brandOptions: result.brands,
            brand: "DEFAULT",
            modelOptions: result.models,
            model: "DEFAULT",
          },
          callback
        );
      } else {
        this.setState(
          {
            brandOptions: [],
            brand: "DEFAULT",
            modelOptions: [],
            model: "DEFAULT",
          },
          callback
        );
      }
    });
  };

  handleClickAddAd = () => {
    if (
      this.state.title &&
      this.state.brand !== "DEFAULT" &&
      this.state.model !== "DEFAULT" &&
      this.state.mileage &&
      this.state.date_circulation &&
      this.state.date_index &&
      this.state.zipcode &&
      this.state.gearbox !== "DEFAULT" &&
      this.state.fuel !== "DEFAULT" &&
      this.state.price
    ) {
      addAd(
        {
          url: this.state.url,
          title: this.state.title,
          image: this.state.image,
          brand: this.state.brand,
          model: this.state.model,
          mileage: this.state.mileage,
          year: moment(this.state.date_circulation, "YYYY-MM-DD").format(
            "YYYY"
          ),
          date_circulation: this.state.date_circulation,
          date_index: this.state.date_index,
          zipcode: this.state.zipcode,
          gearbox: this.state.gearbox,
          fuel: this.state.fuel,
          price: this.state.price,
          description: this.state.description,
          gender: this.props.gender,
        },
        (result) => {
          if (result.success) {
            this.onClose(result.id);
          } else {
            alert("Une erreur est survenue");
          }
        }
      );
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  onClose = (id = null) => {
    this.setState(
      {
        url: "",
        title: "",
        image: "",
        brand: "DEFAULT",
        model: "DEFAULT",
        mileage: "",
        date_circulation: "",
        date_index: "",
        zipcode: "",
        gearbox: "DEFAULT",
        fuel: "DEFAULT",
        price: "",
        description: "",
      },
      () => {
        this.props.onClose(id);
      }
    );
  };

  getAdAuto = (isForce = false) => {
    if ((this.state.oldUrl !== this.state.url || isForce) && this.state.url) {
      this.setState(
        {
          isLoad: true,
          oldUrl: this.state.url,
        },
        () => {
          getAdAuto({ url: this.state.url }, (result) => {
            if (result.success) {
              this.setState({
                isLoad: false,
                title: result.data.title,
                image:
                  result.data.image && JSON.parse(result.data.image).length
                    ? JSON.parse(result.data.image)[0]
                    : "",
                brand: result.data.brand ? result.data.brand.toUpperCase() : "",
                model: result.data.model ? result.data.model.toUpperCase() : "",
                mileage: result.data.mileage,
                date_circulation: result.data.date_circulation,
                date_index: moment(result.data.date_index).format("YYYY-MM-DD"),
                zipcode: result.data.zipcode,
                gearbox: result.data.gearbox,
                fuel: result.data.fuel,
                price: result.data.price,
                description: result.data.description,
                countGetAdAuto: 0,
              });
            } else {
              if (this.state.countGetAdAuto >= 3) {
                this.setState({ isLoad: false }, () => {
                  alert("Récupération automatique non disponible");
                });
              } else {
                this.setState(
                  {
                    countGetAdAuto: this.state.countGetAdAuto + 1,
                  },
                  () => {
                    this.getAdAuto(true);
                  }
                );
              }
            }
          });
        }
      );
    }
  };

  render() {
    return (
      <>
        <FullModal
          title="Ajouter une annonce manuellement"
          show={this.props.show}
          onClose={() => this.onClose(null)}
        >
          <Row>
            <Col xs={12} md={3} className="mb-10">
              <FormInput
                title={"URL de l'annonce"}
                value={this.state.url}
                name="url"
                onChange={this.handleInputChange}
                onBlur={() => this.getAdAuto()}
              />
            </Col>
            <Col xs={12} md={3} className="mb-10">
              <FormInput
                required={true}
                title={"Titre de l'annonce"}
                value={this.state.title}
                name="title"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={2} className="mb-10">
              <FormInput
                title={"URL de l'image"}
                value={this.state.image}
                name="image"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={2} className="mb-10">
              <FormSelect
                required={true}
                ignore={true}
                onChange={this.handleInputChange}
                value={this.state.brand}
                options={this.state.brandOptions}
                title="Marque"
                name="brand"
              />
            </Col>
            <Col xs={12} md={2} className="mb-10">
              <FormSelect
                required={true}
                ignore={true}
                onChange={this.handleInputChange}
                value={this.state.model}
                options={
                  this.state.brand !== "DEFAULT" && this.state.modelOptions
                    ? this.state.modelOptions[this.state.brand]
                    : null
                }
                title="Modèle"
                name="model"
              />
            </Col>
            <Col xs={12} md={2} className="mb-10">
              <FormInput
                required={true}
                title={"Kilométrage"}
                value={this.state.mileage}
                name="mileage"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={2} className="mb-10">
              <FormInput
                required={true}
                type="date"
                title={"Date mise en circulation"}
                value={this.state.date_circulation}
                name="date_circulation"
                placeholder="dd/mm/yyyy"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={2} className="mb-10">
              <FormInput
                required={true}
                type="date"
                title={"Date d'ajout"}
                value={this.state.date_index}
                name="date_index"
                placeholder="dd/mm/yyyy"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={2} className="mb-10">
              <FormInput
                required={true}
                title={"Code postal"}
                value={this.state.zipcode}
                name="zipcode"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={2} className="mb-10">
              <FormSelect
                required={true}
                ignore={true}
                onChange={this.handleInputChange}
                value={this.state.gearbox}
                options={this.state.gearboxOptions}
                title="Boite de vitesse"
                name="gearbox"
              />
            </Col>
            <Col xs={12} md={2} className="mb-10">
              <FormSelect
                required={true}
                ignore={true}
                onChange={this.handleInputChange}
                value={this.state.fuel}
                options={this.state.fuelOptions}
                title="Carburant"
                name="fuel"
              />
            </Col>
            <Col xs={12} md={2} className="mb-10">
              <FormInput
                required={true}
                title={"Prix"}
                value={this.state.price}
                name="price"
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} className="mb-10">
              <FormTextarea
                title={"Description"}
                value={this.state.description}
                name="description"
                onChange={this.handleInputChange}
                rows={6}
              />
            </Col>
            <Col xs={12} md={4} />
            <Col xs={12} md={4} className="mb-10">
              {this.state.oldUrl === this.state.url && this.state.url ? (
                <Button
                  text="Actualiser la récupération automatique"
                  onClick={() => this.getAdAuto(true)}
                />
              ) : null}
            </Col>
            <Col xs={12} md={4} className="mb-10">
              <Button
                text="Ajouter l'annonce"
                onClick={this.handleClickAddAd}
              />
            </Col>
          </Row>
        </FullModal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
