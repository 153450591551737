const regions = {
  "grand-est": {
    departments: ["67", "68", "54", "55", "57", "88", "08", "10", "51", "52"],
  },
  "hauts-de-france": {
    departments: ["59", "62", "02", "60", "80"],
  },
  "ile-de-france": {
    departments: ["75", "77", "78", "91", "92", "93", "94", "95"],
  },
  "rhone-alpes": {
    departments: [
      "01",
      "07",
      "26",
      "38",
      "42",
      "69",
      "73",
      "74",
      "03",
      "15",
      "43",
      "63",
    ],
  },
  "nouvelle-aquitaine": {
    departments: [
      "24",
      "33",
      "40",
      "47",
      "64",
      "19",
      "23",
      "87",
      "16",
      "17",
      "79",
      "86",
    ],
  },
  provence: {
    departments: ["04", "05", "06", "13", "83", "84"],
  },
  occitanie: {
    departments: [
      "09",
      "12",
      "31",
      "32",
      "46",
      "65",
      "81",
      "82",
      "11",
      "30",
      "34",
      "48",
      "66",
    ],
  },
  "pays-de-la-loire": {
    departments: ["44", "49", "53", "72", "85"],
  },
  bourgogne: {
    departments: ["21", "58", "71", "89", "25", "39", "70", "90"],
  },
  normandie: {
    departments: ["27", "76", "14", "50", "61"],
  },
  bretagne: {
    departments: ["22", "29", "35", "56"],
  },
  "centre-val-de-loire": {
    departments: ["18", "28", "36", "37", "41", "45"],
  },
  "guadeloupe": {
    departments: ["971"],
  },
  "martinique": {
    departments: ["972"],
  },
  "guyane": {
    departments: ["973"],
  },
  "la-reunion": {
    departments: ["974"],
  },
};

const getRegionFromDepartment = (department) => {
  let region = null;

  Object.keys(regions).map((key) => {
    if (
      department &&
      regions[key].departments.includes(department.toString())
    ) {
      region = key;
    }
    return null;
  });

  return region;
};

module.exports = { regions, getRegionFromDepartment };
