const inputAdministrativeIdentification = [
  {
    required1: false,
    required2: false,
    name: "Titre de l'assuré",
    value: "Titre",
  },
  { required1: false, required2: false, name: "Nom de l'assuré", value: "Nom" },
  {
    required1: false,
    required2: false,
    name: "Code postal assuré",
    value: "Code_Postal",
  },
  { required1: false, required2: false, name: "Assurance", value: "Compagnie" },
  {
    required1: true,
    print1: true,
    required2: true,
    type: "date",
    name: "Date de sinistre",
    value: "Date_Sinistre",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "N° du sinistre",
    value: "Numero_Sinistre",
  },
  {
    required1: false,
    required2: false,
    name: "N° de formule",
    value: "Numero_Formule",
  },
];

const inputVehicleIdentification = [
  {
    required1: true,
    print1: false,
    required2: true,
    name: "Genre du véhicule",
    value: "Genre",
    options: [
      { value: "MTL", text: "MTL" },
      { value: "MTT1", text: "MTT1" },
      { value: "MTT2", text: "MTT2" },
      { value: "TM", text: "TM" },
      { value: "QM", text: "QM" },
      { value: "CYCL", text: "CYCL" },
      { value: "CL", text: "CL" },
      { value: "VP", text: "VP" },
      { value: "TCP", text: "TCP" },
      { value: "TRR", text: "TRR" },
      { value: "CTTE", text: "CTTE" },
      { value: "CAM", text: "CAM" },
      { value: "SRAT", text: "SRAT" },
      { value: "SREM", text: "SREM" },
      { value: "REM", text: "REM" },
      { value: "SRTC", text: "SRTC" },
      { value: "RETC", text: "RETC" },
      { value: "VASP", text: "VASP" },
      { value: "SRSP", text: "SRSP" },
      { value: "RESP", text: "RESP" },
      { value: "TRA", text: "TRA" },
      { value: "REA", text: "REA" },
      { value: "SREA", text: "SREA" },
      { value: "MAGA", text: "MAGA" },
      { value: "MIAR", text: "MIAR" },
      { value: "CARA", text: "CARA" },
      { value: "TMP1", text: "TMP1" },
      { value: "TMP2", text: "TMP2" },
      { value: "TMM1", text: "TMM1" },
      { value: "TMM2", text: "TMM2" },
    ],
  },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "Marque",
    value: "Constructeur",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "Modèle",
    value: "Modele",
  },
  { required1: false, required2: false, name: "Finition", value: "Finition" },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "Immatriculation",
    value: "Immat",
  },
  {
    required1: true,
    print1: false,
    required2: true,
    name: "Carrosserie",
    value: "Carrosserie",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "Énergie",
    value: "Energie",
    options: [
      { value: "NC", text: "Sans énergie" },
      { value: "ES", text: "Essence" },
      { value: "GO", text: "Diesel" },
      { value: "HY", text: "Hybride" },
      { value: "EL", text: "Électrique" },
    ],
  },
  {
    required1: false,
    required2: true,
    name: "Nombre de place",
    value: "Nb_Places",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "Couleur",
    value: "Couleur",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "N° de série",
    value: "VIN",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "Type Mine",
    value: "Type_Mine",
  },
  {
    required1: false,
    required2: true,
    name: "Puissance fiscale",
    value: "Puissance_Fiscale",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "État",
    value: "Etat_General",
    options: [
      { value: "B", text: "Bon" },
      { value: "N", text: "Normal" },
      { value: "M", text: "Mauvais" },
      { value: "MA", text: "Mauvais" },
      { value: "D", text: "Dangereux" },
      { value: "MO", text: "Moyen" },
    ],
  },
  {
    required1: true,
    print1: true,
    required2: true,
    type: "year",
    name: "Année modèle",
    value: "Annee_Modele",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "Kilométrage",
    value: "Kms",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    type: "date",
    name: "Date de contrôle technique",
    value: "DateDernierCT",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    type: "date",
    name: "Date de mise en circulation",
    value: "Date1MEC",
  },
  {
    required1: true,
    print1: true,
    required2: true,
    name: "Catégorie",
    value: "Categorie",
    options: [
      { value: "(1/A) Petits Véhicules", text: "(1/A) Petits Véhicules" },
      {
        value: "(2/B) Gamme Moyenne Inférieure",
        text: "(2/B) Gamme Moyenne Inférieure",
      },
      {
        value: "(3/C) Gamme Moyenne Supérieure",
        text: "(3/C) Gamme Moyenne Supérieure",
      },
      { value: "(4/D) Gamme Haute", text: "(4/D) Gamme Haute" },
      {
        value: "(7/G) Véhicule de Société Dérivé de V.L",
        text: "(7/G) Véhicule de Société Dérivé de V.L",
      },
      {
        value: "(8/H) Véhicule Utilitaire Léger",
        text: "(8/H) Véhicule Utilitaire Léger",
      },
      { value: "(9/J) 4x4", text: "(9/J) 4x4" },
      { value: "(10/K) Motos < 50cc", text: "(10/K) Motos < 50cc" },
      { value: "(11/L) Motos 50 >< 125cc", text: "(10/L) Motos 50 >< 125" },
      { value: "(12/M) Motos > 125", text: "(10/M) Motos > 125" },
    ],
  },
];

export { inputAdministrativeIdentification, inputVehicleIdentification };
