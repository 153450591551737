import React, { createRef } from "react";
import { Modal, Button } from "ui-kit-ck-consultant";
import SignatureCanvas from "react-signature-canvas";

export default class DescriptiveStateSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 200,
      width: 200,
    };

    this.signatureRef = createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.setSizeSignature();
    }
  }

  setSizeSignature = () => {
    if (
      this.signatureRef.current &&
      this.state.width !== this.signatureRef.current.getCanvas().offsetWidth
    ) {
      this.setState({
        width: this.signatureRef.current.getCanvas().offsetWidth,
      });
    }
  };

  handleClearSignatureClick = () => {
    this.signatureRef.current.clear();
  };

  handleValidSignatureClick = () => {
    let image = this.signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    this.props.onValid(image);
  };

  render() {
    return (
      <Modal
        title="Signature"
        show={this.props.show}
        onClose={this.props.onClose}
      >
        <SignatureCanvas
          ref={this.signatureRef}
          penColor="black"
          canvasProps={{
            height: this.state.height,
            width: this.state.width,
            style: {
              border: "var(--gray) dashed 2px",
              borderRadius: "15px",
            },
            className: "sigCanvas mb-10",
          }}
        />
        <Button
          text="Rénitialiser"
          className="m-0 mb-10 outline"
          onClick={this.handleClearSignatureClick}
        />
        <Button
          text="Valider"
          className="m-0"
          onClick={this.handleValidSignatureClick}
        />
      </Modal>
    );
  }
}
