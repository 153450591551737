let tyreOptions = {};

const getPrices = (price) => {
  let prices = {
    0: ["0%", calculatePrice(price)],
    1: ["1%", calculatePrice(price, 0.95)],
    2: ["5%", calculatePrice(price, 0.9)],
    3: ["10%", calculatePrice(price, 0.8)],
    4: ["15%", calculatePrice(price, 0.7)],
    5: ["20%", calculatePrice(price, 0.6)],
    6: ["25%", calculatePrice(price, 0)],
    7: ["30%", calculatePrice(price, 0)],
    8: ["35%", calculatePrice(price, 0)],
    9: ["40%", calculatePrice(price, 0)],
    10: ["45%", calculatePrice(price, 0)],
    11: ["50%", 0],
    12: ["55%", -1 * calculatePrice(price, 0)],
    13: ["60%", -1 * calculatePrice(price, 0)],
    14: ["65%", -1 * calculatePrice(price, 0)],
    15: ["70%", -1 * calculatePrice(price, 0)],
    16: ["75%", -1 * calculatePrice(price, 0)],
    17: ["80%", -1 * calculatePrice(price, 0.6)],
    18: ["85%", -1 * calculatePrice(price, 0.7)],
    19: ["90%", -1 * calculatePrice(price, 0.8)],
    20: ["95%", -1 * calculatePrice(price, 0.9)],
    21: ["100%", -1 * calculatePrice(price)],
  };

  return prices;
};

const calculatePrice = (price, percent = 1) => {
  return (price * percent) - (price * percent % 5)
}

const setTyrePrices = (category) => {
  switch (category) {
    case "(1/A) Petits Véhicules":
      return getPrices(50)
    case "(2/B) Gamme Moyenne Inférieure":
      return getPrices(60)
    case "(3/C) Gamme Moyenne Supérieure":
      return getPrices(80)
    case "(4/D) Gamme Haute":
      return getPrices(100)
    case "(7/G) Véhicule de Société Dérivé de V.L":
      return getPrices(60)
    case "(8/H) Véhicule Utilitaire Léger":
      return getPrices(80)
    case "(9/J) 4x4":
      return getPrices(100)
    case "(10/K) Motos < 50cc":
      return getPrices(20)
    case "(11/L) Motos 50 >< 125cc":
      return getPrices(40)
    case "(12/M) Motos > 125":
      return getPrices(60)
    default:
      break;
  }
};

let technicalControlOptions = {
  0: ["Valide", 0],
  1: ["Dépassé", -150],
};

let type1Options = {
  0: ["Normal", 0],
  1: ["Fuite", -50],
  2: ["Éléments absents", -50],
  3: ["Autres", 0],
  4: ["Non récent", -50],
  5: ["Récent", 50],
};

let type2Options = {
  0: ["Normal", 0],
  1: ["Fuites", -50],
  2: ["Oxydé", -50],
  3: ["Déformé", -50],
  4: ["Autres", 0],
  5: ["Non récent", -50],
  6: ["Récent", 50],
};

let type3Options = {
  0: ["Normal", 0],
  1: ["À remplacer", -150],
  2: ["Autres", 0],
  3: ["Non récent", -50],
  4: ["Récent", 50],
};

let type4Options = {
  0: ["Normal", 0],
  1: ["Usure importante", -50],
  2: ["Autres", 0],
  3: ["Non récent", -50],
  4: ["Récent", 50],
};

let type5Options = {
  0: ["Normal", 0],
  1: ["Usée", -50],
  2: ["Absent", -50],
  3: ["Autres", 0],
  4: ["Non récent", -50],
  5: ["Récent", 50],
};

let type6Options = {
  0: ["Maximum", 0],
  1: ["Normal", 0],
  2: ["Minimum", 0],
  3: ["Faible", 0],
  4: ["Autres", 0],
};

let type7Options = {
  0: ["Normal", 0],
  1: ["Oxydé", -50],
  2: ["Déformé", -50],
  3: ["Rayé", -50],
  4: ["Fissuré", -50],
  5: ["Défaut de peinture", -50],
  6: ["Réparation antérieure", -50],
  7: ["Absent", -50],
  8: ["Autres", 0],
  9: ["Non récent", -50],
  10: ["Récent", 50],
};

let type8Options = {
  0: ["Normal", 0],
  1: ["Rayé", -50],
  2: ["Impact", -50],
  3: ["Troué", -50],
  4: ["Brisé", -50],
  5: ["Autres", 0],
  6: ["Non récent", -50],
  7: ["Récent", 50],
};

let type9Options = {
  0: ["Présent", 0],
  1: ["Incomplet", -50],
  2: ["Absent", -50],
  3: ["Autres", 0],
};

let type10Options = {
  0: ["Normal", 0],
  1: ["Oxydé", -50],
  2: ["Déformé", -50],
  3: ["Fissuré", -50],
  4: ["Absent", -50],
  5: ["Autres", 0],
  6: ["Non récent", -50],
  7: ["Récent", 50],
};

let type11Options = {
  0: ["Normal", 0],
  1: ["Déchiré", -50],
  2: ["Taché", -50],
  3: ["Perforé", -50],
  4: ["Usé", -50],
  5: ["Griffé", -50],
  6: ["Absent", -50],
  7: ["Autres", 0],
  8: ["Non récent", -50],
  9: ["Récent", 50],
};

let type12Options = {
  0: ["Normal", 0],
  1: ["Moteur", -50],
  2: ["Airbag", -50],
  3: ["ABS/ESP", -50],
  4: ["Frein", -50],
  5: ["Antipollution", -50],
  6: ["Autres", 0],
  7: ["Non récent", -50],
  8: ["Récent", 50],
};

let type13Options = {
  0: ["Normal", 0],
  1: ["Déchiré", -50],
  2: ["Absent", -50],
  3: ["Autres", 0],
  4: ["Non récent", -50],
  5: ["Récent", 50],
};

let type14Options = {
  0: ["Normal", 0],
  1: ["À remplacer", -50],
  2: ["Absent", -50],
  3: ["Autres", 0],
  4: ["Non récent", -50],
  5: ["Récent", 50],
};

let type15Options = {
  0: ["Normal", 0],
  1: ["Anormal", -50],
};

let type16Options = {
  0: ["Normal", 0],
  1: ["Déformé", -50],
  2: ["À remplacer", -50],
  3: ["Absent", -50],
  4: ["Autres", 0],
  5: ["Non récent", -50],
  6: ["Récent", 50],
};

let type17Options = {
  0: ["Normal", 0],
  1: ["Fuite", -50],
  2: ["Déformé", -50],
  3: ["Absent", -50],
  4: ["Autres", 0],
  5: ["Non récent", -50],
  6: ["Récent", 50],
};

let type18Options = {
  0: ["Normal", 0],
  1: ["Oxydé", -50],
  2: ["Déformé", -50],
  3: ["Fissuré", -50],
  4: ["Cassé", -50],
  5: ["Absent", -50],
  6: ["Autres", 0],
  7: ["Non récent", -50],
  8: ["Récent", 50],
};

let type19Options = {
  0: ["Normal", 0],
  1: ["Oxydé", -50],
  2: ["Rayé", -50],
  3: ["Déformé", -50],
  4: ["Fissuré", -50],
  5: ["Défaut de peinture", -50],
  6: ["Réparation antérieure", -50],
  7: ["Absent", -50],
  8: ["Autres", 0],
  9: ["Non récent", -50],
  10: ["Récent", 50],
};

let type20Options = {
  0: ["Présent", 0],
  1: ["Absent", -50],
  2: ["Autres", 0],
};

let type21Options = {
  0: ["Normal", 0],
  1: ["Rayé", -50],
  2: ["Déformé", -50],
  3: ["Défaut de peinture", -50],
  4: ["Réparation antérieure", -50],
  5: ["Absent", -50],
  6: ["Autres", 0],
  7: ["Non récent", -50],
  8: ["Récent", 50],
};

let type22Options = {
  0: ["Normal", 0],
  1: ["Rayé", -50],
  2: ["Fissuré", -50],
  3: ["Déformé", -50],
  4: ["Défaut de peinture", -50],
  5: ["Réparation antérieure", -50],
  6: ["Absent", -50],
  7: ["Autres", 0],
  8: ["Non récent", -50],
  9: ["Récent", 50],
};

let type23Options = {
  0: ["Normal", 0],
  1: ["Déformé", -50],
  2: ["Rayé", -50],
  3: ["À remplacer", -50],
  4: ["Absent", -50],
  5: ["Autres", 0],
  6: ["Non récent", -50],
  7: ["Récent", 50],
};

let type24Options = {
  0: ["Normal", 0],
  1: ["Déformé", -50],
  2: ["Rayé", -50],
  3: ["Cassé", -50],
  4: ["À remplacer", -50],
  5: ["Absent", -50],
  6: ["Autres", 0],
  7: ["Non récent", -50],
  8: ["Récent", 50],
};

let type25Options = {
  0: ["Normal", 0],
  1: ["Déformé", -50],
  2: ["Rayé", -50],
  3: ["Fissuré", -50],
  4: ["Absent", -50],
  5: ["Autres", 0],
  6: ["Non récent", -50],
  7: ["Récent", 50],
};

let type26Options = {
  0: ["Normal", 0],
  1: ["Déformé", -50],
  2: ["Rayé", -50],
  3: ["Fissuré", -50],
  4: ["Défaut de peinture", -50],
  5: ["Réparation antérieure", -50],
  6: ["Oxydé", -50],
  7: ["Absent", -50],
  8: ["Autres", 0],
  9: ["Non récent", -50],
  10: ["Récent", 50],
};

let type27Options = {
  0: ["Normal", 0],
  1: ["Déformé", -50],
  2: ["Rayé", -50],
  3: ["Déchiré", -50],
  4: ["Absent", -50],
  5: ["Autres", 0],
  6: ["Non récent", -50],
  7: ["Récent", 50],
};

let type28Options = {
  0: ["Normal", 0],
  1: ["Déformé", -50],
  2: ["Fuite", -50],
  3: ["Absent", -50],
  4: ["Autres", 0],
  5: ["Non récent", -50],
  6: ["Récent", 50],
};

let type29Options = {
  0: ["Normal", 0],
  1: ["Oxydé", -50],
  2: ["Déformé", -50],
  3: ["Rayé", -50],
  4: ["Fissuré", -50],
  5: ["Absent", -50],
  6: ["Autres", 0],
  7: ["Non récent", -50],
  8: ["Récent", 50],
};

const getParts = (type, gender, category) => {
  tyreOptions = setTyrePrices(category)
  
  let carParts = {
    "CP-1": {
      name: "Contrôle technique",
      main: "technical_control",
      type: "technical_control",
      others: ["technical_control"],
      options: technicalControlOptions,
    },
    "CP-2": {
      name: "Pneu avant gauche",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-3": {
      name: "Pneu avant droit",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-4": {
      name: "Pneu arrière gauche",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-5": {
      name: "Pneu arrière droit",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-6": {
      name: "Moteur",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type1Options,
    },
    "CP-7": {
      name: "Amortisseur avant G",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type2Options,
    },
    "CP-8": {
      name: "Amortisseur avant D",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type2Options,
    },
    "CP-9": {
      name: "Distribution",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type1Options,
    },
    "CP-10": {
      name: "boite de vitesses",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type1Options,
    },
    "CP-11": {
      name: "Embrayage",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type4Options,
    },
    "CP-12": {
      name: "Batterie",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type5Options,
    },
    "CP-13": {
      name: "Niveau huile moteur",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type6Options,
    },
    "CP-14": {
      name: "Niveau liquide de freins",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type6Options,
    },
    "CP-15": {
      name: "Niveau liquide de refroidissement",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type6Options,
    },
    "CP-16": {
      name: "Jante avant G",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type7Options,
    },
    "CP-17": {
      name: "Jante avant D",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type7Options,
    },
    "CP-18": {
      name: "Frein avant",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type3Options,
    },
    "CP-19": {
      name: "Capot avant",
      main: "front",
      type: "body",
      others: ["front"],
      options: type7Options,
    },
    "CP-20": {
      name: "Bouclier avant",
      main: "front",
      type: "body",
      others: ["front"],
      options: type7Options,
    },
    "CP-21": {
      name: "Optique avant G",
      main: "front",
      type: "body",
      others: ["front"],
      options: type8Options,
    },
    "CP-22": {
      name: "Optique avant D",
      main: "front",
      type: "body",
      others: ["front"],
      options: type8Options,
    },
    "CP-23": {
      name: "Antibrouillard avant G",
      main: "front",
      type: "body",
      others: ["front"],
      options: type8Options,
    },
    "CP-24": {
      name: "Antibrouillard avant D",
      main: "front",
      type: "body",
      others: ["front"],
      options: type8Options,
    },
    "CP-25": {
      name: "Aile avant G",
      main: "front",
      type: "body",
      others: ["front"],
      options: type7Options,
    },
    "CP-26": {
      name: "Aile avant D",
      main: "front",
      type: "body",
      others: ["front"],
      options: type7Options,
    },
    "CP-27": {
      name: "Pare-brise",
      main: "front",
      type: "body",
      others: ["front"],
      options: type8Options,
    },
    "CP-28": {
      name: "Kit roue de secours",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type9Options,
    },
    "CP-29": {
      name: "Échappement",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type10Options,
    },
    "CP-30": {
      name: "Frein arrière",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type3Options,
    },
    "CP-31": {
      name: "Amortisseur arrière G",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type2Options,
    },
    "CP-32": {
      name: "Amortisseur arrière D",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type2Options,
    },
    "CP-33": {
      name: "Jante arrière G",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type7Options,
    },
    "CP-34": {
      name: "Jante arrière D",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type7Options,
    },
    "CP-35": {
      name: "Bouclier arrière",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type7Options,
    },
    "CP-36": {
      name: "Hayon / Male",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type7Options,
    },
    "CP-37": {
      name: "Coffre",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type11Options,
    },
    "CP-38": {
      name: "Feu arrière G",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type8Options,
    },
    "CP-39": {
      name: "Feu arrière D",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type8Options,
    },
    "CP-40": {
      name: "Antibrouillard arrière G",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type8Options,
    },
    "CP-41": {
      name: "Antibrouillard arrière D",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type8Options,
    },
    "CP-42": {
      name: "Aile arrière G",
      main: "rear",
      type: "body",
      others: ["rear", "left_side"],
      options: type7Options,
    },
    "CP-43": {
      name: "Aile arrière D",
      main: "rear",
      type: "body",
      others: ["rear", "right_side"],
      options: type7Options,
    },
    "CP-44": {
      name: "Plancher arrière",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type7Options,
    },
    "CP-45": {
      name: "Lunette arrière",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type8Options,
    },
    "CP-46": {
      name: "Voyant",
      main: "interior",
      type: "mechanics",
      others: ["interior"],
      options: type12Options,
    },
    "CP-47": {
      name: "Planche de bord",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-48": {
      name: "Volant",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-49": {
      name: "Levier de vitesses",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-50": {
      name: "Airbag conducteur",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-51": {
      name: "Airbag passager",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-52": {
      name: "Airbag genoux latéral G",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-53": {
      name: "Airbag genoux latéral D",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-54": {
      name: "Ceinture avant G",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-55": {
      name: "Ceinture avant D",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-56": {
      name: "Ceinture arrière G",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-57": {
      name: "Ceinture arrière D",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-58": {
      name: "Siège avant G",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-59": {
      name: "Siège avant D",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-60": {
      name: "Siège arrière G",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-61": {
      name: "Siege arrière D",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-62": {
      name: "Pavillon",
      main: "roof",
      type: "body",
      others: ["roof"],
      options: type7Options,
    },
    "CP-63": {
      name: "Toit ouvrant",
      main: "roof",
      type: "body",
      others: ["roof"],
      options: type8Options,
    },
    "CP-64": {
      name: "Capote",
      main: "roof",
      type: "body",
      others: ["roof"],
      options: type8Options,
    },
    "CP-65": {
      name: "Porte avant G",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type7Options,
    },
    "CP-66": {
      name: "Porte arrière G",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type7Options,
    },
    "CP-67": {
      name: "Vitre avant G",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type8Options,
    },
    "CP-68": {
      name: "Vitre arrière G",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type8Options,
    },
    "CP-69": {
      name: "Garniture avant G",
      main: "left_side",
      type: "cockpit",
      others: ["left_side"],
      options: type11Options,
    },
    "CP-70": {
      name: "Garniture arrière G",
      main: "left_side",
      type: "cockpit",
      others: ["left_side"],
      options: type11Options,
    },
    "CP-71": {
      name: "Bas de caisse G",
      main: "left_side",
      type: "cockpit",
      others: ["left_side"],
      options: type7Options,
    },
    "CP-72": {
      name: "Porte avant D",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type7Options,
    },
    "CP-73": {
      name: "Porte arrière D",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type7Options,
    },
    "CP-74": {
      name: "Vitre avant D",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type8Options,
    },
    "CP-75": {
      name: "Vitre arrière D",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type8Options,
    },
    "CP-76": {
      name: "Garniture avant D",
      main: "right_side",
      type: "cockpit",
      others: ["right_side"],
      options: type11Options,
    },
    "CP-77": {
      name: "Garniture arrière D",
      main: "right_side",
      type: "cockpit",
      others: ["right_side"],
      options: type11Options,
    },
    "CP-78": {
      name: "Bas de caisse D",
      main: "right_side",
      type: "cockpit",
      others: ["right_side"],
      options: type7Options,
    },
    "CP-79": {
      name: "Rétroviseur G",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type7Options,
    },
    "CP-80": {
      name: "Rétroviseur D",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type7Options,
    },
  };
  
  let bikeParts = {
    "CP-1": {
      name: "Fourche",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type17Options,
    },
    "CP-2": {
      name: "Fourreau g",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type2Options,
    },
    "CP-3": {
      name: "Fourreau d",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type2Options,
    },
    "CP-4": {
      name: "Tube d",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type2Options,
    },
    "CP-5": {
      name: "Tube g",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type2Options,
    },
    "CP-6": {
      name: "Té de fourche sup",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type18Options,
    },
    "CP-7": {
      name: "Té de fourche inf",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type18Options,
    },
    "CP-8": {
      name: "Moteur",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type17Options,
    },
    "CP-9": {
      name: "Jeu aux soupapes",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type15Options,
    },
    "CP-10": {
      name: "Distribution",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type14Options,
    },
    "CP-11": {
      name: "boite de vitesses",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type1Options,
    },
    "CP-12": {
      name: "Embrayage",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type1Options,
    },
    "CP-13": {
      name: "Batterie",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type5Options,
    },
    "CP-14": {
      name: "Niveau huile moteur",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type6Options,
    },
    "CP-15": {
      name: "Niveau liquide de freins",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type6Options,
    },
    "CP-16": {
      name: "Niveau liquide de refroidissement",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type6Options,
    },
    "CP-17": {
      name: "Jante avant",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type19Options,
    },
    "CP-18": {
      name: "Frein avant",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type3Options,
    },
    "CP-19": {
      name: "Jante arriere",
      main: "saddle",
      type: "mechanics",
      others: ["saddle", "exhaust"],
      options: type19Options,
    },
    "CP-20": {
      name: "Frein arrière",
      main: "saddle",
      type: "mechanics",
      others: ["saddle", "exhaust"],
      options: type3Options,
    },
    "CP-21": {
      name: "suspension ard",
      main: "saddle",
      type: "mechanics",
      others: ["saddle", "exhaust"],
      options: type1Options,
    },
    "CP-22": {
      name: "suspension arg",
      main: "saddle",
      type: "mechanics",
      others: ["saddle", "exhaust"],
      options: type1Options,
    },
    "CP-23": {
      name: "bras oscillant",
      main: "saddle",
      type: "mechanics",
      others: ["saddle", "exhaust"],
      options: type16Options,
    },
    "CP-24": {
      name: "Kit chaine",
      main: "saddle",
      type: "mechanics",
      others: ["saddle", "exhaust"],
      options: type5Options,
    },
    "CP-25": {
      name: "clé rouge",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type20Options,
    },
    "CP-26": {
      name: "Tête de fourche",
      main: "front",
      type: "body",
      others: ["front"],
      options: type21Options,
    },
    "CP-27": {
      name: "Flanc avd",
      main: "front",
      type: "body",
      others: ["front"],
      options: type22Options,
    },
    "CP-28": {
      name: "Flanc avg",
      main: "front",
      type: "body",
      others: ["front"],
      options: type22Options,
    },
    "CP-29": {
      name: "garde boue av",
      main: "front",
      type: "body",
      others: ["front"],
      options: type22Options,
    },
    "CP-30": {
      name: "Rétro g",
      main: "front",
      type: "body",
      others: ["front", "fuel_tank"],
      options: type23Options,
    },
    "CP-31": {
      name: "Rétro d",
      main: "front",
      type: "body",
      others: ["front", "fuel_tank"],
      options: type23Options,
    },
    "CP-32": {
      name: "Levier g",
      main: "front",
      type: "body",
      others: ["front", "fuel_tank"],
      options: type24Options,
    },
    "CP-33": {
      name: "Levier d",
      main: "front",
      type: "body",
      others: ["front", "fuel_tank"],
      options: type24Options,
    },
    "CP-34": {
      name: "Carter g",
      main: "left_side",
      type: "mechanics",
      others: ["left_side"],
      options: type24Options,
    },
    "CP-35": {
      name: "Carter d",
      main: "right_side",
      type: "mechanics",
      others: ["right_side"],
      options: type24Options,
    },
    "CP-36": {
      name: "Sabot",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type22Options,
    },
    "CP-37": {
      name: "Carénage ard",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type22Options,
    },
    "CP-38": {
      name: "Carénage arg",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type22Options,
    },
    "CP-39": {
      name: "Bulle",
      main: "front",
      type: "body",
      others: ["front"],
      options: type22Options,
    },
    "CP-40": {
      name: "Coque ar",
      main: "saddle",
      type: "body",
      others: ["saddle", "exhaust"],
      options: type22Options,
    },
    "CP-41": {
      name: "Repose pied avg",
      main: "left_side",
      type: "mechanics",
      others: ["left_side"],
      options: type24Options,
    },
    "CP-42": {
      name: "Repose pied avd",
      main: "right_side",
      type: "mechanics",
      others: ["right_side"],
      options: type24Options,
    },
    "CP-43": {
      name: "Repose pied arg",
      main: "left_side",
      type: "mechanics",
      others: ["left_side"],
      options: type24Options,
    },
    "CP-44": {
      name: "Repose pied ard",
      main: "right_side",
      type: "mechanics",
      others: ["right_side"],
      options: type24Options,
    },
    "CP-45": {
      name: "Feu ar",
      main: "saddle",
      type: "body",
      others: ["saddle", "exhaust"],
      options: type25Options,
    },
    "CP-46": {
      name: "Optique av",
      main: "front",
      type: "body",
      others: ["front"],
      options: type25Options,
    },
    "CP-47": {
      name: "Optique avd",
      main: "front",
      type: "body",
      others: ["front"],
      options: type25Options,
    },
    "CP-48": {
      name: "Optique avg",
      main: "front",
      type: "body",
      others: ["front"],
      options: type25Options,
    },
    "CP-49": {
      name: "Sélecteur vitesses",
      main: "left_side",
      type: "mechanics",
      others: ["left_side", "fuel_tank", "rear"],
      options: type24Options,
    },
    "CP-50": {
      name: "Pédale de frein",
      main: "right_side",
      type: "mechanics",
      others: ["right_side", "fuel_tank", "rear"],
      options: type24Options,
    },
    "CP-51": {
      name: "Garde boue ar",
      main: "saddle",
      type: "body",
      others: ["saddle", "exhaust"],
      options: type22Options,
    },
    "CP-52": {
      name: "Cadre",
      main: "front",
      type: "body",
      others: ["front"],
      options: type26Options,
    },
    "CP-53": {
      name: "Butée cadre",
      main: "front",
      type: "body",
      others: ["front"],
      options: type26Options,
    },
    "CP-54": {
      name: "Poignée avd",
      main: "front",
      type: "body",
      others: ["front", "fuel_tank"],
      options: type27Options,
    },
    "CP-55": {
      name: "Poignée avg",
      main: "front",
      type: "body",
      others: ["front", "fuel_tank"],
      options: type27Options,
    },
    "CP-56": {
      name: "Poignée ard",
      main: "saddle",
      type: "body",
      others: ["saddle", "exhaust"],
      options: type27Options,
    },
    "CP-57": {
      name: "Poignée arg",
      main: "saddle",
      type: "body",
      others: ["saddle", "exhaust"],
      options: type27Options,
    },
    "CP-58": {
      name: "Radiateur",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type28Options,
    },
    "CP-59": {
      name: "Tube echappement",
      main: "exhaust",
      type: "mechanics",
      others: ["exhaust"],
      options: type29Options,
    },
    "CP-60": {
      name: "Silencieux",
      main: "exhaust",
      type: "mechanics",
      others: ["exhaust"],
      options: type29Options,
    },
    "CP-61": {
      name: "Protection échappement",
      main: "exhaust",
      type: "mechanics",
      others: ["exhaust"],
      options: type29Options,
    },
    "CP-62": {
      name: "Selle avant",
      main: "saddle",
      type: "body",
      others: ["saddle", "exhaust"],
      options: type29Options,
    },
    "CP-63": {
      name: "Selle arrière",
      main: "saddle",
      type: "body",
      others: ["saddle", "exhaust"],
      options: type29Options,
    },
    "CP-64": {
      name: "Réservoir",
      main: "fuel_tank",
      type: "mechanics",
      others: ["fuel_tank"],
      options: type29Options,
    },
    "CP-65": {
      name: "Bouchon de réservoir",
      main: "fuel_tank",
      type: "mechanics",
      others: ["fuel_tank"],
      options: type29Options,
    },
    "CP-66": {
      name: "Guidon",
      main: "front",
      type: "body",
      others: ["front", "fuel_tank"],
      options: type27Options,
    },
    "CP-67": {
      name: "Pneu avant",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-68": {
      name: "Pneu arrière",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-69": {
      name: "Jante avant",
      main: "tyre",
      type: "mechanics",
      others: ["tyre"],
      options: type7Options,
    },
    "CP-70": {
      name: "Jante arrière",
      main: "tyre",
      type: "mechanics",
      others: ["tyre"],
      options: type7Options,
    },
    "CP-71": {
      name: "Flanc ARD",
      main: "saddle",
      type: "body",
      others: ["saddle", "exhaust"],
      options: type25Options,
    },
    "CP-72": {
      name: "Flanc ARG",
      main: "saddle",
      type: "body",
      others: ["saddle", "exhaust"],
      options: type25Options,
    },
    "CP-73": {
      name: "Sabot",
      main: "front",
      type: "body",
      others: ["front"],
      options: type25Options,
    },
    "CP-74": {
      name: "Flanc inf D",
      main: "front",
      type: "body",
      others: ["front"],
      options: type25Options,
    },
    "CP-75": {
      name: "Flanc inf G",
      main: "front",
      type: "body",
      others: ["front"],
      options: type25Options,
    },
    "CP-76": {
      name: "Bas de caisse D",
      main: "fuel_tank",
      type: "body",
      others: ["fuel_tank"],
      options: type23Options,
    },
    "CP-77": {
      name: "Bas de caisse G",
      main: "fuel_tank",
      type: "body",
      others: ["fuel_tank"],
      options: type23Options,
    },
    "CP-78": {
      name: "cale pied AVD",
      main: "fuel_tank",
      type: "mechanics",
      others: ["fuel_tank"],
      options: type23Options,
    },
    "CP-79": {
      name: "cale pied AVG",
      main: "fuel_tank",
      type: "mechanics",
      others: ["fuel_tank"],
      options: type23Options,
    },
    "CP-80": {
      name: "cale pied ARD",
      main: "fuel_tank",
      type: "mechanics",
      others: ["fuel_tank"],
      options: type23Options,
    },
    "CP-81": {
      name: "cale pied ARG",
      main: "fuel_tank",
      type: "mechanics",
      others: ["fuel_tank"],
      options: type23Options,
    },
    "CP-82": {
      name: "Jante AVG",
      main: "tyre",
      type: "mechanics",
      others: ["tyre"],
      options: type7Options,
    },
    "CP-83": {
      name: "Jante AVD",
      main: "tyre",
      type: "mechanics",
      others: ["tyre"],
      options: type7Options,
    },
    "CP-84": {
      name: "Pneu AVG",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-85": {
      name: "Pneu AVD",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-86": {
      name: "Disque AV",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type17Options,
    },
    "CP-87": {
      name: "Plaquettes AV",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type17Options,
    },
    "CP-88": {
      name: "Disque AR",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type17Options,
    },
    "CP-89": {
      name: "Plaquettes AR",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type17Options,
    },
    "CP-90": {
      name: "Carter embrayage",
      main: "fuel_tank",
      type: "mechanics",
      others: ["rear", "fuel_tank"],
      options: type17Options,
    },
    "CP-91": {
      name: "Carter allumage",
      main: "fuel_tank",
      type: "mechanics",
      others: ["rear", "fuel_tank"],
      options: type17Options,
    },
  };
  
  let commercialParts = {
    "CP-1": {
      name: "Contrôle technique",
      main: "technical_control",
      type: "technical_control",
      others: ["technical_control"],
      options: technicalControlOptions,
    },
    "CP-2": {
      name: "Pneu avant gauche",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-3": {
      name: "Pneu avant droit",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-4": {
      name: "Pneu arrière gauche",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-5": {
      name: "Pneu arrière droit",
      main: "tyre",
      type: "tyre",
      others: ["tyre"],
      options: tyreOptions,
    },
    "CP-6": {
      name: "Moteur",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type1Options,
    },
    "CP-7": {
      name: "Amortisseur avant G",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type2Options,
    },
    "CP-8": {
      name: "Amortisseur avant D",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type2Options,
    },
    "CP-9": {
      name: "Distribution",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type1Options,
    },
    "CP-10": {
      name: "boite de vitesses",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type1Options,
    },
    "CP-11": {
      name: "Embrayage",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type4Options,
    },
    "CP-12": {
      name: "Batterie",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type5Options,
    },
    "CP-13": {
      name: "Niveau huile moteur",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type6Options,
    },
    "CP-14": {
      name: "Niveau liquide de freins",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type6Options,
    },
    "CP-15": {
      name: "Niveau liquide de refroidissement",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type6Options,
    },
    "CP-16": {
      name: "Jante avant G",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type7Options,
    },
    "CP-17": {
      name: "Jante avant D",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type7Options,
    },
    "CP-18": {
      name: "Frein avant",
      main: "front",
      type: "mechanics",
      others: ["front"],
      options: type3Options,
    },
    "CP-19": {
      name: "Capot avant",
      main: "front",
      type: "body",
      others: ["front"],
      options: type7Options,
    },
    "CP-20": {
      name: "Bouclier avant",
      main: "front",
      type: "body",
      others: ["front"],
      options: type7Options,
    },
    "CP-21": {
      name: "Optique avant G",
      main: "front",
      type: "body",
      others: ["front"],
      options: type8Options,
    },
    "CP-22": {
      name: "Optique avant D",
      main: "front",
      type: "body",
      others: ["front"],
      options: type8Options,
    },
    "CP-23": {
      name: "Antibrouillard avant G",
      main: "front",
      type: "body",
      others: ["front"],
      options: type8Options,
    },
    "CP-24": {
      name: "Antibrouillard avant D",
      main: "front",
      type: "body",
      others: ["front"],
      options: type8Options,
    },
    "CP-25": {
      name: "Aile avant G",
      main: "front",
      type: "body",
      others: ["front"],
      options: type7Options,
    },
    "CP-26": {
      name: "Aile avant D",
      main: "front",
      type: "body",
      others: ["front"],
      options: type7Options,
    },
    "CP-27": {
      name: "Pare-brise",
      main: "front",
      type: "body",
      others: ["front"],
      options: type8Options,
    },
    "CP-28": {
      name: "Kit roue de secours",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type9Options,
    },
    "CP-29": {
      name: "Échappement",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type10Options,
    },
    "CP-30": {
      name: "Frein arrière",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type3Options,
    },
    "CP-31": {
      name: "Amortisseur arrière G",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type2Options,
    },
    "CP-32": {
      name: "Amortisseur arrière D",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type2Options,
    },
    "CP-33": {
      name: "Jante arrière G",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type7Options,
    },
    "CP-34": {
      name: "Jante arrière D",
      main: "rear",
      type: "mechanics",
      others: ["rear"],
      options: type7Options,
    },
    "CP-35": {
      name: "Bouclier arrière",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type7Options,
    },
    "CP-36": {
      name: "Hayon / Male",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type7Options,
    },
    "CP-37": {
      name: "Coffre",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type11Options,
    },
    "CP-38": {
      name: "Feu arrière G",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type8Options,
    },
    "CP-39": {
      name: "Feu arrière D",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type8Options,
    },
    "CP-40": {
      name: "Antibrouillard arrière G",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type8Options,
    },
    "CP-41": {
      name: "Antibrouillard arrière D",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type8Options,
    },
    "CP-42": {
      name: "Aile arrière G",
      main: "rear",
      type: "body",
      others: ["rear", "left_side"],
      options: type7Options,
    },
    "CP-43": {
      name: "Aile arrière D",
      main: "rear",
      type: "body",
      others: ["rear", "right_side"],
      options: type7Options,
    },
    "CP-44": {
      name: "Plancher arrière",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type7Options,
    },
    "CP-45": {
      name: "Lunette arrière",
      main: "rear",
      type: "body",
      others: ["rear"],
      options: type8Options,
    },
    "CP-46": {
      name: "Voyant",
      main: "interior",
      type: "mechanics",
      others: ["interior"],
      options: type12Options,
    },
    "CP-47": {
      name: "Planche de bord",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-48": {
      name: "Volant",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-49": {
      name: "Sélecteur de vitesses",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-50": {
      name: "Airbag conducteur",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-51": {
      name: "Airbag passager",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-52": {
      name: "Airbag genoux latéral G",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-53": {
      name: "Airbag genoux latéral D",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-54": {
      name: "Ceinture avant G",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-55": {
      name: "Ceinture avant D",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-56": {
      name: "Ceinture arrière G",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-57": {
      name: "Ceinture arrière D",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type13Options,
    },
    "CP-58": {
      name: "Siège avant G",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-59": {
      name: "Siège avant D",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-60": {
      name: "Siège arrière G",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-61": {
      name: "Siege arrière D",
      main: "interior",
      type: "cockpit",
      others: ["interior"],
      options: type11Options,
    },
    "CP-62": {
      name: "Pavillon",
      main: "roof",
      type: "body",
      others: ["roof"],
      options: type7Options,
    },
    "CP-63": {
      name: "Toit ouvrant",
      main: "roof",
      type: "body",
      others: ["roof"],
      options: type8Options,
    },
    "CP-64": {
      name: "Capote",
      main: "roof",
      type: "body",
      others: ["roof"],
      options: type8Options,
    },
    "CP-65": {
      name: "Porte avant G",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type7Options,
    },
    "CP-66": {
      name: "Porte arrière G",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type7Options,
    },
    "CP-67": {
      name: "Vitre avant G",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type8Options,
    },
    "CP-68": {
      name: "Vitre arrière G",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type8Options,
    },
    "CP-69": {
      name: "Garniture avant G",
      main: "left_side",
      type: "cockpit",
      others: ["left_side"],
      options: type11Options,
    },
    "CP-70": {
      name: "Garniture arrière G",
      main: "left_side",
      type: "cockpit",
      others: ["left_side"],
      options: type11Options,
    },
    "CP-71": {
      name: "Bas de caisse G",
      main: "left_side",
      type: "cockpit",
      others: ["left_side"],
      options: type7Options,
    },
    "CP-72": {
      name: "Porte avant D",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type7Options,
    },
    "CP-73": {
      name: "Porte arrière D",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type7Options,
    },
    "CP-74": {
      name: "Vitre avant D",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type8Options,
    },
    "CP-75": {
      name: "Vitre arrière D",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type8Options,
    },
    "CP-76": {
      name: "Garniture avant D",
      main: "right_side",
      type: "cockpit",
      others: ["right_side"],
      options: type11Options,
    },
    "CP-77": {
      name: "Garniture arrière D",
      main: "right_side",
      type: "cockpit",
      others: ["right_side"],
      options: type11Options,
    },
    "CP-78": {
      name: "Bas de caisse D",
      main: "right_side",
      type: "cockpit",
      others: ["right_side"],
      options: type7Options,
    },
    "CP-79": {
      name: "Rétroviseur G",
      main: "left_side",
      type: "body",
      others: ["left_side"],
      options: type7Options,
    },
    "CP-80": {
      name: "Rétroviseur D",
      main: "right_side",
      type: "body",
      others: ["right_side"],
      options: type7Options,
    },
  };

  switch (type) {
    case 0:
      return carParts;
    case 1:
      let tmpBikeParts = JSON.parse(JSON.stringify(bikeParts));
      if (["TMP1", "TMP2", "TMM1", "TMM2"].includes(gender)) {
        delete tmpBikeParts["CP-67"];
        delete tmpBikeParts["CP-69"];
      } else {
        delete tmpBikeParts["CP-82"];
        delete tmpBikeParts["CP-83"];
        delete tmpBikeParts["CP-84"];
        delete tmpBikeParts["CP-85"];
      }

      return tmpBikeParts;
    case 2:
      return commercialParts;
    case 3:
      return commercialParts;
    case 4:
      return commercialParts;
    default:
      return {};
  }
};

export default getParts;
