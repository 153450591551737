import React, { Fragment } from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  FullModal,
  Nav,
  Button,
  FormInput,
  FormTextarea,
  FormSelect,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faMinusCircle,
  faCheck,
  faThumbsUp,
  faThumbsDown,
  faArrowCircleUp,
  faLink,
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";

import {
  getPrice,
  getInt,
  getFuelString,
  getGearboxString,
} from "../../../utils/general";
import { getRegionFromDepartment } from "../../../utils/region";

export default class ModalData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      select: {},
    };
  }

  handleInputChangeSelect = (e) => {
    const { value, name } = e.target;

    let tmpSelect = this.state.select;
    tmpSelect[name] = value;

    this.setState({
      select: tmpSelect,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.select) !== JSON.stringify(prevProps.select)
    ) {
      this.setState({
        select: this.props.select ? this.props.select : {},
      });
    }
  }

  onClickEdit = () => {
    this.setState(
      {
        isEdit: !this.state.isEdit,
      },
      () => {
        if (!this.state.isEdit) {
          const tmpDataSelect = this.state.select;

          Object.keys(tmpDataSelect).map((key) => {
            if (!tmpDataSelect[key]) {
              tmpDataSelect[key] = undefined;
            }
            return null;
          });

          this.props.updateFolderSelect(this.props.idx, tmpDataSelect);
        }
      }
    );
  };

  getList = () => {
    return [
      {
        name: "price",
        title: "Prix",
        value: this.props.ad.price,
        valueEdit: this.state.select.price,
        formatText: getPrice,
      },
      {
        name: "brand",
        title: "Marque",
        value: this.props.ad.brand,
        valueEdit: this.state.select.brand,
      },
      {
        name: "model",
        title: "Modèle",
        value: this.props.ad.model,
        valueEdit: this.state.select.model,
      },
      {
        name: "date_circulation",
        title: "Mise en circulation",
        value: this.props.ad.date_circulation,
        valueEdit: this.state.select.date_circulation,
      },
      {
        name: "color",
        title: "Couleur",
        value: this.props.ad.color,
        valueEdit: this.state.select.color,
      },
      {
        name: "mileage",
        title: "Kilométrage",
        value: this.props.ad.mileage,
        formatText: getInt,
        valueEdit: this.state.select.mileage,
        extension: "km",
      },
      {
        name: "fuel",
        title: "Énergie",
        value: getFuelString(this.props.ad.fuel),
        valueEdit: this.state.select.fuel,
      },
      {
        name: "fiscal_power",
        title: "Puissance fiscale",
        value: this.props.ad.fiscal_power,
        valueEdit: this.state.select.fiscal_power,
        extension: "cv",
      },
      {
        name: "door",
        title: "Nombre de porte",
        value: this.props.ad.door,
        valueEdit: this.state.select.door,
      },
      {
        name: "source",
        title: "Source",
        value: this.props.ad.source,
        valueEdit: this.state.select.source,
      },
      {
        name: "region",
        title: "Région",
        value: getRegionFromDepartment(this.props.ad.department),
        valueEdit: this.state.select.region,
      },
      {
        name: "gearbox",
        title: "Boîte de vitesse",
        value: getGearboxString(this.props.ad.gearbox),
        valueEdit: this.state.select.gearbox,
      },
      {
        name: "zipcode",
        title: "Code postal",
        value: this.props.ad.zipcode,
        valueEdit: this.state.select.zipcode,
      },
      {
        name: "vehicleType",
        title: "Type de véhicule",
        value: this.props.ad.vehicleType,
        valueEdit: this.state.select.vehicleType,
      },
      {
        options: ["équivalent", "inférieur", "supérieur"],
        name: "finish",
        title: "Finition",
        value: this.props.ad.finish || "",
        valueEdit: this.state.select.finish,
      },
      {
        options: ["", "part.", "pro.", "inte."],
        name: "type",
        title: "Type",
        value: this.props.ad.type || "",
        valueEdit: this.state.select.type,
      },
      ...(this.props.gender === 1
        ? [
            {
              name: "displacement",
              title: "Cylindrée",
              value: this.props.ad.displacement,
              valueEdit: this.state.select.displacement,
            },
          ]
        : []),
      {
        name: "comment",
        title: "Commentaire de l'expert",
        value: this.props.ad.comment,
        valueEdit: this.state.select.comment,
        isFull: true,
        rows: 3,
      },
      {
        name: "description",
        title: "Description",
        value: this.props.ad.description,
        valueEdit: this.state.select.description,
        isFull: true,
        rows: 12,
      },
    ];
  };

  render() {
    return (
      <FullModal
        title={`Annonce - ${this.props.ad.title}`}
        show={this.props.show}
        onClose={this.props.onClose}
      >
        <Nav className="mb-20">
          <Button
            blue
            text={this.state.select.typePrice === "HT" ? "Prix HT" : "Prix TTC"}
            onClick={() => {
              let tmpDataSelect = this.state.select;

              tmpDataSelect.typePrice =
                tmpDataSelect.typePrice === "HT" ? "TTC" : "HT";

              this.setState(
                {
                  select: tmpDataSelect,
                },
                () => {
                  this.props.updateFolderSelect(this.props.idx, tmpDataSelect);
                }
              );
            }}
          >
            <FontAwesomeIcon icon={faEuroSign} />
          </Button>
          <Button
            blue
            text="Voir l'annonce"
            className="ml-10"
            onClick={() => window.open(this.props.ad.url, "_blank")}
          >
            <FontAwesomeIcon icon={faLink} />
          </Button>
          <Button
            blue
            text={this.state.isEdit ? "Valider" : "Modifier"}
            className="ml-10"
            onClick={this.onClickEdit}
          >
            <FontAwesomeIcon icon={this.state.isEdit ? faCheck : faPen} />
          </Button>
          {this.props.ad.status === 0 && this.props.onClickDisableAd ? (
            <Button
              orange
              text="Désactiver"
              className="ml-10"
              onClick={() => this.props.onClickDisableAd(this.props.idx)}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </Button>
          ) : this.props.ad.status === 2 && this.props.onClickEnableAd ? (
            <Button
              green
              text="Réactiver"
              className="ml-10"
              onClick={() => this.props.onClickEnableAd(this.props.idx)}
            >
              <FontAwesomeIcon icon={faArrowCircleUp} />
            </Button>
          ) : null}
          {this.props.dataSelect &&
          this.props.dataSelect.hasOwnProperty(this.props.ad.id) &&
          this.props.dataSelect[this.props.ad.id].status ? (
            <Button
              red
              text="Retirer"
              className="ml-10"
              onClick={() => this.props.onClickRemoveAd(this.props.idx)}
            >
              <FontAwesomeIcon icon={faThumbsDown} />
            </Button>
          ) : (
            <Button
              green
              text="Ajouter"
              className="ml-10"
              onClick={() => this.props.onClickAddAd(this.props.idx)}
            >
              <FontAwesomeIcon icon={faThumbsUp} />
            </Button>
          )}
        </Nav>
        <Row>
          <Col xs={12} md={4}>
            {this.props.ad && this.props.ad.images
              ? this.props.ad.images.map((element, key) => (
                  <Fragment key={key}>
                    {element ? (
                      <img
                        className="mb-10"
                        style={{
                          maxWidth: "100%",
                          display: "block",
                          margin: "auto",
                        }}
                        src={element}
                        alt="car"
                      />
                    ) : null}
                  </Fragment>
                ))
              : null}
          </Col>
          <Col xs={12} md={8}>
            <Row>
              {this.getList().map((element, idx) => (
                <Col
                  key={idx}
                  xs={12}
                  md={element.isFull ? 12 : 3}
                  className="mb-20"
                >
                  {this.state.isEdit ? (
                    element.options ? (
                      <FormSelect
                        classNameTitle={element.valueEdit ? "red" : ""}
                        title={element.title}
                        options={element.options.map((option) => ({
                          value: option,
                          text: option,
                        }))}
                        value={
                          element.valueEdit ||
                          (element.valueEdit === "" && this.state.isEdit)
                            ? element.valueEdit
                            : element.value
                        }
                        name={element.name}
                        onChange={this.handleInputChangeSelect}
                      />
                    ) : element.isFull ? (
                      <FormTextarea
                        classNameTitle={element.valueEdit ? "red" : ""}
                        title={element.title}
                        value={
                          element.valueEdit ||
                          (element.valueEdit === "" && this.state.isEdit)
                            ? element.valueEdit
                            : element.value
                        }
                        name={element.name}
                        rows={element.rows}
                        onChange={this.handleInputChangeSelect}
                      />
                    ) : (
                      <FormInput
                        classNameTitle={element.valueEdit ? "red" : ""}
                        title={element.title}
                        value={
                          element.valueEdit ||
                          (element.valueEdit === "" && this.state.isEdit)
                            ? element.valueEdit
                            : element.value
                        }
                        name={element.name}
                        onChange={this.handleInputChangeSelect}
                      />
                    )
                  ) : (
                    <>
                      <h2 className={`m-0 ${element.valueEdit ? "red" : ""}`}>
                        {element.title}
                      </h2>
                      <p className="m-0">
                        {element.formatText
                          ? element.formatText(
                              element.valueEdit
                                ? element.valueEdit
                                : element.value
                            )
                          : element.valueEdit
                          ? element.valueEdit
                          : element.value}{" "}
                        {element.extension ? element.extension : ""}
                      </p>
                    </>
                  )}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </FullModal>
    );
  }
}
