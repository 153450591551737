import React from "react";

import Identification from "../../../components/folder/identification/Identification";
import {
  inputAdministrativeIdentification,
  inputVehicleIdentification,
} from "../../../components/folder/identification/details";
export default class FolderIdentification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.inputAdministrativeIdentification = inputAdministrativeIdentification;
    this.inputVehicleIdentification = inputVehicleIdentification;
  }

  onValid = (data) => {
    let tmpDataIdentification = this.props.dataIdentification;

    Object.keys(data).map((key) => {
      tmpDataIdentification[key] = data[key];
      return null;
    });

    this.props.updateFolder("dataIdentification", tmpDataIdentification);
  };

  render() {
    return (
      <>
        <Identification
          title="Identification administrative"
          input={this.inputAdministrativeIdentification}
          dataIdentification={this.props.dataIdentification}
          onValid={this.onValid}
        />
        <Identification
          title="Identification du véhicule"
          input={this.inputVehicleIdentification}
          dataIdentification={this.props.dataIdentification}
          onValid={this.onValid}
        />
      </>
    );
  }
}
