import React from "react";

export default class Table extends React.Component {
  render() {
    return (
      <table {...this.props} className="DescriptiveState-table">
        {this.props.children}
      </table>
    );
  }
}
