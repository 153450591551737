import React from "react";

export default class TechnicalControl extends React.Component {
  onClick = (e) => {
    this.props.onClickItem(e.target.getAttribute("name"));
  };

  getColor = (value) => {
    if (value > 0) {
      return this.props.isStandard ? "#bbe1fe" : "#bcf5d5";
    } else if (value < 0) {
      return this.props.isStandard ? "#bbe1fe" : "#ffb8b8";
    } else if (value !== null) {
      return "#bbe1fe";
    } else {
      return "#EFEFEF";
    }
  };

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 106 106"
        style={{ maxHeight: "110px", minHeight: "110px" }}
        className="pb-10"
      >
        <g>
          <g>
            <path
              onClick={this.onClick}
              name="technical_control"
              fill={this.getColor(this.props.technicalControlValue)}
              className={this.props.isStandard ? "svg-hover" : ""}
              d="M.5.5v105h105V.5zm50.262 44.658h-2.133l.731-3.364a8.224 8.224 0 00-.321-2.683 7.665 7.665 0 00-1.667-2.613 7.312 7.312 0 00-2.642-1.567 10.5 10.5 0 00-3.54-.55 14.965 14.965 0 00-9.083 2.843 23.77 23.77 0 00-7.773 10.243 27.95 27.95 0 00-2.323 11.053 12.449 12.449 0 003.341 9.118 11.648 11.648 0 008.674 3.41c2.511.045 4.769-1.208 6.963-2.431a15.2 15.2 0 004.925-4.897h2.5a22.579 22.579 0 01-7.079 7.461 17.554 17.554 0 01-9.315 2.396 17.6 17.6 0 01-8.53-2.014 13.625 13.625 0 01-5.662-5.681 16.49 16.49 0 01-1.929-7.862A26.182 26.182 0 0128.82 35.913a24.083 24.083 0 0112.16-3.482 18.286 18.286 0 016.44 1.362 6.111 6.111 0 001.839.589 2.469 2.469 0 001.216-.279 9.128 9.128 0 001.764-1.671l1.479.008zm36.12-1.382H85.02a18.974 18.974 0 00.963-4.088 3.448 3.448 0 00-1.291-2.978c-.658-.542-2.023-1.027-4.751-1.027h-3.1l-7.776 27.1a35 35 0 00-1.481 6.3 2.186 2.186 0 00.928 1.813 5.57 5.57 0 003.28.71h1.335v2.378H55.976v-2.391h.754a6.874 6.874 0 003.484-.724 4.338 4.338 0 001.523-1.785 35.907 35.907 0 001.9-5.468l8.154-27.938h-2.345a14.358 14.358 0 00-5.468.87 6.16 6.16 0 00-2.812 2.316 13.447 13.447 0 00-2.134 4.913h-1.561l2.763-10.445h29.87z"
              stroke="#707070"
              strokeMiterlimit={10}
            />
          </g>
        </g>
        <text
          data-name="AA - 123 - BB"
          transform="translate(53.5 18.621)"
          fill="#707070"
          fontSize={12}
          fontFamily="ArialMT, Arial"
        >
          <tspan x={-36.352} y={0}>
            {"AA - 123 - BB"}
          </tspan>
        </text>
        <text
          data-name="00/20"
          transform="translate(53.5 95.5)"
          fill="#707070"
          fontSize={15}
          fontFamily="ArialMT, Arial"
        >
          <tspan x={-18.768} y={0}>
            {"00/20"}
          </tspan>
        </text>
      </svg>
    );
  }
}
