const getYearOptions = (startYear = 1940) => {
  const currentYear = new Date().getFullYear()
  let options = []

  for (let index = startYear; index <= currentYear; index++) {
    options.push({ value: index, text: index })
  }

  options.reverse()

  return options
}

const getPriceOptions = () => {
  return [
    { value: 0, text: "0 €" },
    { value: 250, text: "250 €" },
    { value: 500, text: "500 €" },
    { value: 750, text: "750 €" },
    { value: 1000, text: "1 000 €" },
    { value: 1500, text: "1 500 €" },
    { value: 2000, text: "2 000 €" },
    { value: 2500, text: "2 500 €" },
    { value: 3000, text: "3 000 €" },
    { value: 3500, text: "3 500 €" },
    { value: 4000, text: "4 000 €" },
    { value: 4500, text: "4 500 €" },
    { value: 5000, text: "5 000 €" },
    { value: 5500, text: "5 500 €" },
    { value: 6000, text: "6 000 €" },
    { value: 6500, text: "6 500 €" },
    { value: 7000, text: "7 000 €" },
    { value: 7500, text: "7 500 €" },
    { value: 8000, text: "8 000 €" },
    { value: 8500, text: "8 500 €" },
    { value: 9000, text: "9 000 €" },
    { value: 9500, text: "9 500 €" },
    { value: 101000, text: "10 000 €" },
    { value: 11000, text: "11 000 €" },
    { value: 12000, text: "12 000 €" },
    { value: 13000, text: "13 000 €" },
    { value: 14000, text: "14 000 €" },
    { value: 15000, text: "15 000 €" },
    { value: 17500, text: "17 500 €" },
    { value: 20000, text: "20 000 €" },
    { value: 22500, text: "22 500 €" },
    { value: 25000, text: "25 000 €" },
    { value: 27500, text: "27 500 €" },
    { value: 30000, text: "30 000 €" },
    { value: 32500, text: "32 500 €" },
    { value: 35000, text: "35 000 €" },
    { value: 37500, text: "37 500 €" },
    { value: 40000, text: "40 000 €" },
    { value: 42500, text: "42 500 €" },
    { value: 45000, text: "45 000 €" },
    { value: 47500, text: "47 500 €" },
    { value: 50000, text: "50 000 €" },
    { value: 55000, text: "55 000 €" },
    { value: 60000, text: "60 000 €" },
    { value: 65000, text: "65 000 €" },
    { value: 70000, text: "70 000 €" },
    { value: 75000, text: "75 000 €" },
    { value: 80000, text: "80 000 €" },
    { value: 85000, text: "85 000 €" },
    { value: 90000, text: "90 000 €" },
    { value: 95000, text: "95 000 €" },
    { value: 100000, text: "100 000 €" },
  ]
}

const getMileageOptions = () => {
  return [
    { value: 0, text: "0" },
    { value: 1000, text: "1 000" },
    { value: 2000, text: "2 000" },
    { value: 5000, text: "5 000" },
    { value: 10000, text: "10 000" },
    { value: 20000, text: "20 000" },
    { value: 30000, text: "30 000" },
    { value: 40000, text: "40 000" },
    { value: 50000, text: "50 000" },
    { value: 60000, text: "60 000" },
    { value: 70000, text: "70 000" },
    { value: 80000, text: "80 000" },
    { value: 90000, text: "90 000" },
    { value: 100000, text: "100 000" },
    { value: 110000, text: "110 000" },
    { value: 120000, text: "120 000" },
    { value: 130000, text: "130 000" },
    { value: 140000, text: "140 000" },
    { value: 150000, text: "150 000" },
    { value: 160000, text: "160 000" },
    { value: 170000, text: "170 000" },
    { value: 180000, text: "180 000" },
    { value: 190000, text: "190 000" },
    { value: 200000, text: "200 000" },
    { value: 210000, text: "210 000" },
    { value: 220000, text: "220 000" },
    { value: 230000, text: "230 000" },
    { value: 240000, text: "240 000" },
    { value: 250000, text: "250 000" },
    { value: 260000, text: "260 000" },
    { value: 270000, text: "270 000" },
    { value: 280000, text: "280 000" },
    { value: 290000, text: "290 000" },
    { value: 300000, text: "300 000" },
  ]
}

export {
  getYearOptions,
  getPriceOptions,
  getMileageOptions
};